import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import MiscLayout from './miscLayout'
import CustomLink from '../../components/customLink'

// const faqs =[
//     {
//         question:"What is Fractional Ownership?",
//         answer:'Fractional ownership is a method in which a property (or any other asset) is co-owned by several unrelated parties.'
        
//     },
//     {
//         question:"Who will own the property?",
//         answer:'All buyers are the co-owners of the property, in proportion to their respective fractions (or FRAX) held by them.'
        
//     },
//     {
//         question:"How will the property be registered?",
//         answer:'The property will be registered by a custodian entity setup or appointed for the purpose by RealX. The custodian is only the registered owner but will not be the real (or beneficial) owner of the same.'
        
//     },
//     {
//         question:"Will my name reflect when the property is registered?",
//         answer:"The names of the first co-owners and their respective details are included in the registered document. The same information is also credited into the blockchain based registry system (PropChain). The accounts of respective co-owner's then reflect respective amounts of FRAX held by them. Subsequent co-owners who buy these FRAX through the original (or any of the subsequent owners) will have their ownerships recorded on PropChain once their sale is registered. They buyer and seller both will receive copies of the sale documents."
        
//     },
//     {
//         question:"Will I get Index-II?",
//         answer:'No. Index II will be issued only in the name of the Custodian entity - not to the co-owners. However, we have had some preliminary discussions with some state governments on how we can enable our co-owners to also get Index II for their respective proportional co-ownership.'
        
//     },
//     {
//         question:"Will I need to travel while registering the property?",
//         answer:'No. RealX enables a seamless digital framework. There is no need for you to travel in our framework.'
        
//     },
//     {
//         question:"Who will choose the property?",
//         answer:'RealX allows only select properties to be presented on the platform. The registered users of the platform will be free to choose the property they want to invest in, by themselves.'
        
//     },
//     {
//         question:"Can anyone buy property on RealX?",
//         answer:'Yes. RealX is an open platform for everyone to participate. However, we require all our users to be fully registered and allow them to transact only after their KYC is completed.'
        
//     },
//     {
//         question:"Is there any lock-in period before which I cannot sell?",
//         answer:'Yes. RealX mandates a lock-in period of 2 years from the date of purchase for all first co-owners of a property.'
        
//     },
//     {
//         question:"Who will do the due diligence of the property?",
//         answer:'RealX appoints independent external law firms to conduct due diligence on the properties it shortlists.'
        
//     },
//     {
//         question:"Will I get to view the due diligence report?",
//         answer:'Yes. The DD Report will be available on the platform.'
        
//     },
//     {
//         question:"Who will handle the day to day oversight and management of property?",
//         answer:'RealX has accredited Property Management firms that handle the oversight and management of the Property.'
        
//     },
//     {
//         question:"Can foreign nationals become co-owners in property?",
//         answer:'No. Not at the moment. But soon enough it will be possible. If you are a foreign investor desirous of investing in any of our deals, please reach out to us and we will get back to you with details.'
        
//     },
    
// ]

const FaqPage = () =>{

    const tabs = [
        {
            name:"FAQs",
            component:<FAQ />
        },
        {
            name:"Market Update",
            component:<MarketUpdate />
        },
    ]
    return (
        <MiscLayout tabs={tabs} title={"Knowledge Base"} />
    )
}

export default FaqPage


const FAQ = () =>{

    const generalQuestion = [
        {
            id:'1',
            title:"What is Fractional Ownership?",
            description:"Fractional ownership is a method in which any asset (like property) is co-owned by several unrelated parties."
        },
        {
            id:'2',
            title:"Who will own the property?",
            description:"The property is legally owned by a Trust registered with the Government (acting as a fiduciary) holding the asset (as a service) for the investors who are investing to buy the tokens. The Trust is established to be the legal owner of the assets, however it is required to simply represent the investors as if they were direct co-owners of the property. The Trust, being the legal owner on record, in turn issues the legal document entitling the token-holders for the proportionate beneficial rights on the property. This is very common in Financial Services. One common example of such beneficial ownership is equity shares held in Demat accounts. The Demat accounts are actually called BO accounts or Beneficial Owner accounts. This is because the demat holder does not become direct owner of the company shares, instead the depository (where the demat account is held) becomes the single registered and consolidated shareholder on behalf of all the shares held in demat accounts issued by it."
        },
        {
            title:"How will the property be registered?",
            description:"The property will be registered by a custodian entity appointed for the purpose by RealX. This custodian entity is only the registered owner acting as a representative for registration purpose on behalf of the actual co-owners. The actual co-owners, their consideration (investment) amounts and their identity details are also made part of the registered document (Sale Deed).",
        },
        {
            title:"Will my name reflect when the property is registered?",
            description:`As a token holder, you become a beneficial owner (with full rights, benefits as well as obligations) of the property. You have an Option To Convert (OTC) to become a registered co-owner through a registered deed conveying the same rights establishing direct, legal co-ownership. Until the time the property is held as beneficial owner thru the token, your name is not registered with the registrar yet. However, should you exercise the option to convert your token will be exhausted (burnt), instead you will be issued a registered legal document and you will stand to lose all the benefits of the token.`,
        },
        {
            title:"Will I need to travel while registering the property?",
            description:"No. RealX enables a seamless digital framework. There is no need for you to travel in our framework.",
        },
        {
            title:"Who will choose the property?",
            description:"RealX allows only select properties to be presented on the platform. The registered users of the platform will be free to choose the property they want to invest in, by themselves.",
        },
        {
            title:"Can anyone buy property on RealX?",
            description:"Yes. RealX is an open platform for everyone to participate. However, we require all our users to be fully registered and allow them to transact only after their KYC is completed.",
        },
        {
            title:"Is there any lock-in period before which I cannot sell?",
            description:"No, there is no general lock-in period. However, there may be deals that may have an initial lock-in period. Therefore, we strongly recommend you go thru the property details before investing. ",
        },
        {
            title:"Who will do the due diligence of the property?",
            description:"RealX appoints independent external law firms to conduct due diligence on the properties it shortlists.",
        },
        {
            title:"Who will handle the day-to-day oversight and management of property?",
            description:"RealX has accredited Property Management firms that handle the oversight and management of the Property.",
        },
        {
            title:"Can foreign nationals become co-owners in property?",
            description:"No, not now at the moment. But soon enough it will be possible. If you are a foreign investor desirous of investing in any of our deals, please reach out to us and we will get back to you with details.",
        },
        {
            title:"Will I get to view the due diligence report?",
            description:"Yes. The legal due diligence report is a confidential document and will be made available by email to all investors in the property.",
        },

    ]
    const holdingAndRent =[
        {
            title:"Who will collect the rent?",
            description:"All rents will be collected in designated accounts of RealX. From there, the rents shall be proportionately transferred to the token-holders in their bank accounts (after standard deductions).",
        },
        {
            title:"Will the rent be taxable?",
            description:"Yes. As per the law of the land, we may be required to deduct TDS (whenever applicable) on the rental amount before we pass on the same to you.",
        },
        {
            title:"Where will I see my holdings?",
            description:`You will be able to see your holdings in the My Portfolio Section after you login.`,
        },
    ]
    const exit =[
        {
            title:"Will I be able to sell or transfer my FRAX freely?",
            description:"Yes. All token-holders have free-hold ownership over the number of FRAX in their tokens. They are complete and absolute owners of their parts of the property. They are free to sell (or transfer) the FRAX held in their tokens, either all or some of it, to any other blockchain account without any constraints whatsoever.",
        },
        {
            title:"Will I benefit from appreciation in property value when I sell?",
            description:" Yes. As a beneficial owner of a property, you will benefit from the increase in property values proportionate to their beneficial ownership in the property.",
        },
    ]
    const payments = [
        {
            title:"Will I get acknowledgement of payments made?",
            description:"Yes. We will acknowledge you as soon as your funds are cleared into our account, and we are able to match it against your commitment details on the platform.",
        },
        {
            title:"Can I cancel my interest post payment?",
            description:" No. Cancellation at this stage will not be possible because an allotment in your name is already registered. So we recommend you to think through your choices before you commit an investment amount and make payment for it.",
        },
        {
            title:"How much time for refund?",
            description:"Investors who do not get an allotment in a property where they have invested are refunded the amount invested. Usually, refunds should be processed within 15 days from the transaction closure.",
        },
        {
            title:"Do I need to pay the entire investment amount at once?",
            description:`Yes.`,
        },
        {
            title:"Will there be any interest accrued on the amount invested?",
            description:"No. The investment amounts are held in the nature of escrow and therefore, it does not accrue any interest.",
        },
    ]
    const tax =[
        {
            title:"Will stamp duty be payable?",
            description:"The cost of Stamp Duty as well as other statutory and closing costs are included in the total cost of purchase for all primary purchase transactions. The price per FRAX is derived from the total cost of purchase.",
        },
        {
            title:"Will you deduct TDS?",
            description:"Yes. Standard TDS will be deducted as applicable.",
        },
        {
            title:"Will there be GST applicable?",
            description:"Yes. As per prevailing rules, GST becomes applicable when annual rent receivable by a person is in excess of a certain designated threshold. GST, as applicable, will also be deducted from net payable amount to a person when it crosses the government defined threshold.",
        },
        {
            title:"How will I show my rent income and capital gains in my IT returns?",
            description:`Normally the rent income and capital gains from invested properties should be treated as rent income and capital gains respectively, even though the property is held indirectly (as beneficial owner in this case). However, there may be cases when it may be advantageous to treat it as business income especially if you are a prolific investor in properties. Considering that the financial state of every investor is different, we suggest you consult a tax advisor to determine an exact declaration, as may be best applicable in your case.`,
        },
    ]

    const propertyToken = [
        {
            title:"Do the Property Tokens carry any legal rights?",
            description:"Yes, absolutely. The Property Tokens issued by RealX are NFTs that represent legal rights to become proportionate co-owner of the property. Similar to Contracts on Commodity Exchanges, wherein there is a Digital Contract to deliver a commodity, the Property Tokens issued at RealX carry a similar contract to convert the Ownership into Registered Co-Ownership when the option is exercised by the owner of the Token to do so.",
        },
        {
            title:"What are the advantages of buying property through Tokens?",
            description:"While in terms of the benefits, investing through Property Tokens is similar to investing through Registered Co-ownership. However, Tokens can be listed at various other Digital Asset and Crypto exchanges in India and even abroad. This can open up a vast market for investors to exit from their investments and monetize their gains. This liquidity is not possible with Registered Co-Ownership. Another advantage of Tokens is that the investment amounts are generally smaller when compared with Property Co-Ownership.",
        },
        {
            title:"Do you plan to list the Property Tokens on other exchanges?",
            description:"RealX is committed to providing options for liquidity to help our investors with exits. We will enable a secondary market at RealX for investors. Additionally, we do plan to have the tokens listed on other exchanges as well. Aside of this we are exploring other solutions that may improve liquidity for investors.",
        },
        {
            title:"Can anyone freely convert their property tokens into registered co-ownership?",
            description:" Conditions to convert (CTC): There are certain conditions that are required to be met before any token holder can exercise their option to convert in order to become a registered co-owner of a property. These conditions are called Conditions To Convert (CTC) and have been created keeping in mind compliance with laws, regulations, market integrity and commercial feasibility.",
        },
        {
            title:"Can I buy the Property Tokens with Indian Rupee?",
            description:"Yes, all payments are to be made in fiat currency only i.e. the Indian Rupees.",
        },
        {
            title:"Can I purchase the Tokens with other Cryptos like ETH or Bitcoin?",
            description:"Once a Token is listed on another exchange, it may be possible to buy them by paying in other Crypto Tokens like Bitcoin and Ether.",
        },
        {
            title:"If I lose my tokens will I be able to recover them?",
            description:"Yes, of course",
        },
        {
            title: "How are property prices determined on the platform?",
            description: 'RealX operates as a pure marketplace. The sellers of the properties are free to decide the pricing for their properties. RealX does not take any pricing decisions however we do curate what property deals are allowed to go live in the interest of our customers. Unlike most other platforms RealX does not indulge in undertaking sales mandates from sellers because it may compromise the customer interest.'
        },
        {
            title: 'What happens if the property decreases in value?',
            description: 'Property is a stable asset, and it can generate passive income and value for the investors. It is not usual to see property prices decreasing and even when it happens, it is the last asset to lose value after other common assets have already crashed. However, it is possible that certain properties may decrease in value and therefore we recommend you invest after studying the property deal carefully.'
        },
        {
            title:'What happens if the property is damaged or destroyed?',
            description: ' RealX will ensure all properties are adequately insured against natural or unnatural damages (depending on available insurances) to cover for such risks.'
        },
        {
            title: 'What happens if a property I have invested in is sold?',
            description: 'A property can only be sold upon consent from majority of the investors. The proceeds of the sale are distributed amongst the investors as per their proportionate ownership after due deductions for various closing costs and fees.'
        },
        {
            title: 'What is the minimum investment required to participate in RealX?',
            description: 'The sellers are free to set the minimum investment amount per property. RealX allows the minimum investment to be as low as INR 5000.'
        }

    ]
    
    // const legal =[
    //     {
    //         id:'1',
    //         title:"Explain Legal Model of RealX.",
    //         description:"RealX has implemented a 'tenants-in-common' construct to enable 'equitable' and 'undivided' co-ownership of properties held digitally. A custodian is the registered owner of the property on behalf of the co-owners (as a service), but does not have any real ownership in it. The custodian registers the property (once it is fully subscribed on the platform) on behalf of all the (subscribing) parties who become 'equitable' co-owners of their respective 'undivided' portions of the property by paying for their respective proportion of the total purchase price (including the consideration and statutory fees). The registered instrument of purchase (or sale deed) contains names of its first co-owners along with other terms that enable all co-owners to hold such co-ownership digitally as maintained in a digital registry."
    //     },
    //     {
    //         id:'2',
    //         title:"What is co-ownership and why do you say it is co-ownership?",
    //         description:"The RealX model allows you to buy your own share of the property. You hold such share/fraction of your property directly, unencumbered and without any control on it of anyone else. Also, there is no SPV or other legal structure in between. Legally speaking RealX allows you equitable undivided co-ownership in the property and such co-ownerships are registered and can be transferred digitally. In the RealX model, the co-owners have complete right to take decisions on any key matter that involves their ownership. The co-owners have access to their direct holdings in terms of FRAX through the RealX platform."
    //     },
    //     {
    //         id:'3',
    //         title:"What is FRAX?",
    //         description:"A FRAX is the standard unit of undivided property that can be bought, held and sold on RealX. One FRAX equals One Square Inch. This is 144th of an area of One Square Feet. Apart from representing the area, FRAX also stands to represent all rights, restrictions, benefits and obligations that are associated with it."
    //     },
    //     {
    //         id:'4',
    //         title:"Who is a Custodian?",
    //         description:"A Custodian or a Principal Custodian is any legal entity that has responsibility for taking care of or protecting something on behalf of its real owners or people to whom it may really belong. Currently RealX has created RealX Trust I as the Custodian. RealX may introduce different custodial frameworks in different products/properties as may be more appropriate for various products."
    //     },
    // ]
        

    const questions = [
        {
            title:"General questions",
            questions:generalQuestion,
        },
        {
            title:"Holding and Rent",
            questions:holdingAndRent,
        },
        {
            title:"Payment",
            questions:payments,
        },
        {
            title:"Tax",
            questions:tax,
        },
        {
            title:"Property Tokens",
            questions:propertyToken
        },
        {
            title:"Exit",
            questions:exit,
        },

    ]


    return(
        <Stack spacing={{md:4,xs:3}}>
            {
                questions.map((e,i)=>{
                    return(
                        <Stack spacing={{md:1.5,xs:2}}>
                            <Typography variant='aboutHeading2'>{e.title}</Typography>
                            <Box>
                                {
                                    e.questions?.map((k,i)=>{
                                        return(
                                            <QuestionAnswer k={k} />
                                        )
                                    })
                                }
                            </Box>
                        </Stack>
                    )
                })
            }
            
        </Stack>
    )
}

const QuestionAnswer = ({k}) =>{
    const [expanded,setExpanded] = useState(false);
    return(
        <Box>
            <Accordion expanded={expanded} onChange={(e,v)=>setExpanded(v)} sx={{padding:"8px 0px",boxShadow:"none",
            "&.Mui-expanded":{
                margin:0,
                mt:0,
                "&:first-of-type":{
                    mt:0
                }
            },
            "&::before":{
                content:"none"
            }}}>
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        padding:"0px",
                        minHeight:"auto",
                        flexDirection: 'row-reverse',
                        "& .MuiAccordionSummary-expandIconWrapper":{
                            transform:"none !important"
                        },
                        "& .MuiAccordionSummary-content":{
                            my:"0px",
                            "&.Mui-expanded":{
                                margin:0
                            }
                        },
                        "&.Mui-expanded":{
                            minHeight:"auto"
                        }
                    }}
                >
                    <Typography sx={{mr:0.5}} variant='body3'>{expanded?"-":"+"}</Typography>
                    <Typography variant='body3' fontWeight={expanded ? 600 : 300}>{k.title}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{padding:0,margin:0,ml:2,mt:1}}>
                    <Typography variant='body3'  >{k.description}</Typography>
                </AccordionDetails>
            </Accordion>
            <Divider />
        </Box>
    )
}

const MarketUpdate = () =>{
    return(
        <Stack spacing={{md:4,xs:3}}>
            <Stack spacing={{md:1.5,xs:2}}>
                <Typography variant='aboutHeading2'>Government updates</Typography>
              

               <CustomLink num="1." text="India intends to facilitate blockchain tech, not hurt it: FM"
                    href="https://www.fortuneindia.com/macro/india-intends-to-facilitate-blockchain-tech-not-hurt-it-fm/India's%20richest"
                />

                <CustomLink num="2." text="India aims at developing tech-driven regulatory framework for cryptocurrency: FM Nirmala Sitharaman"
                 href='https://m.economictimes.com/news/economy/policy/india-aims-at-developing-tech-driven-regulatory-framework-for-cryptocurrency-fm-nirmala-sitharaman/articleshow/94891216.cms'
                  />


                <CustomLink num="3." text="FM Sitharaman flags anonimity as 'inherent risk' in blockchain tech"
                 href='https://www.livemint.com/market/cryptocurrency/fm-nirmala-sitharaman-flags-anonymity-as-inherent-risk-in-blockchain-tech-11651968872268.html'
                  />

                <CustomLink num="4." text="RealEstate 3.0 : The ownership revolution" 
                href='https://www.nfx.com/post/real-estate-3-proptech-the-ownership-revolution?utm_source=twitter&utm_campaign=real-estate-3-proptech-the-ownership-revolution&utm_medium=social&utm_content=nfx'
                 />

               
            </Stack>
        </Stack>
    )
}