import { useNavigate } from "react-router-dom";
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import {Stack, Typography} from "@mui/material";



export const LearnMore = ({sx={},title="Learn More",link,option}) =>{
    const navigate = useNavigate();

    const handleLink = () =>{
       if(link){
           navigate(link,{state:option});
       }
        
    }

    return(
        <Stack direction={"row"} alignItems={"center"} spacing={{md:1,xs:1}} 
         sx={{cursor:"pointer",...sx, 
         padding:'5px 15px',
         color: '#2B59EC', 
         bgcolor: '#F9FAFD',
         '&:hover': { color: '#0000FF' }, 
         width: 'fit-content',
         borderRadius: '15px'}}>
            <Typography onClick={handleLink} variant="body3">{title}</Typography>     
            {/* <TrendingFlatIcon /> */}
        </Stack>
    )
}
