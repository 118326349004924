import { Box, Stack, Typography } from "@mui/material";
import {React,useEffect} from "react";
import MiscLayout from "./miscLayout";
import CustomLink from "../../components/customLink";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLocation } from "react-router-dom";





export default function HowItWorks(){
    window.heap.track('How it works', {
        buttonName: 'How it works',
        category: 'Page view',
      });

    const tabs = [
        {
            name:"Process",
            component:<Process />
        },
        {
            name:"Ownership Modes",
            component:<OwnershipModes />
        },
        {
            name:"Exit",
            component:<Exit />
        },
        {
            name:"RealX Vs Other Models",
            component:<RealxVsOtherModes />
        },
        {
            name:"View Your Digital Assets Portfolio",
            component:<AboutBlockchain/>
        }
    ]
    return(
        <MiscLayout tabs={tabs} title={"How It Works"} />
    )
}

const Process = () =>{
    return(
        <Stack spacing={{md:4,xs:2.5}}> 
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Investment Process</Typography>
                <Typography variant="body3">RealX presents curated, quality property deals on its platform for our users to choose from for investment. Once a property is fully subscribed, the property deal goes offline, and the sale is executed at the registrar after legal due diligence. Post registration, co-ownership records (as per registered documents) are created on a DLT registry system (called PropChain) for registered co-owners and Property Tokens are minted into the wallets for all Token investors.</Typography>
            </Stack>
            <Box component={"img"} src={"/assets/images/howitworks/howitworks.png"}></Box>
            <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} />
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Post Investment</Typography>
                <Typography variant="body3">
                Post-investment, professional Property Managers are assigned to the property, with the consent of the Co-owners, who manage the day-to-day operations of the property. All rents are collected and processed through RealX, and all co-owners and Token holders can simply enjoy the proportionate share of rental income (after suitable deductions).
                </Typography>
            </Stack>
            <Box component={"img"} src={"/assets/images/howitworks/2.png"}></Box>
        </Stack>
    )
}

const OwnershipModes = () =>{
    const location = useLocation()
    useEffect(()=> {
        if (location.hash) {
            setTimeout(() => {
                let elem = document.getElementById(location.hash.slice(1));
                if (elem) {
                //    console.log('Element found:', elem);
                  elem.scrollIntoView({ behavior: 'smooth', block: 'start' });
                } else {
                //   console.log('Element not found');
                }
              }, 400); 
            } else {
        window.scrollTo({top:0,left:0, behavior: "smooth"})
        } 
}, [location,])
    return(
        <Stack spacing={{md:4,xs:2.5}}>
            <Stack spacing={1.5} mb={{md:0,xs:2.5}}>
                <Typography variant="aboutHeading2">Ownership Modes at RealX</Typography>
                <Box sx={{
                        borderRadius:"10px",
                        bgcolor:"primary.light",
                        padding:{md:4,xs:"16px 20px"}
                    }}>
                    <Typography variant="body3">
                    RealX introduces a pioneering new product that will enable investors the ability to invest in further smaller amounts as well as have much diversified and deeper options to exit from these investments. Our promise hasn’t changed and remains the same – you own and control where you invest. With our new product you get the power of Distributed Ledger Technology - now you have better control, better transparency, and better governance. <br/><br/>
                    Now, we offer property tokens alongside our well established ‘Registered Co-ownership’ (RCO) product. The Property Tokens hold a ‘Right to Registered Co-Ownership’.<br/><br/>
                    {/* While our former product offered a direct ‘Registered Co-ownership’ to the user, the current product, property tokens, gives a right for such registered co-ownership (and is not registered co-ownership until such right is exercised). <br/><br/> */}
                    The Property Tokens offer legally enforceable Secure Direct Rights (SDR) over the property with an Option To Convert (OTC) into a proportionate Registered Co-Ownership by exhausting the token.Such right, when exercised by the Token Holder can be executed into Registered Co-Ownership through a deed executed at Sub-Registrar’s office. <br/><br/>
                    As investor, you will have full beneficial rights emanating from the property (as a Registered Co-owner would) and such rights and terms are established within the Property Token. <br/><br/>
                    Best of all, your rights and obligations are also legally enforceable. RealX is a global pioneer in introducing this – so you can feel safe and invest with confidence.
                    </Typography>
                </Box>
            </Stack>
            <Stack spacing={1.5}>
                <Typography id={"coOwnership"} variant="aboutHeading2">Registered Co-Ownership</Typography>
                <Typography variant="body3">
                This is the first innovation introduced by RealX. This model offers people the chance to invest and have direct rights of co-ownership of the properties and assets that it lists on the platform. These rights are established through registered property deeds at the respective sub-registrar’s office. This mode is referred to as ‘Registered Co-Ownership’ mode. <br/><br/>
                There is a natural limitation to how many people can subscribe to such direct co-ownerships. This leads to a situation where the minimum investments for direct co-ownerships can become prohibitively high esp for high valued properties or assets.
                {/* Also, despite us being able to convert this property holding into a Digital Holding, the Registered Co-ownership mode suffers from challenges of registration etc when you exit your investment. Because of these challenges of higher ticket sizes and process (and time) for registration, the liquidity (options to exit) in market for secondary sales of Registered Co-ownership will not be high as experienced in other digitally available assets like Stocks and Bonds etc. <br/><br/> */}
                {/* Reasons valid enough for us to move forward when the time became right.  */}
                </Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Challenges of Registered Co-Ownership</Typography>
                <Typography variant="body3">
                The Registered Co-ownership suffers from challenges of registration etc. when you exit your investment. Because of these challenges of higher ticket sizes and process (and time) for registration, the liquidity (options to exit) in market for secondary sales of Registered Co-ownership will not be high as experienced in other digitally available assets like Stocks and Bonds etc<br/><br/>
                Reasons valid enough for us to move forward when the time became right. 
                </Typography>
            </Stack>
            <Stack id={"propertyTokens"} spacing={1.5}>
                <Typography variant="aboutHeading2">Property Tokens</Typography>
                <Typography variant="body3">
                RealX introduces a new model that enables more people to participate in quality properties. Now everybody can invest in smaller amounts even if the property is high value property – that too without changing the linkage of their investment with direct property ownership (something that we pioneered earlier). <br/><br/>
                Given some favorable changes and clarity in some laws, it was now possible for RealX to offer an instrument (i.e. the Property Token) that gives ‘Right to Registered Property’. This right comes with all the benefits and obligations that are enjoyed by a normal co-owner (in the ‘Registered Co-ownership’ model). Therefore, while you may not be a Registered Co-Owner yet (until you decide to convert), you are a full and direct beneficial owner of the property, for exactly the same proportion, exactly equivalent to a co-owner. Consider this Right to be a Digital Contract or Undertaking that gives you option to get a document (deed) evidencing your Registered Co-Ownership when you decide to exercise this option (and in turn exhaust or ‘Burn’ the Token). <br/><br/>
                Similar Digital Contracts to ‘deliver’ an asset or commodity have been very common and are even traded on our regular exchanges too. Traditionally the exchanges have used Depositories as an electronic registry. In our case we use Blockchain as a registry. We’ve just improved on what already existed, by using the core essence of Distributed Ledger Technology to bring you better and more secure technology. It can usher in a whole new world of products and solutions as the world embraces more Web3, much of which haven't been even imagined yet. 

                </Typography>
            </Stack>
            {/* <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} /> */}
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Advantages of Property Token</Typography>
                <Typography variant="body3">
                Immutability, safety and security are some of the natural advantages of the Distributed Ledger technology that the investors can benefit from. <br/><br/>
                Another benefit is that since this is completely Digital record, RealX can process investments in very small amounts. This allowed us to reduce the minimum investment amount to begin with INR 5000 now – in turn, making this dream of investing in high value, high quality, and high return properties accessible to everyone. <br/><br/>
                Apart from that the big advantage also is that the Property Token can be listed on other Token Exchanges for better investment and liquidity (exit) options for investors.
                </Typography>
            </Stack>
            {/* <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} /> */}
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Why Now?</Typography>
                <Typography variant="body3">
                The core construct of RealX was always designed to leverage the power of Distributed Ledger Technology commonly called as Blockchain. We had waited so far for more clarity in legal, tax, regulatory and technology framework to evolve. This was to ensure we are more sure footed and our investors’ interests are covered well. 
                <br/><br/>There are some recent positive developments that make a compelling case for RealX to launch Property Tokens now.<br/><br/>  
                {/* <br />1. <a rel="noreferrer" href="/assets/docs/GR.pdf" target={"_blank"}>GR by Government of India classifying Property linked Tokens to be out of purview of punishing ‘Crypto Tax’ </a> */}
                
                <CustomLink   href="/assets/documents/GR-issued-by-CBDT.pdf"   text="GR by Government of India classifying Property linked Tokens to be out of purview of punishing ‘Crypto Tax’"/><br/>  

                {/* <br />2. <a rel="noreferrer" href="https://community.nasscom.in/communities/policy-advocacy/update-meity-unlocks-digital-documentation-financial-and-real-estate" target={"_blank"}>Amendment in IT Act to include property related transaction within the purview of IT Act</a> */}
                
                <CustomLink  href="https://community.nasscom.in/communities/policy-advocacy/update-meity-unlocks-digital-documentation-financial-and-real-estate"  text="Nasscom: MEITY Paves the Way for Digital Transformation in Real Estate and Financial Documentation"/>
               
                <br />Repeated clarity by Government that they welcome innovation in Blockchain based products and solutions  
                <br /><br />Also, there were challenges in the core technology even as it was rapidly evolving. We now have some workable solutions that can be a decent start. 
                </Typography>
            </Stack>   
            <DifferenceTokenRco/>
        </Stack>
    )
}

const Exit = () =>{
    return(
        <Stack spacing={4}>
           
            <Stack spacing={1.5}>
            <Box 
                        sx={{
                            borderRadius:"10px",
                            bgcolor:"primary.light",
                            px:4,
                            py:1,
                            // width:"100%",
                            textAlign:"center"
                        }}
                    >
                        <Typography  sx={{fontWeight:'600'}}  variant="miscItalic">"Exits are the best part of investment cycles"</Typography>
                    </Box>
                <Typography align="center" sx={{fontWeight:'600'}} variant="aboutHeading2">RealX provides multiple exit for investors</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Full Sale</Typography>
                <Typography variant="body3">RealX will entertain bonafide proposals of full and outright purchase of property. Such deals will be duly negotiated deals presented to the co-owners for their consent by voting. If the negotiated deal is approved by the co-owners, then the deal is executed and all realizations from the sale are distributed proportionately to the respective co-owners.</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Token listing on Other Exchanges (for Property Tokens)</Typography>
                <Typography variant="body3">One of the core benefits of holding the property asset in the tokenized form is that they will enjoy much more liquidity options as Tokens than with Registered Co-Ownership.RealX will enable RealX Secondary Market for token holders to sell their tokens and Exit. The Tokens can also be traded on alternate exchanges, and this opens up a wide market for investors to liquidate (sell) and exit as and when they may want.</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Fractional Sale (for Registered Co-Owners)</Typography>
                <Typography variant="body3">If a registered Co-owner wants to sell to someone who would like to hold it as Registered Co-owner (and not property token), then RealX will facilitate such transfer of registered co-ownership to a new Co-owner. Alternately, RealX Secondary Market shall also enable for registered co-owners to exit to new investors who can hold it as property tokens </Typography>
                 {/* RealX will also provide an open market for Registered Co-Owners to negotiate a sale of the Registered Co-Ownerships. However, these may not be as liquid as Token exchanges owing to higher ticket sizes and slower process due to offline registration requirements. */}
            </Stack>

        </Stack>
    )
}


const RealxVsOtherModes=()=>{
    return(
        <Stack spacing={{md:6,xs:2.5}}>
         <MarketPlaceVsFund />
         <RealXVsReit/>
         <DifferenceTokenRco />
        </Stack>
    )
}


const DifferenceTokenRco=()=>{

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
         
        return (
            <Stack id={"ownership-modes"} spacing={{md:3,xs:1.5}}>
                 <Box 
                        sx={{
                            borderRadius:"10px",
                            bgcolor:"primary.light",
                            px:4,
                            py:1,
                            // width:"100%",
                            textAlign:"center"
                        }}
                    >
               <Typography variant="aboutHeading2" align="center">RealX Ownership Modes - A Comparison</Typography>
                 </Box>
               <Stack>
                <TableContainer component={Paper}>
                    <Table sx={{}} aria-label="customized table">
                        <TableHead>
                            <TableRow  >
                                <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',borderRight:'1px solid #979A9A',minWidth:'200PX'}} align="center">
                                <Typography variant="body3" sx={{color:'#fff'}}>Tokenisation</Typography> 
                                </TableCell>
                                
                                <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',minWidth:'200PX'}} align="center">
                                <Typography variant="body3" sx={{color:'#fff'}}>Registered co-ownership(RCO)</Typography>
                                </TableCell>
                        
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Digital Asset</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Not a digital Asset</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Token Trust Acts as sole Co-Owner in Sale Deed</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Co-Owners' name directly included in Sale deed</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Beneficial Co-Ownership right in property</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Direct Co-Ownership right in property</Typography>
                                </StyledTableCell>

                            </StyledTableRow>


                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Transfer does not require registration (Even as per property laws)</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Transfer requires registration</Typography>
                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Property based taxes applicable(Crypto tax does not apply)</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Property based taxes applicable</Typography>
                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Stamp Duty not applicable(on Token sale)</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Stamp duty applicable on sale</Typography>
                                </StyledTableCell>

                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Easier to Sell/Transfer</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Difficult to Sell/Transfer</Typography>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Token Holder KYC mandatory (Even if bought in secondary)</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">KYC mandatory for all RCO investors</Typography>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Can be held by Indians as well as foreigners</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Only Indians can become co-owner by RCO</Typography>
                                </StyledTableCell>
                            </StyledTableRow>

                            <StyledTableRow>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid  #979A9A'}} align="center">
                                    <Typography variant="body3">Custody as well as all Rights & Obligations rest with Token Holders(as Beneficial Owners)</Typography>
                                </StyledTableCell>
                                <StyledTableCell sx={{ width:{md:'40%',xs:'50%'}, whiteSpace: 'normal', wordWrap: 'break-word' }} align="center">
                                    <Typography variant="body3">Custody as well as all Rights & Obligations rest with Token Holders(as Beneficial Owners)</Typography>
                                </StyledTableCell>
                            </StyledTableRow>
                           <StyledTableRow>
                              
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                </Stack>
            </Stack>
        );
      }








  const MarketPlaceVsFund=()=>{
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const marketPlaceVsFund =[
        {parameter:'Business Model',realXModel:"Marketplace",spvModel:"Asset Management",fundModel:"Asset Management"},
        {parameter:'Property Ownership',realXModel:"Direct Legal Rights in Property",spvModel:"Owned by the SPV with shares/securities held by investor",fundModel:"Proportionate Units in a Fund"},
        {parameter:'Property Registration',realXModel:"Registered Specific Trust as Registered/Legal Owner",spvModel:"The SPV registers and Owns the Property",fundModel:"The Fund registers and Owns the Property"},
        {parameter:'Due Diligence and other services',realXModel:"Independent 3rd party - for most services",spvModel:"Independent or In-House",fundModel:"Independent or In-House"},
        {parameter:'Property Management',realXModel:"Independent Accredited Asset Managers",spvModel:"Managed Directly",fundModel:"Managed Directly"},
        {parameter:'Regulation',realXModel:"Does not come under any regulation (as of now)",spvModel:"Likely incidence of Collective Investment Scheme",fundModel:"Registered with SEBI as AIF"},
        {parameter:'Minimum Investment for investor',realXModel:"INR 5000 onwards",spvModel:"INR 10 Lakhs Onwards",fundModel:"INR 1 Crore (AIF Cat II)"},
        {parameter:'Minimum Asset Value',realXModel:"No Minimum Value or Size",spvModel:"No Minimum Value or Size",fundModel:"INR 20 Crore (per AIF Cat II Rules)"},
        {parameter:'Asset Holding Period',realXModel:"Not Fixed - asset can be held in perpetuity",spvModel:"Not Fixed - asset can be held in perpetuity",fundModel:"Fixed - only as long as the fund tenure"},
        {parameter:'Decision Making',realXModel:"Investors take direct call over their ownership",spvModel:"Investors have indirect say in decision making",fundModel:"Not directly in hands of Investors"},
        {parameter:'Secondary Exit/Liquidity',realXModel:"Token is freely transferable OTC basis + Secondary Liquidity on RealX & Other Digital Exchanges + Full Exit of Property through negitiated End Sale",spvModel:"Managed through the the managers/platform",fundModel:"Managed through the the managers/platform"},
        {parameter:'Primary Investment Fees (in general)',realXModel:"1%",spvModel:"1-2%",fundModel:"1% (Closing Costs)"},
        {parameter:'Annual Management Charges (AMC)',realXModel:"5% of Income (Rent) Processed (No AMC)",spvModel:"AMC 1% of Amount Invested (in general across platforms)",fundModel:"2% of Amount Invested"},
        {parameter:'Carried Interest (Carry)',realXModel:"Applicable",spvModel:"Depends on platform",fundModel:"Applicable"},
        {parameter:'Taxation : On Rental Income',realXModel:"Direct (pari-passu) - Treated as AOP",spvModel:"25% of Rent paid as Corporate Income Tax",fundModel:"Direct (pari-passu) - to Investor"},
        {parameter:'Taxation : On Secondary Sale (Stamp Duty & Registration)',realXModel:"Nil (on Token sale)",spvModel:"Negligible (on Demat Securities)",fundModel:"May not be high - Depends on Fund Structure"},
        {parameter:'',realXModel:"",spvModel:"",fundModel:""}
      
    
    ]

    return(
        <Stack spacing={{md:3,xs:1.5}}>
        <Typography variant="aboutHeading2">Marketplace Vs Fund model</Typography>
        <Stack>
         <TableContainer component={Paper}>
             <Table   aria-label="customized table">
                 <TableHead>
                     <TableRow  >
                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',borderRight:'1px solid #979A9A'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>Parameter</Typography> 
                         </TableCell>
                         
                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word' ,borderRight:'1px solid #979A9A'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>RealX Model (Marketplace)</Typography>
                         </TableCell>

                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',borderRight:'1px solid #979A9A'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>SPV Model</Typography>
                         </TableCell>

                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>Fund Model</Typography>
                         </TableCell>
                 
                     </TableRow>
                 </TableHead>
                 <TableBody>

                            {marketPlaceVsFund.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A',minWidth:'50PX'}} component="th" scope="row" align="center">
                                        <Typography variant="body3" fontWeight={500}>{row.parameter}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A',minWidth:'200PX'}} align="center">
                                    <Typography variant="body3">{row.realXModel}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A',minWidth:'200PX'}} align="center">
                                    <Typography variant="body3">{row.spvModel}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word',minWidth:'200PX'}}align="center">
                                    <Typography variant="body3">{row.fundModel}</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
 
                 </TableBody>
             </Table>
         </TableContainer>
         </Stack>
     </Stack>

    )
  }    
      

  const RealXVsReit=()=>{

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));

      const RealXVsReit =[
        {parameter:"Invested Asset",reit:"Security (REIT Units)",realx:"Property"},
        {parameter:"Diversification",reit:"Yes. REIT unit represents a diverse set of property investments",realx:"No. At RealX investors invest in single property units chosen by them."},
        {parameter:"Liquidity",reit:"Exchange Listed",realx:"Through RealX Secondary Market (coming up soon)"},
        {parameter:"Stability",reit:"Yield, Value & Returns Subject to market volatility",realx:"Stable. Not subject to market volatility"},
        {parameter:"Minimum Asset (pool) Size",reit:"INR 500 Crore (of pooled property assets)",realx:"No Asset Size Limit"},
        {parameter:"Regulation",reit:"Regulated",realx:"Not Regulated yet but based on base property and other laws"},
        {parameter:"Investment Asset Type",reit:"Restricted",realx:"Not Restricted"},
        {parameter:"Fee Structure",reit:"Heavy",realx:"Lighter"},
        {parameter:"Investment Decision",reit:"By Fund Manager",realx:"By Investors themselves"},
        {parameter:"Working Method",reit:"More Discretionary",realx:"More Transparent"},
        {parameter:"",reit:"",realx:""},
      
      
    
    ]

    return(
        <Stack spacing={{md:3,xs:1.5}}>
        <Typography variant="aboutHeading2">RealX Vs Reit</Typography>
        <Stack>
         <TableContainer component={Paper}>
             <Table sx={{minWidth:500}} aria-label="customized table">
                 <TableHead>
                     <TableRow  >
                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',borderRight:'1px solid #979A9A'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>Parameter</Typography> 
                         </TableCell>
                         
                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word' ,borderRight:'1px solid #979A9A',minWidth:'200PX'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>REIT</Typography>
                         </TableCell>

                         <TableCell sx={{ bgcolor:'#21618C',whiteSpace: 'normal',wordWrap: 'break-word',borderRight:'1px solid #979A9A',minWidth:'200PX'}} align="center">
                         <Typography variant="body3" sx={{color:'#fff'}}>RealX</Typography>
                         </TableCell>
                 
                     </TableRow>
                 </TableHead>
                 <TableBody>
                            {RealXVsReit.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A'}} component="th" scope="row" align="center">
                                        <Typography variant="body3" fontWeight={500}>{row.parameter}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A'}} align="center">
                                    <Typography variant="body3">{row.reit}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{whiteSpace: 'normal', wordWrap: 'break-word', borderRight:'1px solid #979A9A'}} align="center">
                                    <Typography variant="body3">{row.realx}</Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
 
                 </TableBody>
             </Table>
         </TableContainer>
         </Stack>
     </Stack>
    )

  }

      
const AboutBlockchain = () => {

    return (
        <Stack spacing={{ lg: 5, md: 3, xs: 2 }}>
            <Stack>
                <Box sx={{
                    borderRadius: "10px",
                    bgcolor: "primary.light",
                    padding: { md: 2, xs: "12px 20px" },
                    textAlign: "center"
                }}>
                    <Typography sx={{}} variant="aboutHeading2">How to check your portfolio on Algorand Blockchain?</Typography>
                </Box>
            </Stack>

            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{
                    width: { lg: "80%", md: "90%", sm: "90%", xs: "100%" },
                    height: {
                        lg: "450px",
                        md: '400px',
                        sm: '350px',
                        xs: '200px'
                    },
                }}>
                    <iframe
                        class="video"
                        id="ytplayer"
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/RQE1Xqs-ri4?playlist=RQE1Xqs-ri4&rel=0&loop=1&showinfo=0&modestbranding=1&autoplay=1&mute=0`}
                        frameborder="0"
                        allowfullscreen="allowfullscreen"
                        loop="loop"
                        title="YouTube Video"
                    ></iframe>
                </Box>
            </Box>

            <Stack spacing={{ lg: 4, md: 2, xs: 2 }}>
              
                <Stack>
                   
                    <List>
                        <ListItem sx={{ padding: '0', margin: '0' }}>
                            <Typography variant="aboutHeading3">Step 1: Visit any Algorand Explorer </Typography>
                        </ListItem>
                        <List sx={{ paddingLeft: { xs: "20px", md: "45px" } }}>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3" >Go to any Algorand Explorer website/wallet that provides real-time insights into the Algorand blockchain network.</Typography>
                            </ListItem>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3">Here's a list of popular and reliable Algorand Explorers:</Typography>
                            </ListItem>
                            <ListItem sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "start" }}>
                                <Box sx={{ marginRight: '25px', marginBottom: { xs: '5px', md: 'none' } }}>
                                    <CustomLink num="1." text="Pera Wallet" href="https://explorer.perawallet.app/" />
                                </Box>
                                <Box sx={{ marginRight: '25px', marginBottom: { xs: '5px', md: 'none' } }}>
                                    <CustomLink num="2." text="Allo Explorer" href="https://allo.info/" />
                                </Box>
                                <Box sx={{ marginRight: '20px' }}>
                                    <CustomLink num="3." text="DappFlow Explorer" href="https://app.dappflow.org/explorer/home" />
                                </Box>
                            </ListItem>
                            <ListItem style={{ padding: '0', margin: '0', display: 'flex', alignItems: 'flex-start' }}>
                                <VisibilityIcon /><Typography variant="body3" sx={{ paddingLeft: "5px" }}> Note: Just make sure you're on the Mainnet, and not Testnet or Betanet</Typography>
                            </ListItem>

                        </List>

                    </List>


                    <List>
                        <ListItem sx={{ padding: '0', margin: '0' }}>
                            <Typography variant="aboutHeading3">Step 2: Input your wallet address</Typography>
                        </ListItem>
                        <List sx={{ paddingLeft: { xs: "20px", md: "45px" } }}>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3" >Upon reaching the homepage, identify the search bar, usually positioned towards the top of the page.This is where you will input your Algorand wallet address.</Typography>
                            </ListItem>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3">Type in your unique Algorand wallet public address into the search bar.</Typography>
                            </ListItem>
                            <ListItem sx={{ padding: '5px 0px 0px 0px', margin: '0', display: 'flex', alignItems: 'flex-start' }}>
                                <VisibilityIcon /><Typography variant="body3" sx={{ paddingLeft: "5px" }}> Note: You can find your public address in the registration mail from RealX.<br />
                                    <Box sx={{ paddingLeft: { md: "7%" } }}>Ensure there are no typos, as this could lead to incorrect or missing information.</Box></Typography>
                            </ListItem>
                        </List>

                    </List>

                    <List>
                        <ListItem sx={{ padding: '0', margin: '0' }}>
                            <Typography variant="aboutHeading3">Step 3: Review your digital account information</Typography>
                        </ListItem>
                        <List sx={{ paddingLeft: { xs: "20px", md: "45px" } }}>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3" >Following the search, Algorand Explorer will display various details about your account.</Typography>
                            </ListItem>
                            <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                <Typography variant="body3">This includes your asset portfolio, current balance of ALGO tokens, transaction history, and other related information such as transaction fees paid and received.</Typography>
                            </ListItem>
                        </List>

                    </List>

                </Stack>
                <Stack>
                <Box sx={{
                    borderRadius: "10px",
                    bgcolor: "primary.light",
                    padding: { md: 2, xs: "12px 20px" },
                    textAlign: "center"
                }}>
                    <Typography variant="aboutHeading2">Other things you can do</Typography>
                </Box>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }}  justifyContent={'center'} alignItems={{xs:"center",md:'unset'}} >
                    <Card  sx={{ maxWidth: { xs: '94%', md: '48%' },   boxShadow: 3 }}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div"> 
                                View your assets details </Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >On Asset's dashboard page, you will see information like name of the asset, asset id, unit name, creation date, liquidity, creator's address etc.</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3">Here's a list of key details to verify RealX digital asset:</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', }}>
                                    <Typography variant="body3">1. Make sure you're on a Mainnet network</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', }}>
                                    <Typography variant="body3">2. Unit name should always be "FRAX"</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', }}>
                                    <Typography variant="body3">3. Asset must have a working ASA Url</Typography>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>
                    <Card sx={{ maxWidth: { xs: '94%', md: '48%' }, boxShadow: 3 ,margin: { xs: "5px 0px", md: "0px 10px" },}}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div"> Explore transaction history </Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" > Delve deeper into your transaction history to see all incoming and outgoing transactions associated with your wallet address.</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3">This can provide valuable insights into your trading activity and interactions within the Algorand blockchain.</Typography>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>
                </Stack>
                <Stack direction={{ xs: "column", md: "row" }}  justifyContent={'center'} alignItems={{xs:"center",md:'unset'}} >
                    <Card sx={{ maxWidth: { xs: '94%', md: '48%' }, boxShadow: 3 }}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div"> Check transaction details</Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >Clicking on individual transactions will reveal more detailed information, including the timestamp, transaction ID, sender and receiver addresses, and the amount of ALGO transferred.</Typography>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>
                    <Card sx={{ maxWidth: { xs: '94%', md: '48%' },margin: { xs: "5px 0px", md:"0px 10px" }, boxShadow: 3 }}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div">Use filters for specific insights</Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >Utilize the available filters on the Algorand Explorer to narrow down your search.</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >You can filter by transaction type, status, or time period to focus on specific aspects of your portfolio.</Typography>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>


                </Stack>
                <Stack direction={{ xs: "column", md: "row" }} justifyContent={'center'} alignItems={{xs:"center",md:'unset'}} >
                    <Card sx={{ maxWidth: { xs: '94%', md: '48%' }, boxShadow: 3 }}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div"> Explore IPFS documents of the digital asset </Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >Click on the ASA URL to open IPFS gateway in the new tab</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3">You'll get two important documents there:</Typography>
                                </ListItem>

                                <ListItem style={{ padding: '0', margin: '0' }}>
                                    <Typography variant="body3">1. Index II of the real estate asset</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0' }}>
                                    <Typography variant="body3">2. Digitally signed legal note by eMudhra</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3">Note: Both documents will be encrypted by a secure keyphrase for security reasons.</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0'}}>
                                <Box sx={{ marginRight: '25px', marginBottom: { xs: '5px', md: 'none' } }}>
                                    <CustomLink text="What is IPFS" href="https://en.wikipedia.org/wiki/InterPlanetary_File_System" />
                                </Box>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>
                    <Card sx={{ maxWidth: { xs: '94%', md: '48%' }, margin: { xs: "5px 0px", md: "0px 10px" }, boxShadow: 3 }}>

                        <CardContent>
                            <Typography gutterBottom variant="aboutHeading3" component="div">Check current holders of the asset</Typography>

                            <List sx={{ paddingLeft: { xs: "5px", md: "20px" } }}>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >Go back to asset's dashboard page and click on the "holders" tab</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >All the accounts listed here are Co-owners with you in the particular digital asset</Typography>
                                </ListItem>
                                <ListItem style={{ padding: '0', margin: '0', display: 'list-item', listStyleType: 'disc', listStylePosition: 'outside' }}>
                                    <Typography variant="body3" >You can also cross check your own public address and number of FRAX in the table</Typography>
                                </ListItem>
                            </List>

                        </CardContent>

                    </Card>

                </Stack>

                <Stack>
                    <Typography>By following these steps, you can easily get a clear picture of your Algorand holdings and transactions, along with their related activities.</Typography>
                </Stack>

            </Stack>

        </Stack>
    )
}
