import { Box, Button, Card, Dialog, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import * as Yup from 'yup';
import { useFormik,Form, FormikProvider } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import FormField from "../form/formField";
import userServices from "../../services/userServices";
import { useSnackbar } from "notistack";
import { EXTERNAL_URLS } from "../../utils/constants";

const inviteSchema = Yup.object().shape({
    name:Yup.string().required("Name is required."),
    email:Yup.string().email("Please enter valid email.").required("Email is required."),
    location:Yup.string().required("Location is required")
});

export default function StartInvesting({open,handleClose = ()=>{},setShowLogin}){
    const { enqueueSnackbar } = useSnackbar();

    window.heap.track('Start Investing', {
        buttonName: 'Start Investing',
        category: 'Home page event',
      });

    const formik = useFormik({
        initialValues: {
            name:"",
            email:"",
            location:"",
        },
        validationSchema: inviteSchema,
        onSubmit: (v) => {
            // console.log(v,"v");
          saveDetails(v);
        //   saveDetails(v);
        },
    });


    const saveDetails = async(data) =>{
        const res = await userServices.addInvite(data);
        if(res && res.success){
            enqueueSnackbar("You have been successfully added to invite list.",{variant:"success"});
            handleClose();
        }
        else{
            enqueueSnackbar("Something went wrong.",{variant:"error"})
        }
    }
    
    const { errors, touched, handleSubmit, getFieldProps,resetForm } = formik;

    const onClose = () =>{
        resetForm();
        handleClose();
    }

    const openSignUpForm =()=>{
        setShowLogin(true);
        onClose();
    }

    return(
        <Dialog
           
            maxWidth={"md"}
            scroll={"body"}
            PaperProps={{sx:{overflow:"auto",borderRadius:"15px",width:{md:"100%"}}}}
            open={open}
            onClose={onClose}
        >
            <Card>
                <Box sx={{
                    position:"absolute",
                    right:0,
                    padding:2.5,
                    color:{md:"#FFF",xs:"text.disabled"}
                }}>
                    <CloseIcon onClick={onClose} sx={{width:24,height:24,cursor:"pointer"}} />
                </Box>
                <Grid container width={"100%"}>
                    <Grid item md={6} xs={12}>
                        <Box padding={5}>
                            <FormikProvider value={formik}>
                                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                    <Stack spacing={3}>
                                        <Stack>
                                        <Typography variant="h4">Join The Invite list</Typography>
                                        <Typography variant="body3">Get early access for pre-launch deals.</Typography>
                                        <Stack direction={"row"} spacing={0.5} mt={1}>
                                            <Typography variant="body2">Have an invite code?</Typography>
                                            <Typography onClick={() => window.open(EXTERNAL_URLS, '_blank')} variant="body2" sx={{color:"primary.main",cursor:"pointer"}}>Signup</Typography>
                                        </Stack>
                                        </Stack>
                                        <FormField 
                                            label={"Enter Your Name*"}
                                            inputProps={{
                                                ...getFieldProps("name"),
                                                error:Boolean(touched.name && errors.name),
                                                helperText:touched.name && errors.name
                                            }}
                                        /> 
                                        <FormField 
                                            label={"Enter Location"}
                                            placeHolder={"eg - Mumbai, India"}
                                            inputProps={{
                                                ...getFieldProps("location"),
                                                error:Boolean(touched.location && errors.location),
                                                helperText:touched.location && errors.location
                                            }}
                                        /> 
                                        <FormField 
                                            label={"Enter Email ID*"}
                                            inputProps={{
                                                ...getFieldProps("email"),
                                                error:Boolean(touched.email && errors.email),
                                                helperText:touched.email && errors.email
                                            }}
                                        /> 
                                        <Stack pt={1}>
                                            <Button type="submit" variant="contained">Submit</Button>
                                        </Stack>
                                    </Stack>
                                </Form>
                            </FormikProvider>
                        </Box>
                    </Grid>
                    <Grid item md={6}>
                        <Box
                            sx={{
                                borderRadius:"0px 15px 15px 0px",
                                background:"url('/assets/images/landing/section3.png') #142331 no-repeat",
                                backgroundSize:"contain",
                                backgroundPosition:"bottom",
                                width:"100%",
                                height:"100%"
                            }}
                        >
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Dialog>
    )
}