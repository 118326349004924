import { Box, Button, Card, Dialog, Grid, Stack, Typography } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel, Select, Switch, Checkbox, InputLabel, MenuItem } from "@mui/material";
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, Field, FormikProvider, ErrorMessage } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import FormField from "../form/formField";
import userServices from "../../services/userServices";
import { useSnackbar } from "notistack";
import { COMMON, PLATFORM_OPTIONS, REALX_REFERENCES_LIST, STATES } from "../../utils/constants";

const inviteSchema = Yup.object().shape({
  fname: Yup.string().required("First Name is required."),
  lname: Yup.string().required("Last name is required."),
  email: Yup.string().email("Please enter a valid email.").required("Email is required."),
  mobile: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be exactly 10 digits').required("Mobile is required."),
  city: Yup.string().required("City is required."),
  area: Yup.string().required("Area/Location is required."),
  state: Yup.string().required("State is required."),
  orgname: Yup.string().required("Orgnization name is required."),
  brand: Yup.string().required("Brand name is required."),
  reference: Yup.string().required("Reference is required."),
  projName: Yup.string().required("Project name is required."),
  stage: Yup.string().required("Project stage is required."),
  saleableunitdescription: Yup.string().required("Saleable Unit Description is required."),
  salesablemarketval: Yup.string().required("Estimated Market Value is required."),
  estrealSp: Yup.string().required("Expected Sale Price (on RealX) is required."),
  doyouknowfraxownership: '',
  haveyouinvestedinfrax: '',
  gotclientsforfrax: '',
  platformsused: '',
});


export default function DevelopersRegistraions({ open, handleClose = () => { }, setShowLogin }) {

  window.heap.track('Developer Signup', {
    buttonName: 'Developer Signup',
    category: 'Associate with us page event',
  });

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      mobile: "",
      city: "",
      area: "",
      state: "Maharashtra",
      orgname: "",
      brand: "",
      projName: "",
      stage: "",
      saleableunitdescription: "",
      salesablemarketval: "",
      estrealSp: "",
      doyouknowfraxownership: false,
      haveyouinvestedinfrax: false,
      gotclientsforfrax: false,
      platformsused: "",
      reference: "",

    },
    validationSchema: inviteSchema,
    onSubmit: (cpDetails) => {
      console.log("Form submited successfully");
      const cpRegistrationPayload = {
        name: `${cpDetails.fname} ${cpDetails.lname}`,
        mobileNo: cpDetails.mobile,
        email: cpDetails.email,
        location: { city: cpDetails.city, area: cpDetails.area, state: cpDetails.state },
        organisation: {
          name: cpDetails.orgname,
          brand: cpDetails.brand
        },
        project: {
          name: cpDetails.projName,
          stage: cpDetails.stage,
          saleableunitdescription: cpDetails.saleableunitdescription,
          salesablemarketval: cpDetails.salesablemarketval,
          estrealSp: cpDetails.estrealSp,
        },
        miscDetails: {
          doyouknowfraxownership: cpDetails.doyouknowfraxownership || false,
          haveyouinvestedinfrax: cpDetails.haveyouinvestedinfrax || false,
          gotclientsforfrax: cpDetails.gotclientsforfrax || false,
          platformsused: selectedInterests,
        },
        reference: cpDetails.reference,
      };

      saveDetails(cpRegistrationPayload);
    },
  });

  /**
   * Signup for Channel Partner
   * @param {*} data Channel partner data
   */
  const saveDetails = async (data) => {
    const res = await userServices.addDevelopersDetails(data);
    if (res && res.success) {
      enqueueSnackbar(COMMON.CHANNEL_PARTNER_TOAST.SUCCESS, { variant: "success" });
      resetForm();
      handleClose();
    }
    else {
      enqueueSnackbar(COMMON.CHANNEL_PARTNER_TOAST.ERROR, { variant: "error" })
    }
  }

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue, resetForm } = formik;

  const onClose = () => {
    resetForm();
    handleClose();
  }

  const [selectedInterests, setSelectedInterests] = useState([]);

  const handleInterestsChange = (event) => {
    setSelectedInterests(event.target.value);
  };

  return (
    <Dialog
      maxWidth={"md"}
      scroll={"body"}
      PaperProps={{ sx: { overflow: "auto", borderRadius: "15px", width: { md: "100%" } } }}
      open={open}
      onClose={onClose}
    >
      <Card>
        <Box sx={{
          position: "absolute",
          right: 0,
          padding: 2.5,
          color: { md: "#000", xs: "text.disabled" }
        }}>
          <CloseIcon onClick={onClose} sx={{ width: 24, height: 24, cursor: "pointer" }} />
        </Box>
        <Grid container width={"100%"}>

          <Grid container>
            <Grid item md={12} xs={12}>
              <Box sx={{ padding: '35px 35px 0px 35px', textAlign: 'center' }}>
                <Stack>
                  <Typography variant="h4" gutterBottom>
                    Signup As Developer
                  </Typography>
                </Stack>
              </Box>
            </Grid>

            <Grid item md={12} xs={12} sx={{
              maxHeight: '85vh', overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '5px', // Adjust the width as needed
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#888', // Scrollbar thumb color
              },
              '&::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#555', // Scrollbar thumb color on hover
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: '#f0f0f0', // Scrollbar track color
              },

            }}>
              <Box sx={{ padding: '0px 35px 35px 35px' }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"First Name*"}
                          inputProps={{
                            ...getFieldProps("fname"),
                            error: Boolean(touched.fname && errors.fname),
                            helperText: touched.fname && errors.fname
                          }}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Last Name*"}
                          inputProps={{
                            ...getFieldProps("lname"),
                            error: Boolean(touched.lname && errors.lname),
                            helperText: touched.lname && errors.lname
                          }}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Email ID*"}
                          inputProps={{
                            ...getFieldProps("email"),
                            error: Boolean(touched.email && errors.email),
                            helperText: touched.email && errors.email
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Mobile Number*"}
                          inputProps={{
                            ...getFieldProps("mobile"),
                            error: Boolean(touched.mobile && errors.mobile),
                            helperText: touched.mobile && errors.mobile,
                            onInput: (e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }
                          }}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"City*"}
                          inputProps={{
                            ...getFieldProps("city"),
                            error: Boolean(touched.city && errors.city),
                            helperText: touched.city && errors.city
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Area/Location*"}
                          inputProps={{
                            ...getFieldProps("area"),
                            error: Boolean(touched.area && errors.area),
                            helperText: touched.area && errors.area
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <FormLabel sx={{ fontSize: '0.875rem !important; color: #B0B0B0' }}>States*</FormLabel> {/* Use the same label */}
                          <Select sx={{ marginTop: '8px' }}
                            {...getFieldProps("state")}
                            error={Boolean(touched.state && errors.state)}
                          >
                            {STATES.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Orgnization name, if any *"}
                          inputProps={{
                            ...getFieldProps("orgname"),
                            error: Boolean(touched.orgname && errors.orgname),
                            helperText: touched.orgname && errors.orgname
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Brand (mention main Brand) *"}
                          inputProps={{
                            ...getFieldProps("brand"),
                            error: Boolean(touched.brand && errors.brand),
                            helperText: touched.brand && errors.brand
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Project Name *"}
                          inputProps={{
                            ...getFieldProps("projName"),
                            error: Boolean(touched.projName && errors.projName),
                            helperText: touched.projName && errors.projName
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Project Stage *"}
                          inputProps={{
                            ...getFieldProps("stage"),
                            error: Boolean(touched.stage && errors.stage),
                            helperText: touched.stage && errors.stage
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Saleable Unit Description *"}
                          inputProps={{
                            ...getFieldProps("saleableunitdescription"),
                            error: Boolean(touched.saleableunitdescription && errors.saleableunitdescription),
                            helperText: touched.saleableunitdescription && errors.saleableunitdescription
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Estimated Market Value *"}
                          inputProps={{
                            ...getFieldProps("salesablemarketval"),
                            error: Boolean(touched.salesablemarketval && errors.salesablemarketval),
                            helperText: touched.salesablemarketval && errors.salesablemarketval,
                            onInput: (e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }
                          }}
                        />
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormField
                          label={"Expected Sale Price (on RealX) *"}
                          inputProps={{
                            ...getFieldProps("estrealSp"),
                            error: Boolean(touched.estrealSp && errors.estrealSp),
                            helperText: touched.estrealSp && errors.estrealSp,
                            onInput: (e) => {
                              e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }
                          }}
                        />
                      </Grid>

                      <Grid item md={12} sm={12} xs={12}>
                        <Field name="doyouknowfraxownership">
                          {({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  onChange={field.onChange}
                                />
                              }
                              label="Do you know about fractional ownership?"
                              labelPlacement="end"
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                                marginRight: '20px',
                              }}
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        {/* Toggle button */}
                        <Field name="haveyouinvestedinfrax">
                          {({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  onChange={field.onChange}
                                />
                              }
                              label="Have you and your clients have invested through fractional ownership"
                              labelPlacement="end"
                            />
                          )}
                        </Field>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>

                        {/* Toggle button */}
                        <Field name="gotclientsforfrax">
                          {({ field }) => (
                            <FormControlLabel
                              control={
                                <Switch
                                  {...field}
                                  checked={field.value}
                                  onChange={field.onChange}
                                />
                              }
                              label="Have you gotten any of your clients to invest in fractional ownership?"
                              labelPlacement="end"
                            />
                          )}
                        </Field>
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormControl fullWidth sx={{ marginTop: '8px' }}>
                          <InputLabel
                            sx={{
                              fontSize: '1.2rem !important',
                              color: '#B0B0B0',
                            }}
                            shrink={true} // Add this prop to prevent the label from floating
                          >
                            If Yes, then select platform(s) you have used
                          </InputLabel>
                          <Select
                            sx={{ marginTop: '18px' }}
                            multiple
                            value={selectedInterests}
                            onChange={handleInterestsChange}
                            name="platformsused"
                            renderValue={(selected) => selected.join(', ')}
                          >
                            {PLATFORM_OPTIONS.map((platform) => (
                              <MenuItem key={platform.value} value={platform.value}>
                                <Checkbox checked={selectedInterests.indexOf(platform.value) > -1} />
                                {platform.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item md={6} sm={6} xs={12}>
                        <FormControl fullWidth>
                          <FormLabel sx={{ fontSize: '0.875rem !important; color: #B0B0B0' }}>RealX Reference</FormLabel>
                          <Select sx={{ marginTop: '8px' }}
                            {...getFieldProps("reference")}
                            error={Boolean(touched.reference && errors.reference)}
                          >
                            {REALX_REFERENCES_LIST.map(option => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage name="reference" component="div" className="error-message" /> {/* Display the error message */}
                        </FormControl>
                      </Grid>

                      {/* ...remaining form fields */}
                    </Grid>
                    <Stack pt={2} direction="row" sx={{ alignItems: 'center', justifyContent: 'center' }} spacing={2}>
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                      <Button type="reset">Reset</Button>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Dialog>
  )
}