// ----------------------------------------------------------------------

export default function Card(theme) {
  return {
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          position: 'relative',
          borderRadius:"15px",
          zIndex: 0, // Fix Safari overflow: hidden with border radius
          overflow:'visible',
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: 'h6' },
        subheaderTypographyProps: { variant: 'body2' },
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(3, 3, 0),
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 40,
          height:"100%",
          // "&:last-child":{
          //   padding: 40,
          // },
          [theme.breakpoints.up('xs')]: {
            padding: 24,
            // "&:last-child":{
            //   padding: 24
            // },
          },
          [theme.breakpoints.up('lg')]: {
            padding: 24,
            // "&:last-child":{
            //   padding: 24
            // },
          },
          [theme.breakpoints.up('xl')]: {
            padding: 40,
            // "&:last-child":{
            //   padding: 40
            // },
          },
        },
      },
    },
  };
}
