import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { checkLogin } from '../../utils/functions'

const PolicyPage = () =>{
    window.heap.track('Policy-us', {
        buttonName: 'policy',
        category: 'Page view',
      });

    return(
        <Card sx={{mt:checkLogin()?5:{md:15,xs:12},mx:{lg:24,md:18,xs:3},mb:5}}>
            <CardContent>
                <Box sx={{pt:{lg:10,xs:4},px:{lg:12,xs:0}}}>
                    <Stack spacing={5} >
                            <Typography variant="aboutHeading">RealX PRIVACY POLICY</Typography>
                        <Stack spacing={3}>
                            <Stack spacing={2}>
                            <Typography>
                                <span style={{fontWeight:"bold"}}>RealX Marketplace Private Limited</span> (“RealX” or “We”) provides this Privacy Policy to
                                inform all the participants of our policies and procedures regarding the collection, use
                                and disclosure of personal/other information we receive from Users and/or Participants
                                of RealX.in and any other associated site operated under the same brand and under the
                                same set of management and control (this “Site”). RealX&#39;s mission is to enable multiple
                                people who may be unrelated to each other to come together and co-own or have
                                proportionate rights to co-own or benefit from certain rights to co-ownership in
                                properties. Our various Users and Participants including registered users and non-
                                registered users, associates or intermediaries share information, that may include
                                personal identifiable information inter alia and make necessary disclosures to engage
                                with each other, exchange knowledge and business insights and enable them to benefit
                                from it to perform their respective actions on the site. Maintaining user trust is a top
                                priority for us, so we adhere to the principles outlined in next sections to protect your
                                privacy.
                            </Typography>
                            <Typography>
                            This Privacy Policy applies only to information that you provide to us through this Site.
                            This Privacy Policy may be updated from time to time. We will notify you of any material
                            changes by posting the new Privacy Policy on the Site. You are advised to consult this
                            policy regularly for any changes. Unless otherwise defined in this Privacy Policy, terms
                            used in this Privacy Policy have the same meanings as in our Buyer Agreements and
                            Terms &amp; Conditions.
                            </Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="aboutHeading2">Information Collection and Use by Us</Typography>
                            <Stack spacing={2} >
                                <Typography> <span style={{fontWeight:"bold"}}>Buyers :</span> When you register with us through the Site to become a Buyer, we will ask you
        for your personally identifiable information. This refers to information about you that can
        be used to identify and contact you. Such information may include, but is not limited to,
        your personal details and may include details like Name, Email address, PAN Card copy,
        Mobile or other Contact Number, Bank Account details, Photo, Social Media Handles or
        URLs, some of which may be required information and others may be optional for you to
        share. We use your information to verify your identity, administer your membership as
        User-Buyer, complete your transactions and administer your payments, buying interest
        and other quotes/inquiries/grievances. We also use your Email address to communicate
        Notifications or other information that may be of interest or importance to you.
                                </Typography>
                                <Typography>
                                We collect information of the Buyer/s to build interest, faith, confidence and reliability. In
        case, if anyone observes something which is incorrect, inconsistent or illegal, then
        according to this privacy policy you shall immediately report the same to RealX and
        refrain from publishing or disclosing outside to the general public until it is informed and
        permitted by RealX, to avoid unnecessary harm to other users of the platform. The
        information collected is strictly confidential and meant to be within the closed user group
        and therefore would not be handled casually. Upon such intimation received by RealX, it
        will take all measures to ensure/examine the validity of such observation and may take
        steps to rectify to the extent possible.
                                </Typography>
                                <Typography>When you visit the Site, our servers may automatically record information that your
        browser sends. Such information may include Computer/Mobile devices’ Internet
        Protocol (&quot;IP&quot;) address and similar information, browser and session details, pages of
        our Site that you visit, the time spent on those pages including the User Agreements,
        information you search for on our Site, access times and dates, and other statistics. We
        use this data to monitor and analyse use of the Site by users and participants to ensure
        that they are educated and understand the terms/information made available and to
        identify the interest areas for all.</Typography>
                            </Stack>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Invitations by Buyer to new persons</Typography>
                            <Typography>As part of our facility, Buyer may invite non-registered friends to join RealX Platform by
        sending invitation emails through use of their Account Login Id. The Site may store the
        email addresses to which the invitation was sent by the User-Buyer.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Information Sharing and Disclosure</Typography>
                            {/* <Typography variant="aboutPara">As part of our facility, Buyer may invite non-registered friends to join RealX Platform by sending invitation emails through use of their Account Login Id. The Site may store the email addresses to which the invitation was sent by the Investor-Users.</Typography> */}
                            <Stack spacing={3}>
                                <Typography variant="aboutHeading3">All Users of the Platform, including Buyer, Seller and other parties:</Typography>
                                <Stack variant="aboutPara" spacing={2}>
                                    <Typography>
                                    1. We will display your Personal Information in your profile page which may be
                                        visible to all users on the Site by default. You can add, delete, and alter your
                                        profile information any time. Please be aware that whenever you post any
                                        buy/sell/offer/estimation/interest/quote, it is visible to RealX authorized officials
                                        and specific parties involved on a need to know basis. Information regarding
                                        funds transferred by you with respect to any financial/fund/security/any other
                                        financial instrument transactions is visible only to RealX, the Transaction
                                        Settlement Partners/Vendors/Consultants who are made a part of the processing
                                        and settlement process of REALX either prior to or during the processing for such
                                        transactions.
                                    </Typography>
                                    <Typography>
                                    2. We may share and disclose aggregated information, or metadata or any analysis
                                        of user behaviour or metadata, with all users which may be utilised for
                                        industry/personal analysis, profiling and other purposes, on an anonymous basis.
                                        However, the users shall not share such information/analysis outside the Platform
                                        for marketing by third parties and sale of data outside. We may publish publicly
                                        any analysis done for establishing and marketing the
                                        behavioural/transactional/any other aggregate traits and statistics of our
                                        Platform. Any aggregated information shared in these contexts shall not contain
                                        your strict and agreed confidential or personally identifiable information.
                                    </Typography>
                                    <Typography>
                                    3. Any data of Seller is made available to the Users on the strict understanding that
                                        it is confidential and need not be disclosed outside RealX Platform by them. The
                                        User-buyer also agrees to not solicit any direct connect with the Seller or any
                                        other partner or intermediary directly outside of any facility for engagement and
                                        interaction created or facilitated by RealX, without express consent from RealX.
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack spacing={3} >
                                <Typography variant="aboutHeading3">Service Providers/Associate Partners:</Typography>
                                <Typography variant="aboutPara">We are employing third party Companies and/or Individuals to facilitate our service, to
        provide certain unbiased settlement services on our behalf, to store confidential data, to
        perform Site related services or to assist us in analysing how our Site be used. These
        third parties may have access to your Personal Information or other data only to perform
        these tasks on our behalf or within their scope of services and are obligated not to
        disclose or use it for any other purpose and will be obligated to dispose off the data once
        the activity for which the data was shared is completed.</Typography>
                            </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading3">Law Enforcement:</Typography>
                            <Typography variant="aboutPara">RealX shall cooperate with government and law enforcement
        officials to enforce and comply with the law. We will disclose any information about you
        to government or law enforcement officials as we, in our sole discretion, believe
        necessary or appropriate to respond to claims and legal process, to protect the property
        and rights of the Site or a third party, to protect the safety of all users, or to prevent or
        stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or
        legally actionable activity.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading3">Corporate Restructuring:</Typography>
                            <Typography variant="aboutPara">RealX may transfer or otherwise share some or all of its
        assets, including your Personal Information, in connection with a merger, acquisition, or
        in the event of winding up of the Company. RealX will notify you before transferring your
        information to a new entity in such cases. If the new entity is processing your
        information then it may materially differ from that set forth in this Privacy Policy. All
        users shall be deemed to have consented to new entity and its policies by continuing the
        association unless they express a contradictory intention to not continue with the new
        entity in a manner the new entity may require.</Typography>
                        </Stack>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Access to Information by our Staff</Typography>
                            <Typography variant="aboutPara">
                            We permit only specifically authorized staff members to have access to your information
        on a need to know basis. This access merely enables them to assist you in completing
        transactions as per REALX process and resolving any issues that might arise and are not
        permitted to use it in any other manner. We have policies and procedures in place and
        have educated our staff members on the importance of confidentiality and your privacy.
        Any staff member who wilfully or knowingly violates these policies is subject to strict
        actions.
                            </Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Email</Typography>
                            <Typography variant="aboutPara">
                            We may preserve the content of your email, your email address, and our response so
        that we can more efficiently complete RealX processes and handle any questions you
        may have. This will also make us meet legal and regulatory requirements, if any.
                            </Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">De-registration</Typography>
                            <Typography variant="aboutPara">
                            If you completely de-register from RealX Platform, then your Account will become
        deactivated. We may retain an archived copy of your records as required by law or for
        legitimate business purposes. Data that has been made part of the Distributed Ledger
        deployed by RealX may not be deleted or removed from the Ledger owing to the nature
        of the technology. Given that the data is only accessible to you or your agents or
        representatives via your own private key only, you may consider it safe that the data
        remains safe and inaccessible to anyone else, unless you decide to give access to it or
        personally consent for it to be shared.
                            </Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Security</Typography>
                            <Typography variant="aboutPara">RealX is very concerned with safeguarding your information. We use reasonable security
        measures to protect your information. However, while we strive to use commercially
        acceptable means to protect your Personal/Other Information, we cannot guarantee its
        absolute security. Therefore, RealX shall have no liability in case of hacking or un-
        intentional and hitherto fore unknown lapse of system and security of the data on Site.
        We will make disclosures of any breach of the security, confidentiality, or integrity of
        your information to you via email or other means in the most expedient time possible
        and without unreasonable delay.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">International Participants</Typography>
                            <Typography variant="aboutPara">In case of International participants, your information will be transferred and maintained
        at a location that is outside your governmental jurisdiction where the privacy laws may
        not be as protective as those in your jurisdiction. Your consent to this Privacy Policy
        followed by your submission of such information represents your agreement to such
        transfer.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">External Links</Typography>
                            <Typography variant="aboutPara">You may find various links on our Site, few of which may be owned and maintained by
        us and few of which are owned and maintained by independent parties on whom we
        have no control and hence not covered under our Privacy Policy. This privacy policy
        commonly applies to only the links that are owned and maintained by us. The User is
        expected to exercise due caution and use their discretion while accessing such links or
        availing such external services.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Site Maintenance</Typography>
                            <Typography variant="aboutPara">Our Services may not be uninterrupted or error free or virus free and RealX disclaims all
        warranties, express or implied, written or oral, including but not limited to warranties of
        fitness of the services for the purpose of information collection or sharing. Our sole
        obligation and exclusive remedy in the event of interruption in the Services shall be to
        use all reasonable endeavours to restore the said services as soon as reasonably
        possible.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2" >Blog and Social Media Widgets</Typography>
                            <Typography variant="aboutPara">We may have a feature of public or private blogs or comments or reviews on our Site.
        Any information you include in a comment on our blog may be read, collected, and used
        by anyone. If your personal information appears on our blogs and you would like it to be
        removed, contact us on <span style={{color:'#2F6BF2',fontStyle:"italic"}}>hi@realx.in</span>. If we are not able to remove your information, we
        will let you know why. Also, our Site may in future include social media features, like the
        Facebook Like button. These features may collect information about your IP address and
        which page you are visiting on our Site and you will be expected to use such features at
        your own risk and understanding of how the other sites use the data.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Cookies</Typography>
                            <Typography variant="aboutPara">A cookie is a string of information that a website stores on a visitor’s computer, and that
        the visitor’s browser provides to the website each time the visitor returns. RealX uses
        cookies to help RealX identify and track visitors, their usage of RealX website, and their
        website access preferences. Visitors who do not wish to have cookies placed on their
        computers should set their browsers to refuse cookies before using RealX&#39;s websites,
        with the drawback that certain features of RealX websites may not function properly
        without the aid of cookies.</Typography>
                        </Stack>
                        <Stack spacing={3} >
                            <Typography variant="aboutHeading2">Contacting Us</Typography>
                            <Typography variant="aboutPara">
                            If you have any queries about this Privacy Policy, please contact us at <span style={{color:'#2F6BF2',fontStyle:"italic"}}>hi@realx.in</span>. In
        case of any complaints or grievances wrt the use of your personal data, you may register
        a complaint through our Grievance redressal Mechanism and reach out to our if you still
        remain dissatisfied from the response or decision from the Grievance redressal
        mechanism, as mentioned on the Contact Us page of the site. The details of the Data
        Protection Officer and Grievance redressal mechanism is available on our Contact Us
        page.
                            </Typography>
                        </Stack>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}
export default PolicyPage