import { Navigate, useRoutes } from "react-router-dom";
import LandingPage from "./pages/landing/landing";
import AboutUsPage from "./pages/misc/aboutUs";
import MainLayout from "./layouts/mainLayout";
import FaqPage from "./pages/misc/faq";
import PolicyPage from "./pages/misc/policy";
import Terms from "./pages/misc/terms";
import HowItWorks from "./pages/misc/howItWorks";
import WhyRealX from "./pages/misc/whyRealX";
import JsrForm from "./pages/jsrForm";
import FundbezziejsrForm from "./pages/fundbezziejsrForm";
import SaylaJsrForm from  "./pages/saylaJsrForm";
import FintooJsrForm from "./pages/fintooJsrForm";
export default function Router() {
    return useRoutes([
      {
          path:'/',
          element:<MainLayout />,
          children:[
            {path:"",element:<LandingPage />},
          ]
      },
      {
          path:'/',
          element:<MainLayout />,
          children:[
            {path:"how-it-works",element:<HowItWorks />},
            {path:"why-realx",element:<WhyRealX />},
            {path:"about-us",element:<AboutUsPage />},
            {path:"faq",element:<FaqPage />},
            {path:"policy",element:<PolicyPage />},
            {path:"terms-and-conditions",element:<Terms />},
          ]
      },
      
      { path: '*', element: <Navigate to="/" replace /> },
      // { path: '*', element: <Navigate to="/404" replace /> },

      // {
      //   path:'ayodhya',
      //   element:<MainLayout />, // Assuming you want to keep the MainLayout for this route
      //   children:[
      //     {path:"",element:<JsrForm />},
      //     {path:"fb",element:<FundbezziejsrForm />},
      //     {path:"sayla",element:<SaylaJsrForm/>},
      //     {path:"fintoo",element:<FintooJsrForm/>},
      //   ]
      // },
    ]);
  }