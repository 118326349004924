import { Box,Dialog } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';



export default function videos({ data, open, handleClose = () => { } }) {

  const onClose = () => {

    handleClose();
  }

  return (
    <>

    <Dialog
      maxWidth={"lg"}
      scroll={"body"}
      PaperProps={{ sx: { overflow: "auto", width: { xs: "100%" } } }}
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          style: { backgroundColor: 'rgba(0,0,0,0.75)' }
        }
      }}
      >
    {/* <CloseIcon onClick={onClose} sx={{ width: 24, height: 24, cursor: "pointer",bgcolor:"#fff" ,right:0}} /> */}
      
        <Box sx={{
          position: "absolute",
          right: 0,
          padding: 2.5,
          color: { md: "#000", xs: "text.disabled" }
        }}>
          
        </Box>
        <Box className="video-container">
        <iframe 
          class="homepage-left-video"
          id="ytplayer"
          // width="100%"
          // height="510"
          src={`https://www.youtube.com/embed/${data}?playlist=${data}&rel=0&loop=1&showinfo=0&modestbranding=1&autoplay=1&mute=0`}
          frameborder="0"
          allowfullscreen="allowfullscreen"
          loop="loop"
          title="YouTube Video" 
          ></iframe>
           <IconButton
           sx={{bgcolor:{sm:"white",xs:"none"},color:{sm:"black",xs:"white"}}}
            edge="end"
            bgcolor="white"
            onClick={handleClose}
            style={{ position: 'absolute', top: '-5px', right: '5px' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
    </Dialog>
          </>
  )
}