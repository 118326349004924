import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import React from 'react'
import { checkLogin } from '../../utils/functions'

const Terms = () => {
    window.heap.track('terms-and-conditions', {
        buttonName: 'Terms of use',
        category: 'Page view',
    });

    return (
        <Card sx={{ mt: checkLogin() ? 5 : { md: 2, xs: 5 }, mx: { lg: 24, md: 18, xs: 3 }, mb: 2 }}>
            <CardContent>
                <Box sx={{ pt: { lg: 10, xs: 4 }, px: { lg: 12, xs: 0 } }}>
                    <Stack spacing={5} >
                        <Typography variant="aboutHeading">RealX Terms of Use</Typography>
                        <Box>
                            <Typography variant="aboutPara">

                                <Typography variant="aboutHeading2">Welcome to RealX!</Typography>
                                <br></br><br></br>
                                Welcome to RealX, a digital asset platform owned and operated by and proprietary
                                to ‘RealX Marketplace Private Limited’ (“RealX Platform”), a company
                                incorporated in India and having its address at 303, Olympia Business House, above Citroen Showroom, Mumbai - Bangalore Highway Service Road, Baner, Pune MH 411021 (“RealX or we or us or
                                our”).<br></br><br></br>
                                You agree and understand that by accessing or using the RealX Platform or by
                                signing up and opening an account with us or by holding any Digital
                                Assets/FRAX/NFTs in any manner whatsoever, you are deemed to enter into this
                                user agreement (the “User Agreement”) and/or agree to be subject to this User
                                Agreement by and between you and RealX, and you agree to be legally bound by
                                its terms and conditions, so please read them carefully. If any term or condition of
                                this User Agreement is unacceptable to you, please do not visit, access, or use the
                                RealX Platform.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Using our services</Typography>
                                <br></br><br></br>
                                By accessing or signing up and opening an account on the RealX platform, you
                                represent and affirm that you are at least 18 years old, a citizen of India and have
                                the legal capacity to enter into this User Agreement by and between you and RealX
                                and to use the services provided on the RealX Platform and you agree to be legally
                                bound by the terms and conditions of this User Agreement in its entirety.
                                <br></br><br></br>
                                You agree and understand that by logging into your account or authenticating to
                                our application programming interface (“API”) with your API key (“API
                                Authenticating” or “API Authentication”) following any change to this User
                                Agreement, your login or API Authentication, as applicable, shall constitute your
                                agreement to the amended User Agreement by and between you and RealX and
                                you agree to be legally bound by its terms and conditions as amended. Among
                                other things, this means that, if you log into your account or API Authentication
                                following an amendment to this User Agreement, transactions that you or others
                                have already undertaken could be affected by the amended terms and conditions of
                                this User Agreement. You agree and understand that we have the right to require
                                your affirmative assent and continuing acceptance of this User Agreement, from
                                time to time, as a condition of you accessing or using the RealX Platform, or
                                logging into your account, as applicable.
                                <br></br><br></br>
                                If you do not agree to be bound by this User Agreement, you should not access or use the RealX Platform or login to your
                                account. Should you disagree to this User Agreement (including any changes or
                                amendments) please close your account in accordance with the Account Closure
                                procedure of RealX.
                                In order to create your account and use the services offered on the RealX Platform
                                and you must first successfully provide the required KYC information and any
                                other information required by RealX pursuant to our customer identification
                                policies.
                                <br></br><br></br>
                                Please understand that we reserve the right to change any of these terms and
                                conditions at any time. But don’t worry, you can always find the latest version of
                                this User Agreement here on this page.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Applicable Laws and Regulations</Typography>
                                <br></br><br></br>
                                Your conduct on the RealX Platform is subject to the laws of Republic of India and
                                any rules and regulations of any applicable governmental or regulatory authority
                                (the “Applicable Laws and Regulations”).
                                You unequivocally agree and understand that by signing up to the RealX Platform
                                and opening an account and using the RealX Platform in any capacity, you agree
                                and understand to act in compliance with and be legally bound by this User
                                Agreement as well as the Applicable Laws and Regulations and our policies
                                including our privacy policy.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Opening</Typography>
                                <br></br><br></br>
                                Only individuals or corporate entities that have opened an account (“RealX
                                Account”) and successfully completed the KYC and other customer identification
                                requirements prescribed by RealX are considered the customers of RealX (each, a
                                “RealX Customer”).
                                <br></br><br></br>
                                Corporate entities are required to designate specific individual(s) who alone will
                                have the access to the RealX account on behalf of such corporate entities. If such
                                corporate entities wish to nominate any other person or replace the existing
                                nominees, they will have to authorize such nominees as the designated users on
                                behalf of such corporate entities. You alone are responsible all the associated risks
                                for providing your API Authentication key to any third-person.
                                <br></br><br></br>
                                You hereby authorize us, or a third-party service provider, to take any measures
                                that we consider necessary to verify and authenticate your identity, confirm the
                                KYC information that you submit and to take any action we deem necessary based
                                on the results. You undertake to provide any additional information which may be
                                required as per the policies of our third-party service providers in order to verify
                                and authenticate your identity.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Your Account</Typography>
                                <br></br><br></br>
                                In order to access the RealX Platform, you must register and open a RealX
                                Account. Each RealX Account is a Digital Asset Account (“Digital Asset
                                Account”):
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Digital Asset Account</Typography>
                                <br></br><br></br>
                                The Digital Asset Account reflects the current number of Frax held by you. On
                                opening and accessing your RealX Account and before you purchase any Digital
                                Assets on the RealX Platform you will be required to link your existing Digital
                                Wallet with Your Digital Asset Account. If you do not own a Digital Wallet (as
                                defined below) you will need to set up an account with a digital wallet service
                                provider of your choice which supports the storage of your Digital Assets and
                                which is compatible with the block-chain technology used by RealX.
                                <br></br><br></br>
                                Your use of the Digital Wallet selected by you will be governed by the terms of use of the
                                Digital Wallet service provider. If you do not have a Digital Wallet, you will not be
                                able to purchase any NFTs and / or transact on the RealX Platform.
                                <br></br><br></br>
                                “Digital Wallet” means a secured virtual environment which holds and stores your
                                NFTs, which is built upon a block-chain protocol and is secured by a private key
                                created by the user of such wallet.
                                <br></br><br></br>
                                RealX Customers who are corporate entities will have a single User Account
                                which may be operated by multiple authorized users of such corporate entity in
                                accordance with the terms and conditions of this User Agreement. Access to your
                                RealX Account is limited solely to the individuals who have successfully
                                registered and opened an associated User Account. You agree that you will not
                                grant any person access to your User Account, except as described herein, as
                                granting access may violate Applicable Laws and Regulations and compromise the
                                security of your RealX Account.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Access</Typography>
                                <br></br><br></br>
                                You are only permitted to access your RealX Account using your User Account
                                login credentials and other required forms of authentication. We require multi-
                                factor authentication to keep your User Account safe and secure. As a result, you
                                are required to use at least two forms of authentication when accessing your User
                                Account and performing certain operations in your RealX Account. Forms of
                                multi-factor authentication in addition to your login credentials may include one-
                                time passwords delivered through short message service (“SMS”).
                                <br></br><br></br>
                                We reserve the right in our sole discretion to prohibit your access to the RealX
                                Platform temporarily or permanently, if we believe that there is any violation or
                                threatened violation of this User Agreement or if any device on which the
                                operating system has been or is suspected of having been modified or tampered
                                without obtaining our written authorization. You agree that we may provide your
                                login data to a third-party service provider in order to help us authenticate you.
                                <br></br><br></br>
                                You agree that your User Account login credentials and any other required forms
                                of authentication, where applicable, have been chosen by you. You also agree to
                                keep your User Account login credentials and any other required forms of
                                authentication, including your API keys, confidential and separate from each other,
                                as well as separate from any other information or documents relating to your
                                RealX Account and your User Account.
                                <br></br><br></br>
                                You agree and understand that your disclosure of login information to third parties
                                may negate our user authentication measures and allow unauthorized access to
                                your account.
                                You agree that RealX or its third-party service providers cannot be held
                                responsible or liable for unauthorized access or other loss resulting from your direct or indirect disclosure or other transmission, whether intentional or
                                inadvertent, of your login information to third parties.
                                <br></br><br></br>
                                You agree and understand that you are solely responsible (and you will not hold us
                                responsible) for managing and maintaining the security of your User Account login
                                credentials and any other required forms of authentication, including your API
                                keys. You further agree and understand that, we are not responsible or liable (and
                                you will not hold us responsible or liable) for any unauthorized access to or use of
                                your User Account and/or your RealX Account.
                                <br></br><br></br>
                                You agree and understand that you are responsible for monitoring your RealX
                                Account. If you notice any unauthorized or suspicious activity in your account, you
                                are required to contact us immediately.
                                <br></br><br></br>
                                You understand that being a DLT platform our ability to take any action or enforce
                                them is very limited and therefore any corrective actions or remedies, once you
                                inform us of any suspicious activity, shall be on a best effort basis only without
                                any liability being incident on RealX for any action or inaction about the reported
                                activity.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Communication</Typography>
                                <br></br><br></br>
                                You agree and understand that all communication with you and RealX will be via
                                email. We will use the email address on record for your User Account as our
                                primary means of communicating with you. You acknowledge and understand that
                                this is the only authorized way to communicate with each other.
                                <br></br><br></br>
                                Third parties may advertise or publish alternative ways to contact us or use other
                                measures to pose as RealX. We cannot verify the authenticity of any such
                                alternative contacts, and they pose a risk of fraud and other malfeasance, so we
                                strongly recommend you do not attempt to contact us using any unauthorized
                                methods. You agree that we cannot be held responsible or liable for any
                                unauthorized access to your account or other loss resulting from such fraud or
                                other malfeasance by third parties. To ensure that you receive all our
                                communications, you agree to keep your email address up-to-date and immediately
                                notify us if there are any changes to your email address. Delivery of any
                                communication to the email address on record is considered valid.
                                <br></br><br></br>
                                If any email communication is returned as undeliverable, we retain the right to
                                block your access to RealX until you provide and confirm your new and valid
                                email address.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account History</Typography>
                                <br></br><br></br>
                                Your transaction history (“Transaction History”) is always available in your RealX
                                Account. Your Transaction History contains all information of your past
                                transactions, present and pending transactions, and your Digital Asset Account
                                balances.
                                We use commercially reasonable efforts to assure that the information contained in
                                the notices we send you and your Transaction History is accurate and reliable.
                                <br></br><br></br>
                                Any transaction listed in your Transaction History or other communication
                                including, but not limited to, receipts sent to your email on record shall be deemed
                                and treated as authorized and correct, approved, and confirmed by you unless we
                                receive written notice to the contrary from you within 3 (three) calendar days from
                                the date the communication was sent.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2"> Account Review and Acknowledgment</Typography>
                                <br></br><br></br>
                                It is important for you to understand that it is your sole responsibility to review
                                your Transaction History and any notices. If for any reason you are unable to
                                review your Transaction History or if you do not receive our communications or if
                                you have any grievances regarding your Transaction History or the
                                communications sent by us, it is your responsibility to notify us immediately by e-
                                mail in writing.
                                <br></br><br></br>
                                You understand that every communication sent to your email on record will be
                                deemed to have been acknowledged as correct, approved, and confirmed by you
                                unless we have received Written Notice to the contrary within 3 (three) calendar
                                days from the date the communication was sent.
                                <br></br><br></br>
                                We reserve the right to make changes or adjustments to your Digital Asset Account
                                as necessary and appropriate, but only in the event of your breach or default in
                                complying with the terms of this User Agreement or the policies of RealX or for
                                breach of any applicable laws and regulations.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Closure</Typography>
                                <br></br><br></br>
                                You may close your RealX account at any time in accordance with the Account
                                closing procedure. You agree and understand that closing an account will not affect any rights and
                                obligations incurred prior to the date of account closure.
                                You may be required to either cancel or complete all open orders and, in
                                accordance with the provisions of this User Agreement, provide transfer
                                instructions, of where to transfer any Digital Asset remaining in your account. You
                                are responsible for any fees, costs, expenses, charges, or obligations (including, but
                                not limited to, attorney and court fees or transfer costs of Digital Assets) associated
                                with the closing of your account. In the event that the costs of closing of your
                                account exceed the value in your account, you will be responsible for reimbursing
                                us the outstanding amounts spent by us to close your account.
                                <br></br><br></br>
                                You may not close any of your accounts to avoid paying any amounts that are due and payable by you
                                to us under the terms of this User Agreement or to avoid any statutory or
                                regulatory payments including but not limited to any tax payments to be made for
                                the transactions concluded by you on the RealX Platform, or to escape any
                                inquiries or audits.
                                <br></br><br></br>
                                Account closure shall not mean deletion of previous transaction records as RealX
                                will be required to maintain such information as per prevailing laws, regulations
                                and its internal policies.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Suspension</Typography>
                                <br></br><br></br>
                                You agree and understand that we have the right to immediately
                                (i) suspend your RealX Account or in the case of corporate entities, your RealX
                                Account for which you are an authorized representative or authorized signatory;
                                and (ii) freeze/lock your Digital Assets in your Digital Asset account, and
                                (iii) suspend your access to RealX Platform, until a determination has been made,
                                if we suspect, in our sole discretion, you and/or any such accounts to be in
                                violation of:<br></br>
                                (a) Any provision of this User Agreement;<br></br>
                                (b) Any Applicable Laws or Regulations;<br></br>
                                (c) Our Policies updated from time to time; or<br></br>
                                (d) Our Marketplace Conduct Rules,<br></br><br></br>
                                at any time since the opening of such account (each, a “Conduct Violation”).<br></br><br></br>
                                You further agree and understand that we have the right to immediately (i) suspend
                                your RealX Account, (ii) freeze/lock the Frax in your Digital Asset Account and
                                seek to recover additional funds from you to cover your payment obligations under
                                the User Agreement, or any losses suffered by RealX on account of your breach or
                                violation, and (iii) suspend your access to RealX Platform until a determination has
                                been made, if:
                                <br></br><br></br>
                                1. We are required to do so by a regulatory authority, court order, summons, or a
                                binding order of a government authority;<br></br>
                                2. Your RealX Accounts are or subject to any pending litigation, investigation, or
                                governmental proceeding;<br></br>
                                3. If we believe in our sole discretion, someone is attempting to gain unauthorized
                                access to your RealX Accounts;<br></br>
                                4. We believe there is unusual activity in any of your RealX Accounts;<br></br>
                                5. We believe your RealX Accounts were used for any unauthorized or illegal
                                purpose;<br></br>
                                6. We believe you are using RealX Platform or your login credentials, or other
                                account information in an unauthorized or inappropriate manner;<br></br>
                                7. The RealX Platform account is being accessed from a jurisdiction where we do not
                                offer RealX services;<br></br>
                                8. If your RealX Account(s) has been suspended, you will be notified when accessing
                                any of your RealX Accounts on the RealX Platform.
                                We may, in our sole
                                discretion, give Written Notice that your RealX Account has been suspended and
                                may, in our sole discretion, disclose the reasons for suspension, if permitted to do
                                so by Applicable Law.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Investigations</Typography>
                                <br></br><br></br>
                                You agree and understand that we have the right to immediately investigate your
                                RealX Account, if we suspect, in our sole discretion, that you and/or any such
                                account has committed a Conduct Violation or otherwise been engaged in any
                                Prohibited Use or suspicious/illegal activity.
                                <br></br><br></br>
                                You further agree and understand that we have the right to immediately investigate
                                your accounts if:
                                <br></br><br></br>
                                We are required to do so by a regulatory authority, court order, summons, or
                                binding order of a government authority;<br></br>
                                The RealX Account, subject to any pending litigation, investigation, or
                                governmental proceeding;<br></br>
                                Any account was used to facilitate any illegal purpose or activity;<br></br>
                                If we take any of the aforesaid actions relating to your Account, we will give
                                Written Notice and state the reasons for such action. You will have the right to
                                submit, within 7 (seven) calendar days from the date of notification, a written
                                statement to our Chief Compliance Officer (“CCO”) explaining why no
                                disciplinary action should be taken against you.
                                <br></br><br></br>
                                You agree and understand that upon our request and within the time frame
                                designated by us, you and any related party are obligated to respond to any
                                interrogatories in writing and to furnish any documentary materials and other
                                information in connection with any investigation initiated pursuant to this User
                                Agreement.
                                <br></br><br></br>
                                You agree and understand that neither you nor any related party shall impede or
                                delay any investigation or proceeding conducted pursuant to this User Agreement
                                and shall not refuse to comply with any request made pursuant to this User
                                Agreement.
                                <br></br><br></br>
                                Following the conclusion of an investigation, our CCO will make, in his or her sole
                                discretion, a determination based upon the weight of the evidence.
                                You agree and understand that if your RealX Account is determined, in our sole
                                discretion, to have committed a Conduct Violation, we have the right to terminate
                                any such accounts and take any and all necessary and appropriate actions pursuant
                                to this User Agreement and/or Applicable Laws and Regulations.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Remedies for Breach:</Typography>
                                <br></br><br></br>
                                You agree and understand that if you or your RealX Account is determined, in our
                                sole discretion, to have committed a Conduct Violation or violation of any of our
                                policies, we have the right to claim damages from you of amounts that you may be
                                in default to pay to RealX in connection with your ownership of your Frax and any
                                other damages suffered by us as a result of the Conduct Violation. We also have
                                the right to freeze/lock/seize your Digital Assets in your RealX Account if you fail,
                                to comply with your obligation to fund the purchase price or any other payment
                                obligation and you have failed to fulfil such an obligation.
                                <br></br><br></br>
                                You further agree and understand that if we determine, in our sole discretion, that you have colluded,
                                coordinated and/or collaborated with any other User to commit a Conduct
                                Violation or violation of our policies or violation of any applicable law then you
                                will be liable to compensate us for such amounts that are equivalent to the losses
                                suffered by us including any attorney fees. If you disagree with any determination
                                made or remedy exercised under this subsection, you may bring an arbitration
                                action pursuant to the ‘Dispute Resolution’ section of this User Agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Account Termination</Typography>
                                <br></br><br></br>
                                You agree and understand that we have the right to terminate your access to RealX
                                and any RealX Account at any time and for any reason, including for violation of
                                this User Agreement or any of our policies or any applicable law, and that if we do
                                so, your rights and obligations under this User Agreement will continue.
                                You further agree and understand that we have the right to take any and all
                                necessary and appropriate actions pursuant to this User Agreement and/or
                                Applicable Laws and Regulations.
                                <br></br><br></br>
                                If your RealX Account is no longer subject to any investigation, court order, or
                                summons, you authorize us to return your funds, if any, that may be in our
                                possession, (less any payments owed to RealX, offsets, and/or damages to which
                                we are entitled to deduct) to any bank account linked to your RealX Account,
                                unless otherwise required by law.
                                <br></br><br></br>
                                Subject to any rights that RealX may have against the balance of your RealX
                                Account, if there is a Digital Asset balance remaining in your Digital Asset
                                Account, you agree to provide us with a Digital Wallet address within 7 (seven)
                                days upon receiving a Written Notice, so that we can return the remaining Digital
                                Assets to you. If you fail to do so, you hereby agree that we are permitted to sell
                                any remaining Digital Assets on the open market at a price within 10% of the
                                Prevailing Market Price, as determined by us in our discretion, and return the
                                proceeds (less any payment and/or damages to which we are entitled) to any bank
                                account linked to your RealX Account. “Prevailing Market Price” means the
                                prevailing market price of the immovable property / asset whose fractional co-
                                ownership is represented through your holding of the Digital Asset.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Legal Process</Typography>
                                <br></br><br></br>
                                You agree and understand that we, as well as our affiliates, service providers, their
                                respective officers, directors, agents, joint venturers, employees and
                                representatives (collectively, the “RealX Service Provider(s)”), may comply with
                                any writ of attachment, execution, garnishment, tax levy, restraining order,
                                summons, warrant or other legal process, which any of us reasonably and in good
                                faith believe to be valid. We and any RealX Service Provider may in our sole
                                discretion, but are not obligated to, notify you of such process by electronic
                                communication.
                                <br></br><br></br>
                                You agree to indemnify, defend, and hold all of us harmless from all actions,
                                claims, liabilities, losses, costs, attorney’s fees, or damages associated with our

                                compliance with any process that any of us reasonably believe in good faith to be
                                valid.
                                You further agree that we and any RealX Service Provider may honor any legal
                                process, regardless of the method or location of service.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Unclaimed Funds</Typography>
                                <br></br><br></br>
                                If your RealX Account remains closed or dormant for a long enough period of time
                                determined as per our policies, we may be required, upon the passage of applicable
                                time periods, to report any remaining funds in your account as unclaimed property
                                in accordance with applicable laws. If this occurs, we will use reasonable efforts to
                                give Written Notice. If you fail to respond to any such Written Notice within 7
                                (seven) Business Days (as defined herein) or as otherwise required by law, we may
                                be required to deliver any such funds to the applicable authority as unclaimed
                                property. We reserve the right to deduct an administrative fee resulting from such
                                unclaimed funds, as permitted by Applicable Law and Regulations.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Background Checks</Typography>
                                <br></br><br></br>
                                In furtherance of our customer verification policies, we reserve the right to require
                                you to provide us with additional information and/or require you to undergo a
                                background check prior to being authorized to use RealX, or at any point
                                thereafter, in accordance with Applicable Laws and Regulations.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Prohibited Activity</Typography>
                                <br></br><br></br>
                                RealX does not permit the use of the platform for any prohibited activity in a
                                RealX account, including but not limited to: use on any black-market, ransomware,
                                mixing service (i.e., a mixer or tumbler used to obscure the source of funds), or
                                illegal activity that would violate, assist in violation of, or cause RealX to violate
                                any Applicable Laws and Regulations (as defined in the ‘Applicable Laws and
                                Regulations’ section), or which would involve proceeds of any unlawful or illegal
                                activity (collectively, “Prohibited Activity”).
                                <br></br><br></br>
                                You agree and understand that we have the right to immediately (i) suspend your
                                RealX Account, (ii) freeze/lock the funds and assets in all such RealX Accounts,
                                and (iii) suspend your access to RealX, if we suspect, in our sole discretion, your
                                RealX Account to be in violation of our Prohibited Activity.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">RealX Platform</Typography>
                                <br></br><br></br>
                                We offer the opportunity to you for buying and selling of Digital Assets. You may
                                use your RealX Account to buy and/or sell your Digital Assets. When we receive
                                full subscription to the listed property, a transaction (each, a “Transaction”) occurs.
                                Each Transaction (i) transfers ownership of these Digital Assets / Frax between
                                Users, (ii) is recorded on our internal ledger (“Transaction Ledger”), and (iii) is
                                reflected through automatic adjustments to each User’s Digital Asset Account. You
                                agree to pay all fees associated with or incurred by your use of the RealX Platform
                                and understand that all fees will be debited from your RealX Account and all
                                proceeds will be credited to the applicable RealX Account. All Transaction activity
                                is “off-chain” and not broadcast to a given blockchain.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">API Agreement</Typography>
                                <br></br><br></br>
                                You agree and understand that by accessing or using our API, you agree to the
                                terms and conditions for the use of our API
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Transaction Commitment</Typography>
                                <br></br><br></br>
                                To purchase Digital Assets, you must deposit funds into our escrow account to
                                confirm your commitment and you are not permitted to place an Order unless you
                                have deposited enough funds in our escrow account to satisfy your transaction (i.e.
                                all commitments are pre-funded). When you place an commitment, the full amount
                                of funds required (including any fees) need to be deposited in our escrow until the
                                subscription is fulfilled or is cancelled. Any commitment that exceeds the amount
                                of available funds deposited in our escrow account will be rejected and any amount
                                paid in excess of the commitment, will be refunded without interest.
                                <br></br><br></br>
                                Prior to the placement of a commitment, you are required to review the following
                                information:
                                <br></br><br></br>
                                1. The amount to be deposited in our escrow account;<br></br>
                                2. The amount of Digital Assets to be bought or sold;<br></br>
                                3. The gross purchase price (buy) or sale proceeds (sell) of your transaction;<br></br>
                                4. Any fees or other charges associated with your transaction;<br></br>
                                5. The net proceeds to be transferred into your Digital Asset; and<br></br>
                                6. A notification which warns you that once your commitment has been placed<br></br><br></br>
                                it may not be cancellable or otherwise undone (you may choose to hide this
                                notice going forward).
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Terms and Conditions of Commitment</Typography>
                                <br></br><br></br>
                                You agree and understand that commitment placed via the RealX Platform are
                                subject to the provisions of this User Agreement unless specified otherwise. You
                                also agree and understand that the commitment placed by you interact directly with
                                our Platform.
                                <br></br><br></br>
                                You agree and understand that we do not guarantee that any commitment you place
                                will be fulfilled. Commitments that are active before any downtime of the Platform
                                will be handled once our Platform resumes operations. You agree and understand
                                that we are not responsible for your commitment executing at disadvantageous
                                prices or any other losses associated with your commitment not being cancelled
                                during Downtime.
                                <br></br><br></br>
                                You agree and understand that we reserve the right to cancel any commitment, or
                                remaining portion of any transaction, if:
                                <br></br><br></br>
                                1. Any such commitment was placed and/or active during downtime;<br></br>
                                2. Any such commitment violates any provisions of this User Agreement;<br></br>
                                3. Any such commitment is materially non-marketable.<br></br><br></br>

                                You agree and understand that Commitments may be subject to, and we shall have
                                no liability for, delays, difficulties, and/or conditions affecting transmission or
                                execution of commitments over which we have no control, including, but not
                                limited to, mechanical or electronic failure or market congestion.
                                <br></br><br></br>
                                You agree and understand that we reserve the right to require all commitments to
                                pass automated compliance checks. If your commitment is flagged for review by
                                any of these checks or if it meets certain defined criteria, it may require our manual
                                review and approval. We will use commercially reasonable efforts to review such
                                commitments on a timely basis, but we are not liable for any delays. If at any time
                                you are in any doubt about the status of your commitment, you can view your
                                commitment status on RealX. We may cancel any transaction flagged for review.
                                Lastly, we may require you to confirm your transaction via telephone or another
                                form of authentication prior to execution.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Transaction Settlement</Typography>
                                <br></br><br></br>
                                All commitments that are fulfilled, executed, or matched (in whole or in part)
                                (each, a “Transaction”), settle immediately and are recorded on our Platform
                                Ledger.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Transaction Receipts</Typography>
                                <br></br><br></br>
                                After your transaction has been placed, we will send you an email confirming your
                                commitment (each, an “Transaction Receipt”), and another email when your
                                commitment is subsequently fulfilled (in whole or in part) (each, a “Transaction
                                Receipt”).
                                <br></br><br></br>
                                Transaction Receipts contain the following information:
                                <br></br><br></br>
                                The Transaction date, type, ID, amount, and pricing information if applicable;
                                Contact information (including a phone number) for RealX should you have any
                                questions, need to register a complaint, or claim that your transaction was not
                                authorized (subject to applicable limits described herein).
                                <br></br><br></br>
                                Transaction Receipts contain the following information:
                                <br></br><br></br>
                                The Transaction date, type, ID, amount and pricing information;
                                All fees charged;
                                <br></br>
                                Contact information (including a phone number) for RealX should you have any
                                questions, need to register a complaint, or claim that your transaction was not
                                authorized (subject to applicable limits described herein).
                                <br></br><br></br>
                                RealX maintains and retains records of your Transaction History for a period as
                                required by its internal policies &amp; applicable laws and regulations.
                                It is your sole responsibility to review your Transaction History and Transaction
                                Receipts on a regular basis for authenticity.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Marketplace:</Typography>
                                <br></br><br></br>
                                Marketplace Conduct Rules
                                <br></br><br></br>
                                You agree to abide by and understand that we enforce the following marketplace
                                standards of conduct rules (“Marketplace Conduct Rules”) designed to prevent the
                                use of RealX for disruptive or manipulative conduct or deceptive practices,
                                including, but not limited to, disruptive transactions and price manipulation. We
                                take our Marketplace Conduct Rules very seriously and it is our policy to take all
                                the necessary steps to prohibit manipulative conduct or deceptive practices.
                                <br></br><br></br>
                                The following are strictly prohibited on RealX:
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Fraudulent Acts</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall engage or attempt to engage in any
                                fraudulent act or engage or attempt to engage in any scheme to defraud, deceive or
                                trick, in connection with or related to any Transaction on or other activity related to
                                RealX.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Fictitious Transactions</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall create or execute fictitious transactions or
                                place any commitment for a fictitious transaction with knowledge of its nature.
                                <br></br><br></br>
                                <Typography variant='aboutHeading2'>Market Manipulation</Typography>
                                <br></br><br></br>
                                Any disruption or manipulation of the RealX marketplace whatsoever is
                                prohibited.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Violation of Applicable Laws and Regulations</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall engage in conduct that is in violation of any
                                Applicable Laws and Regulations, including but not limited to this User
                                Agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Misstatements/Concealment </Typography>
                                <br></br><br></br>
                                It is a violation of this User Agreement to make any misstatement or for
                                Concealment of a material fact to RealX, its board of directors, any committee
                                thereof, or any director, officer, manager, or employee of RealX.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Disrepute Upon You or RealX </Typography>
                                <br></br><br></br>
                                Neither you nor any related party may access RealX in any way which could be
                                expected to bring disrepute upon you or RealX.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Non-Supervision</Typography>
                                <br></br><br></br>
                                You shall be responsible for establishing, maintaining, and administering
                                reasonable, supervisory procedures to ensure that you and any related parties
                                comply with all Applicable Laws and Regulations. You may be held accountable
                                for the actions of a related party. In addition, you shall be responsible for
                                supervising related parties and may be held accountable for the actions of such
                                related parties.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Money Passes</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall prearrange the execution of transactions on
                                RealX for the purpose of passing money between accounts. All transactions
                                executed on RealX must be made in good faith for the purpose of executing bona
                                fide transactions, and prearranged trades intended to effectuate a transfer of funds
                                from one account to another are strictly prohibited.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Disruptive Practices</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall engage in any trading, practice, or conduct
                                on RealX that:<br></br><br></br>
                                Demonstrates intentional or reckless disregard for the orderly execution of
                                transactions; or<br></br>
                                Is of the character of, or is commonly known as “spoofing” (bidding or offering
                                with the intent to cancel the bid or offer before execution).
                                <br></br><br></br>
                                All commitments must be placed for the purpose of executing bona fide
                                transactions. Additionally, all non-actionable messages must be placed in good
                                faith for legitimate purposes.
                                <br></br><br></br>
                                You shall not place or cause to be placed a commitment with the intent, at the time
                                of entry, to cancel the commitment before execution or to modify the commitment
                                to avoid execution;
                                <br></br><br></br>
                                You shall not place or cause to be placed an actionable or non-actionable message
                                or messages with the intent to mislead other market participants;<br></br>
                                You shall not place or cause to be placed an actionable or non-actionable message
                                or messages with the intent to overload, delay, or disrupt the system of Real or
                                market participants; and<br></br>
                                You shall not place or cause to be placed an actionable or non-actionable message
                                with the intent to disrupt, or with reckless disregard for the adverse impact on the
                                orderly conduct or the fair execution of transactions.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2"> Intimidation</Typography>
                                <br></br><br></br>
                                Neither you nor any related party shall:
                                <br></br><br></br>
                                Engage, directly or indirectly, in any conduct that threatens, harasses, coerces,
                                intimidates, or otherwise attempts improperly to influence another market
                                participant or any other person.
                                <br></br><br></br>
                                This includes, but is not limited to, any attempt to influence a market participant or
                                person to adjust or maintain a price or quotation on RealX or any related market, or
                                any refusal to trade or any other conduct that retaliates against or discourages the
                                competitive activities of another market participant or person.
                                <br></br><br></br>
                                Nothing in this Marketplace Conduct Rule with respect to the coordination of each
                                transaction, Price of the Property shall be deemed to limit, constrain, or otherwise
                                inhibit your freedom: (i) to set your own bid or ask on any transaction, (i) set the
                                price at which you are willing to buy or sell any Digital Asset, and (iii) set the quantity of the Digital Asset that you are willing to buy or sell, provided that such
                                conduct is otherwise in compliance with all Applicable Laws and Regulations.
                                These provisions apply to the entire RealX marketplace at all times.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Custody</Typography>
                                <br></br><br></br>
                                Our Platform Ledger tracks the balance and ownership of your Digital Assets.
                                Entries on our Platform Ledger is not publicly disclosed.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Your Digital Assets </Typography>
                                <br></br><br></br>
                                We will store your Digital Assets in a Trust Account controlled and secured by a
                                trustee appointed by RealX; the balances of which are reflected in the Digital Asset
                                Account of your RealX Account. Digital Assets custodied in a Trust Account are
                                pooled together in one or more of our Digital Wallets. Digital Assets custodied in a
                                Trust Account are custodied pursuant to the NFT Trust Agreement entered into by
                                and between RealX as the Settlor of the trust and the Trustees appointed by RealX.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Deposits and Withdrawals</Typography>
                                <br></br><br></br>
                                We email you receipt confirmation for all deposits and withdrawals.
                                Escrow Deposits Any deposit in escrow account are only accepted from either through Payment
                                Gateway or bank account that meets the following criteria: (i) is in the name of an
                                individual or institution and is in compliance with Applicable Law and that is
                                named on your primary RealX Account, and (ii) is domiciled in the country of
                                residence of the individual or institution named on the RealX Account (each, a
                                “User Bank Account”). If your deposit in our escrow account does not originate
                                from a User Bank Account or account linked to User Bank Account, it can be
                                returned at the earliest.
                                <br></br><br></br>
                                Wire Deposits
                                <br></br><br></br>
                                We accept wire deposits from User Bank Accounts only. You agree and
                                understand that wire deposit settlement times are subject to bank holidays, the
                                internal processes and jurisdiction of your bank, and the internal processes of our
                                Banks and financial institutions. You further agree and understand that in certain
                                situations, wire deposit settlement times may be delayed in connection with
                                Downtime or disruptions to RealX Service Providers.
                                <br></br><br></br>
                                Digital Asset Deposits
                                <br></br><br></br>
                                Digital Asset deposits will typically be credited to your RealX Account after the
                                required number of network confirmations, as determined by us in our sole
                                discretion, have occurred on the blockchain for such Digital Assets. You agree and
                                understand that in certain situations, Digital Asset deposits may be delayed in
                                connection with downtime or congestion of or disruption to a Digital Asset
                                Network.
                                <br></br><br></br>
                                Digital Asset Withdrawals
                                <br></br><br></br>
                                Digital Assets withdrawals will typically be processed at the speed of a Digital
                                Asset Network. In certain situations, Digital Asset withdrawals may be delayed in
                                connection with downtime or the congestion or disruption of a Digital Asset
                                Network.
                                <br></br><br></br>
                                Digital Asset Networks
                                <br></br><br></br>
                                You understand that we do not own or control the underlying software protocols of
                                Digital Asset Networks which govern the operation of Digital Assets. In general,
                                the underlying protocols are open source and anyone can use, copy, modify, and
                                distribute them. You agree and understand that: (i) we are not responsible for the
                                operation of the underlying protocols, and (ii) we make no guarantees regarding
                                their security, functionality, or availability.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Prohibited Use and Conduct Violations</Typography>
                                <br></br><br></br>
                                You agree and understand that the Digital Assets is prohibited from being used in
                                the following manner:
                                <br></br><br></br>
                                including, but not limited to, use on any dark market, ransomware, mixing service
                                (i.e., a mixer or tumbler used to obscure the source of funds), or illegal activity that
                                would violate, or assist in violation of, any Applicable Laws and Regulations (as
                                defined in the ‘Applicable Laws and Regulations’ section), or which would involve
                                proceeds of any unlawful activity.
                                <br></br><br></br>
                                You further agree and understand that we have the right to immediately suspend,
                                investigate, and/or terminate your account pursuant to the terms of this User
                                Agreement for any such Conduct Violations or if we reasonably believe that
                                Conduct Violations have occurred or may occur, as determined in our sole
                                discretion. You agree and understand that your Digital Assets involved in a
                                Conduct Violation may be subject to freezing, forfeiture to, or seizure by a law
                                enforcement agency and/or subject to any similar limitation on its use, may be
                                wholly and permanently unrecoverable and unusable, and may, in appropriate
                                circumstances, be destroyed.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2"> Disclaimer</Typography>
                                <br></br><br></br>
                                By purchasing Frax, you agree and understand that we and/or any RealX Service
                                Provider are not responsible to any non-RealX Customer that obtains the Frax.
                                You also agree and understand that you will not make us and/or any RealX Service
                                Provider a party to any dispute involving a non-RealX Customer that uses the Frax.
                                You further agree and understand that we and/or any RealX Service Provider are
                                not responsible for your use (or misuse) of the Frax and that you will not make us
                                and/or any RealX Service Provider a party to any dispute involving your use (or
                                misuse) of the Frax. In any event, you hereby agree to indemnify us and/or any
                                RealX Service Provider for any and all costs, including legal costs, which are
                                <br></br><br></br>
                                reasonably incurred by us and/or any RealX Service Provider and related to any
                                such disputes.
                                <br></br><br></br>
                                THE VALUE OF THE UNDERLYING IMMOVABLE PROPERTY OF THE
                                DIGITAL ASSET CAN FLUCTUATE WHICH MAY RESULT IN A
                                REDUCTION OR AN INCREASE IN THE VALUE OF DIGITAL ASSETS. WE
                                HAVE NO CONTROL OVER THE VALUE OF THE UNDERLYING
                                IMMOVABLE PROPERTY OF THE DIGITAL ASSET AND
                                CONSEQUENTLY WE ALSO DO NOT HAVE ANY CONTROL OVER THE
                                VALUE OF THE DIGITAL ASSET. ACCORDINGLY, WE DISCLAIM ANY
                                AND ALL LIABILITIES ARISING FROM ANY REDUCTION IN THE
                                VALUES OF THE UNDERLYING IMMOVABLE PROPERTY AND THE
                                DIGITAL ASSET. WE MAKE NO GUARANTEES NOR GIVE ANY
                                ASSUSRANCES OR ANY SECURITY AND MAKE NO REPRESENTATION
                                CONCERNING THE VALUE AND/OR ANY CHANGE IN THESE VALUES
                                OF THE UNDELYING IMMOVABLE PROPERTY AND THE DIGITAL
                                ASSET.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Questions, Feedback, and Complaints</Typography>
                                <br></br><br></br>
                                If you have questions, feedback, and complaints please see the ‘Questions,
                                Feedback, and Complaints’ section of this User Agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Dispute Resolution</Typography>
                                <br></br><br></br>
                                If you are a RealX Customer and have a dispute related to the Frax, you agree and
                                understand that it shall be governed by the ‘Dispute Resolution’ section of this
                                User Agreement.
                                <br></br><br></br>
                                If you are not a RealX Customer, by obtaining or using the Frax, you agree that
                                any relationship between you and us (other than the terms and conditions of this
                                section of our User Agreement) or you and any RealX Service Provider and does
                                not subject us or any RealX Service Provider to any obligations whatsoever as they
                                relate to you. If you are not a RealX Customer, by obtaining or using the Frax, you
                                further agree and understand that you will not make us and/or any RealX Service
                                Provider a party to any dispute, including a dispute involving the Frax, and you
                                hereby agree to indemnify us and/or any RealX Service Provider for any and all
                                costs, including legal costs, which are reasonably incurred by us and/or any RealX
                                Service Provider and related to such disputes.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Privacy of Information</Typography>
                                <br></br><br></br>
                                You agree that the information contained in your RealX Account is only for you
                                and that you will not cause others to access or rely upon it (other than your tax
                                advisor or attorney, or as otherwise prescribed by law).
                                You acknowledge and agree to the collection, use, and disclosure of your personal
                                information in accordance with RealX’s Privacy policy, which is incorporated into
                                this User Agreement by reference.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Recording and Recordkeeping</Typography>
                                <br></br><br></br>
                                You agree and understand that for our mutual protection we may electronically
                                record any telephone conversation we have with you. You also agree and
                                understand that we maintain and retain records of all information, activities, and
                                communications relating to your RealX Account, and your use of the RealX
                                Platform.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Terms and Conditions of Investment</Typography>
                                <br></br><br></br>
                                Disposition of Frax
                                <br></br><br></br>
                                As a RealX Customer you will be entitled to purchase Frax which will entitle you
                                to an undivided co-ownership of the underlying immovable property, which is
                                associated with that set of Frax. Each Frax will be treated as an intangible
                                moveable asset and accordingly subject to the various dispositions that are
                                applicable to an intangible moveable asset as may be available under Applicable
                                Law.
                                These dispositions are as follows:
                                <br></br><br></br>
                                a. Sale, assignment, transfer, convey:
                                <br></br><br></br>
                                A sale of goods is where the goods are transferred from the seller to the buyer. But
                                where the transfer of the goods is to take place at a future time or subject to some
                                condition, then it is an agreement for sale and not an actual sale. Goods means
                                every kind of moveable property and will include NFTs.
                                <br></br><br></br>
                                b. Pledge:
                                <br></br><br></br>
                                A pledge is where there is a delivery of goods to a creditor for the purpose of
                                securing a debt due to him by the owner of the goods. In the case of a pledge, the
                                pledgee has a right to sell the goods in the event of a default to recover the debt.
                                <br></br><br></br>
                                c. Lien:
                                <br></br><br></br>
                                A lien is a right to retain goods to secure payment, but without the right to sell the
                                goods in case of a default.
                                <br></br><br></br>
                                d. Charge / Encumber:
                                <br></br><br></br>
                                Where property is made security for the payment of money.
                                <br></br><br></br>
                                e. Gift:
                                <br></br><br></br>
                                Is a transfer of certain existing moveable property made voluntarily and without
                                consideration by a donor to the donee and accepted by the donee on someone on
                                behalf of the donee.
                                <br></br><br></br>
                                f. Exchange:<br></br><br></br>
                                When two persons mutually transfer the ownership of one thing (NFT) for the
                                ownership of another thing, and none of the things exchanged is money. And
                                transfer in this case is sale.
                                <br></br><br></br>
                                g. Will:
                                <br></br><br></br>
                                Is also known as a testament, which is a declaration in writing making a voluntary
                                posthumous disposition of property.
                                <br></br><br></br>
                                h. Attachment by a Judicial Order:
                                <br></br><br></br>
                                If any Court or a Judicial authority passes an order attaching the property, which
                                then prevents the owner of the property from dealing with it or disposing it in any
                                manner.
                                <br></br><br></br>
                                i. Freeze instructions under any governmental / regulatory / law enforcement authority:
                                <br></br><br></br>
                                Where any law enforcement agency, such as the Enforcement Directorate, CBI,
                                Income Tax or the police issue instructions to seize the property.
                                <br></br><br></br>
                                j. Void transfers:
                                <br></br><br></br>
                                If there is any transfer of an NFT for an illegal purpose, then the transaction is void
                                and is not enforceable. For example, if a NFT holder were to transfer the NFT to
                                purchase drugs or achieve any illegal benefit or gratification, such a contract is
                                void.<br></br><br></br>
                                k. Voidable transfers:<br></br><br></br>
                                Any contract (from the list mentioned above), concerning the NFT which is
                                enforceable by law at the option of one party to the contract but not at the option of
                                the other party, is a voidable contract. For example: If a contract has been entered
                                into by undue influence, in which case, the person who is under the undue
                                influence of another, can choose not to honour it and he will not be liable; or with a
                                minor, where the minor being unable to contract, can choose to rescind the contract
                                and the minor will not be liable.<br></br><br></br>
                                l. Auction:<br></br><br></br>
                                Where the contract is an invitation to an offer. Here the seller invites the purchaser
                                to make an offer to purchase the NFT for a price, which if the seller accepts, then it
                                becomes a valid contract, and not otherwise. Hence the seller invites bids from
                                prospective purchasers to make offers to purchase the NFT for a price and the
                                contract for sale comes into existence only when the seller accepts a price offered
                                by the purchaser.
                                <br></br><br></br>
                                Any of the aforesaid dispositions relating to the Frax will be linked to
                                proportionate ownership of the underlying immovable property associated with
                                that Frax. This means that whatever monetary value/consideration that is attached
                                to the Frax notionally represents the proportionate value of the underlying
                                immovable property associated with such Frax.
                                <br></br><br></br>
                                Each Frax therefore represents an undivided ownership of a prescribed area (1 sq.
                                inch) of the immovable property and a collection of specific number of Frax will
                                represent the entire undivided ownership of that immovable property.
                                <br></br><br></br>
                                To enable the Transaction of each Frax on the RealX Platform/Marketplace, which
                                may involve multiple trades and dispositions with respect to each Frax resulting in
                                a change in the ownership of the Frax on multiple occasions, the legal (registered)
                                ownership of the underlying immovable property (associated with such Frax) will
                                always remain with the RealX Trust Company which shall hold such immovable
                                property in trust as a fiduciary for and on behalf of each Frax holder who or which
                                along with the other Frax holders shall together be the beneficial owners/holder of
                                such immovable property.
                                <br></br><br></br>
                                You as the owner of each Frax shall therefore be entitled to deal with the Frax in
                                any one or more of the abovementioned dispositions such as (a) Sale, assignment,
                                transfer and/or convey; (b) Pledge; (c) Lien; (d) Charge, Encumber; (e) Gift; (f)
                                exchange; (g) bequeath by Will; (h) Auction; and each Frax is subject to (i)
                                transfer by intestate succession, (ii) attachment pursuant to a judicial order; (iii)
                                Freeze instructions pursuant to any governmental /regulatory/law enforcement
                                authority; (iv) Void and voidable Transfers.
                                <br></br><br></br>
                                You will be able to deal with your Frax in any of the above manner by clicking and
                                accessing the options available in your Digital Asset Account and providing the
                                necessary details about your disposition.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Conversion of Ownership</Typography>
                                <br></br><br></br>
                                As the owner of the Frax, you are also entitled to the proportionate undivided
                                beneficial co-ownership of the underlying immovable property linked to such Frax
                                and accordingly you shall have the right at any time to opt for the direct legal
                                proportionate registered undivided co-ownership of the underlying immovable
                                property. This can be achieved in the following manner:
                                <br></br><br></br>
                                (a) If a majority in number of the Frax holders constituting at least 51% of the
                                area of the immovable property covered by such Frax holders decide by a vote to
                                convert the ownership of the immovable property from beneficial ownership into a
                                legal ownership for the purposes of sale of the underlying immovable property to
                                any third-party or any individual Frax holder (or jointly with other Frax holders),
                                then you along with other Frax holders will have the right to do so by casting your
                                individual votes in favour of such conversion and sale on the RealX Platform. If
                                the votes cast by the number of Frax holders which hold not less than 51% of the
                                area of the immoveable property, then RealX and the RealX Trust Company will
                                act on the decision of the majority Frax holders to enable the transfer of ownership
                                of the underlying immovable property from the Trust to any third-party purchaser
                                or any individual Frax holder (or jointly with other Frax holders)
                                <br></br><br></br>
                                (b) The decision to convert the nature of ownership of the underlying
                                immovable property shall always be based on a majority vote and even if you have
                                voted against it, you will be obliged to comply with and honor the decision of the
                                majority.
                                <br></br><br></br>
                                (c) Pursuant to such conversion all the Frax linked to such underlying
                                immovable property will be burned/extinguished and you will cease to hold any
                                Frax linked to such underlying immovable property and you along with all the
                                
                                other Frax holders (linked to such underlying immovable property) will become
                                the direct registered co-owners of such property ownership and the provisions of
                                this User Agreement to the extent they were applicable to you as the Frax holder of
                                such immovable property will no longer apply.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Disposition of Immovable Property</Typography>
                                <br></br><br></br>
                                As the owner of the Frax, you are also entitled to the proportionate undivided
                                beneficial co-ownership of the underlying immovable property linked to such Frax
                                and accordingly you shall have the right at any time to opt for leasing or licensing
                                the underlying immovable property. This can be achieved in the following
                                manner:
                                <br></br><br></br>
                                (a) If a majority in number of the Frax holders constituting at least 51% of the
                                area of the immovable property covered by such Frax holders decide by a vote to
                                allow the use, occupation and enjoyment of the underlying immovable property by
                                virtue of a lease or a licensing agreement, then you along with other Frax holders
                                will have the right to do so by casting your individual votes in favour of such
                                leasing or licensing arrangement on the RealX Platform. If the votes cast by the
                                number of Frax holders which hold not less than 51% of the area of the
                                immoveable property, are in favour of such leasing or licensing arrangement of the
                                underlying immovable property, then RealX and the RealX Trust Company will
                                act on the decision of the majority Frax holders to enable the leasing or licensing of
                                the underlying immovable property on behalf of and for the benefit of the Frax
                                holders. In that regard, You along with the other Frax holders authorize RealX and
                                the RealX Trust Company to collect and deposit the monthly rents / license fees
                                received by leasing/licensing of the underlying immovable property, and deposit
                                the same in the User Bank Account in proportion to the number of Frax held by
                                you, on a monthly or quarterly basis, as may be decided by RealX.
                                <br></br><br></br>
                                (b) The decision to lease or license the underlying immovable property shall
                                always be based on a majority vote and even if you have voted against it, you will
                                be obliged to comply with and honor the decision of the majority.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Your Payment Obligations</Typography>
                                <br></br><br></br>
                                As a Frax holder you shall be periodically liable to make your proportionate
                                contribution for the up-keep, maintenance, insurance, taxes, stamp-duty and any
                                other charges, fees that are required to be paid in connection with your
                                proportionate, undivided, beneficial co-ownership of the underlying immovable
                                property. RealX shall accordingly notify you of all such payments that are required
                                to be made by you from time to time and you must deposit such amounts our
                                escrow account when requested by us, which shall be used to discharge your
                                respective payment obligations. If you fail to make such payments into our escrow
                                account it will be considered to be a Conduct Violation and the consequences of
                                the Conduct Violation as mentioned in this User Agreement shall apply.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Powers and Functions of the RealX Trust Company</Typography>
                                <br></br><br></br>

                                You hereby agree, confirm, undertake, consent and acknowledge that RealX and
                                the RealX Trust Company will implement all such lawful acts, deeds and
                                instructions with respect to the disposition, maintenance and operation of the
                                underlying immovable properties as set out in this User Agreement in a fiduciary
                                capacity and they shall not be held liable or responsible in any manner for any
                                losses, cost, charges, damages, penalties and expenses that you may incur as a
                                result thereof. You also agree to pay any and all fees by whatever name called
                                charged by RealX and the RealX Trust Company for the services provided to you
                                under this trust agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">General Use</Typography>
                                <br></br><br></br>
                                Code of Conduct
                                <br></br><br></br>
                                We work hard to make RealX safe, secure, and compliant. To that end, we expect
                                that you treat our employees, including our Customer Support Team, as you would
                                like to be treated yourself: with respect. Any use of inappropriate or abusive
                                language towards any of our employees is strictly prohibited. Examples include,
                                but are not limited to, verbal threats, harassment, aggressive comments, or
                                behavior that is disrespectful. This type of behavior, we will terminate your
                                account.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Proprietary Rights and Limitations on Use</Typography>
                                <br></br><br></br>
                                RealX is our proprietary platform. RealX is protected by copyright and other
                                intellectual property laws. You agree and understand not to modify, copy,
                                reproduce, retransmit, distribute, sell, publish, broadcast, create derivative works
                                from, or store RealX source code or similar proprietary or confidential data or
                                other similar information provided via RealX, without our express prior written
                                consent. You may not use RealX for any unlawful purpose.
                                <br></br><br></br>
                                We hereby grant you a non-assignable and non-exclusive personal, worldwide,
                                royalty-free license to use and access the RealX platform and other informational
                                content through RealX in accordance with this User Agreement. All other uses are
                                prohibited. All rights in and to RealX platform, and not granted herein, are
                                reserved. RealX and the RealX logo (whether registered or unregistered) (the
                                “RealX Marks”) are proprietary marks licensed to RealX and protected by
                                applicable trademark laws. Nothing contained in this User Agreement should be
                                construed as granting any license or right to use any of the RealX Marks displayed
                                here without our express written consent. Any unauthorized use of the RealX
                                Marks is strictly prohibited.
                                <br></br><br></br>
                                Also, you may not use any of the RealX Marks in connection with the creation,
                                issuance, sale, offer for sale, trading, distribution, solicitation, marketing, or
                                promotion of any investment products (e.g., Digital Assets, fiat currency,
                                securities, commodities, investment or trading products, derivatives, structured
                                products, investment funds, investment portfolios, commodity pools, swaps,
                                securitizations or synthetic products, etc.), including where the price, return, and/or
                                performance of the investment product is based on, derived from, or related to
                                RealX or any portion thereof, without a separate written agreement with us.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Service Modifications</Typography>
                                <br></br><br></br>
                                You agree and understand that we may modify part of or all of RealX platform
                                without notice.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Downtime and Maintenance</Typography>
                                <br></br><br></br>
                                You agree and understand that part of or all of RealX may be periodically
                                unavailable during scheduled maintenance or unscheduled downtime.
                                You agree and understand that RealX is not liable or responsible to you for any
                                inconvenience or damage to you as a result of downtime. Following Downtime,
                                when services resume, you understand that market conditions and prices may differ
                                significantly from the market conditions and prices prior to such Downtime.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Risks</Typography>
                                <br></br><br></br>
                                Please note the following risks in accessing or using RealX:
                                <br></br><br></br>
                                There is a possibility of a risk of loss in owning and selling Digital Assets;
                                The price and liquidity of Digital Assets is subject to market fluctuations;
                                Legislative and regulatory changes or actions may adversely affect the use,
                                transfer, exchange, and value of Digital Assets;
                                <br></br><br></br>
                                Digital Assets in the Digital Asset Account of your RealX Account are presently
                                not subject to protection under any insurance policies;
                                <br></br>
                                Transactions in Digital Assets may be irreversible, and, accordingly, losses due to
                                fraudulent or accidental transactions may not be recoverable;
                                <br></br>
                                Some Digital Asset transactions shall be deemed to be made when recorded on a
                                public ledger, which is not necessarily the date or time that you initiated the
                                transaction;
                                <br></br>
                                You agree and understand that you are solely responsible for determining the
                                nature, potential value, suitability, and appropriateness of these risks for yourself,
                                and that RealX does not give advice or recommendations regarding Digital Assets,
                                including the suitability and appropriateness of, and investment strategies for,
                                Digital Assets. You agree and understand that you access and use RealX at your
                                own risk; however, this brief statement does not disclose all of the risks associated
                                with Digital Assets and using RealX. You should, therefore, carefully consider
                                whether such use is suitable for you in light of your circumstances and financial
                                resources. You should be aware that you may sustain a total loss of the funds in
                                your RealX Account, and that under certain market conditions, you may find it
                                difficult or impossible to liquidate a position.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Loss and Recovery</Typography>
                                <br></br><br></br>
                                a. Loss Liability:
                                <br></br><br></br>
                                You will not hold RealX or the Property Token Issuer liable for loss of your
                                Property Token due to your negligence or any cyber-attack or any act the
                                cause if which does not emanate from any action or lack of it by RealX or
                                the Property Token Issuer.
                                <br></br><br></br>
                                b. Right to admit Loss of Token Claims:
                                <br></br><br></br>
                                RealX shall in its sole discretion, entertain any request to recover lost
                                Property Tokens and follow its process to ascertain the veracity of such
                                claims and examine the authenticity of such claims through valid proofs of
                                identity and ownership from the current wallet holder and the claimant both,
                                if and as needed.<br></br><br></br>
                                c. Notice to prove ownership:<br></br><br></br>
                                Should there be any claim on a Property Token that is found to be prima-
                                facie bonafide, RealX may send a notice to the Property Token Wallet and
                                expect a response within a reasonable time period from the holder of the
                                wallet.<br></br><br></br>
                                d. Adjudication of claim based on proof:<br></br><br></br>
                                Further, to retain their claim on the Property Tokens, the wallet owner may
                                also be required to provide sufficient proofs of ownership, failing which,
                                RealX, itself or through any governing or administering body or officer as so
                                may be designated by it, will be at full and absolute freedom to grant the
                                ownership of FRAX in the Property Token in favour of the claimant and
                                take steps to assign the Property Token or issue new Property Token to the
                                claimant’s wallet.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Provisions related to receiving Rent/License Fees</Typography>
                                <br></br><br></br>
                                You will be entitled to receive rents/license fees, after suitable deductions for
                                maintenance, statutory dues, debt payments and various fees, and charges as they
                                may be applicable, if and when any immovable property represented by your
                                Property Token is given on lease or license. Provided however, if for any reasons
                                under Applicable Law you are not entitled/eligible to receive rents/license fees
                                then RealX shall not make any such payment to you but deal with the amount due
                                to you (as rent/license fee) in a manner to enable you to receive an equivalent
                                benefit commensurate with the value of such rent/license fee that is due to you.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Business Continuity</Typography>
                                <br></br><br></br>

                                In the event RealX goes into voluntary liquidation or winding up, the operations of
                                RealX will be taken over by the RealX Trust Company, which will operate as the
                                custodian of all the immovable properties of which it is the legal owner for the
                                benefit of all the NFT Holders who are the beneficial holders of the immovable
                                properties. The RealX Trust Company will identify another person/entity/company
                                to assign all the rights and obligations under this User Agreement and the NFT
                                Terms and Conditions to such person/entity/company which will assume all the
                                rights and obligations under this User Agreement and the NFT Terms and
                                Conditions. In the event a petition is filed for the appointment of any receiver of its
                                assets or for bankruptcy, any administrator who is appointed of RealX’s assets,
                                then the RealX Trust Company will work together with such administrator to
                                identify any other person/entity/company which will assume the rights and
                                obligations of this User Agreement and the NFT Terms and Conditions.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Third-Party Websites</Typography>
                                <br></br><br></br>

                                RealX may link to other websites operated by or with content provided by third
                                parties, and such other websites may link to our website. You agree and understand
                                that RealX has no control over any such other websites or their content and will
                                have no liability arising out of or related to such websites or their content. The
                                existence of any such links does not constitute an endorsement of such websites,
                                their content, or their operators. RealX is providing these links to you only as a
                                convenience.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Third-Party Service Providers</Typography>
                                <br></br><br></br>
                                You agree and understand that we may use third parties to gather, review, and
                                transmit your data and activity from your Banks to us. By using RealX, you agree
                                to grant third-party providers that we may engage, the right, power, and authority
                                to access and transmit your data, activity, and personal and financial information
                                from your Banks to us in accordance with and pursuant to their terms and
                                conditions.
                                <br></br><br></br>
                                You agree and understand that we may use third parties to gather, review, and
                                submit or facilitate submitting your data and activity from us to regulatory
                                authorities on our behalf. By using RealX, you agree to grant third-party providers
                                that we may engage, the right, power, and authority to access and submit your data,
                                activity, and personal and financial information to regulatory authorities on our
                                behalf and in accordance with and pursuant to their terms and conditions, privacy
                                policy, and/or other policies.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Third-Party Information Accuracy and Usage</Typography>
                                <br></br><br></br>
                                An information provider is any company or person who directly or indirectly
                                provides us with information (“Information Provider”). Such information could
                                include, but is not limited to, the value of the underlying immoveable property of
                                the Frax, overall market conditions affecting real estate prices in India, quotations
                                from other exchanges, markets, dealers, and/or miners of Digital Assets. The third-
                                party information we may provide through RealX has been obtained from
                                Information Providers and sources we believe are reliable; however, we cannot
                                guarantee that this information is accurate, complete, timely, or in the correct
                                order. The information belongs to the Information Providers. You may use this
                                information only for your own benefit. You may not reproduce, sell, distribute,
                                circulate, create derivative works from, store, commercially exploit in any way, or
                                provide it to any other person or entity without our written consent or the consent
                                of the Information Provider, if required.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Tax Advice and Information</Typography>
                                <br></br><br></br>
                                You agree and understand that RealX does not provide legal, tax, or investment
                                advice, and to the extent you deem necessary, you will consult with qualified
                                professionals prior to using RealX.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Non-Solicitation</Typography>
                                <br></br><br></br>

                                You agree and understand that all Commitments received through and by RealX
                                are considered unsolicited, which means that you have not received any investment
                                advice from us or any RealX Service Provider in connection with any commitment
                                you place, including those you place via our API, and that we do not conduct a
                                suitability review of any commitment you place, including those you place via our
                                API. You also agree and understand that you have not and do not expect to receive
                                any investment advice from us nor any of our affiliates in connection with your
                                transaction. In addition, you agree and understand that your review of our blog
                                and/or FAQ does not constitute any solicitation or investment advice.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Participation Advice</Typography>
                                <br></br><br></br>
                                You agree and understand that all participation decisions are made solely by you.
                                Notwithstanding anything in this User Agreement, you agree and understand that
                                we accept no responsibility whatsoever for and shall in no circumstances be liable
                                to you in connection with, your decisions. You agree and understand that under no
                                circumstances will the operation of RealX and your use of RealX be deemed to
                                create a relationship that includes the provision of or tendering of investment
                                advice.
                                <br></br><br></br>
                                You agree and understand that by using RealX and placing your commitments, you
                                have sufficient knowledge to place such transactions. You are responsible for
                                making sure that any commitments placed by you are accurate and intentional. We
                                may, in some cases, and at our sole discretion, require secondary electronic, verbal,
                                written or other confirmation before acting if your account activity is outside of its
                                normal range of activities.
                                <br></br><br></br>
                                You agree and understand that the information and services provided by RealX are
                                not provided to, and may not be used by, any individual or institution in any
                                jurisdiction where the provision or use thereof would be contrary to any Applicable
                                Laws and Regulations, the rules or regulations of any governmental authority or
                                regulatory organization, or where RealX is not authorized to provide such
                                information or services. RealX may not be available in all jurisdictions.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Accessing RealX</Typography>
                                <br></br><br></br>
                                You agree and understand that you are not allowed to enter any restricted area of
                                any computer or network of RealX or any RealX Service Provider under any
                                circumstances or perform any functions that are not authorized by this User
                                Agreement. You should never access RealX from an unsecured or public computer
                                and/or network.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Prohibited Use</Typography>
                                <br></br><br></br>
                                Sanctions and Export Controls
                                <br></br><br></br>
                                The services we provide are subject to the laws of the Republic of India and its
                                regulations relating to investment in real estate by foreign nationals. By purchasing
                                Digital Assets on RealX, you represent and Warrant that your acquisition and use
                                of any such digital Asset complies with those requirements. Without limiting the

                                foregoing, you may not buy Digital Assets on RealX or use any of our services that
                                we provide if: (i) you are a resident of Pakistan, Bangladesh or Nepal or any other
                                country, which the Indian government prescribes, whose resident cannot acquire
                                any immoveable property in India (‘Prohibited Jurisdiction’) or (ii) you intend to
                                sell any Digital Assets bought on RealX or offer any services that we provide on
                                RealX in any Prohibited Jurisdiction.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">General Provisions</Typography>
                                <br></br><br></br>
                                You agree and understand to be legally bound by the terms and conditions set forth
                                in this User Agreement and that this User Agreement governs your use of RealX
                                and the services we provide. By clicking “I AGREE,” during the account opening
                                process, you agree and understand to be legally bound by the terms and conditions
                                of this User Agreement and
                                agree and understand that you have received the disclosures set forth herein. If you
                                do not agree to be legally bound by the terms and conditions of this User
                                Agreement, do not click “I AGREE” and please do not visit, access, or use RealX
                                in any capacity or manner. Whether or not you click “I AGREE,” if you sign up for
                                an account and use RealX in any capacity or manner, you agree, by virtue of any
                                such action, to be legally bound by the terms and conditions of this User
                                Agreement (including any changes or amendments) in their entirety.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Disclaimer of Warranties</Typography>
                                <br></br><br></br>
                                For the avoidance of doubt, neither we nor any RealX Service Provider are giving
                                investment advice, tax advice, legal advice, or other professional advice by
                                allowing you to use RealX, services we or any RealX Service Provider provide,
                                including, but not limited to, the ability to buy, sell, or store Digital Assets. In
                                addition, neither we nor any RealX Service Provider recommend, or endorse that
                                you buy or sell Digital Assets, or any other asset, or that you make any investment
                                or enter into any Transaction. Before engaging in any transaction or investment
                                activity, you should always consult a qualified professional.
                                <br></br><br></br>
                                THE SERVICES WE AND REALX SERVICE PROVIDERS PROVIDE ARE
                                PROVIDED TO YOU ON A STRICTLY “AS IS,” “WHERE IS,” AND “WHERE
                                AVAILABLE” BASIS. NEITHER WE NOR ANY REALX SERVICE
                                PROVIDER REPRESENT OR WARRANT TO THE ACCURACY,
                                COMPLETENESS, CURRENTNESS, NON-INFRINGEMENT,
                                MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF
                                REALX OR THE INFORMATION CONTAINED THEREIN OR SERVICES
                                CONTAINED THEREON. REALX DOES NOT MAKE ANY
                                REPRESENTATIONS OR WARRANTIES THAT ACCESS TO THE SITE OR
                                USE OF THE SERVICES WILL BE CONTINUOUS, UNINTERRUPTED, OR
                                ERROR FREE.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Disclaimer of Liability</Typography>
                                <br></br><br></br>
                                EXCEPT TO THE EXTENT REQUIRED BY LAW, NEITHER WE NOR ANY
                                REALX SERVICE PROVIDER SHALL BE LIABLE TO YOU, WHETHER IN

                                CONTRACT OR TORT, FOR ANY PUNITIVE, SPECIAL, INDIRECT,
                                CONSEQUENTIAL, INCIDENTAL, OR SIMILAR DAMAGES, INCLUDING
                                LOST TRADING OR OTHER PROFITS, DIMINUTION IN ASSET VALUE,
                                OR LOST BUSINESS OPPORTUNITIES EVEN IF WE AND/OR ANY REALX
                                SERVICE PROVIDER HAVE BEEN ADVISED OF THE POSSIBILITY
                                THEREOF IN CONNECTION WITH THIS USER AGREEMENT, YOUR USE
                                OR ATTEMPTED USE OF REALX, OR ANY OF THE INFORMATION,
                                SERVICES OR TRANSACTIONS CONTEMPLATED BY THIS USER
                                AGREEMENT. REALX’S TOTAL LIABILITY FOR BREACH OF THIS
                                AGREEMENT SHALL BE LIMITED TO RS. 1,00,000/-.
                                YOU AGREE AND UNDERSTAND THAT NEITHER WE NOR ANY REALX
                                SERVICE PROVIDER SHALL BE LIABLE FOR DELAYS OR
                                INTERRUPTIONS IN SERVICE CAUSED BY
                                INTERRUPTION SHORTER THAN ONE WEEK, OR DELAYS OR
                                INTERRUPTIONS IN SERVICE BEYOND THE CONTROL OF REALX OR
                                ITS SERVICE PROVIDERS.
                                THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED TO
                                ANY DAMAGE OR INTERRUPTIONS CAUSED BY ANY COMPUTER
                                VIRUSES, SPYWARE, SCAMWARE, TROJAN HORSES, WORMS, OR
                                OTHER MALWARE THAT MAY AFFECT YOUR COMPUTER OR OTHER
                                EQUIPMENT, OR ANY PHISHING, SPOOFING, DOMAIN
                                TYPOSQUATTING, OR OTHER ATTACKS, FAILURE OF MECHANICAL
                                OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES,
                                TELEPHONE OR OTHER INTERCONNECT PROBLEMS E.G., YOU
                                CANNOT ACCESS YOUR INTERNET SERVICE PROVIDER ,
                                UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR
                                OTHER LABOR PROBLEMS, OR ANY FORCE MAJEURE. NEITHER WE
                                NOR ANY REALX SERVICE PROVIDER GUARANTEE CONTINUOUS,
                                UNINTERRUPTED, OR SECURE ACCESS TO REALX.
                                IF THIS DISCLAIMER OF LIABILITY SECTION IS DEEMED TO CONFLICT
                                WITH ANY OTHER SECTION OF THIS USER AGREEMENT, THIS
                                DISCLAIMER OF LIABILITY SECTION SUPERSEDES THE OTHER
                                SECTION.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Reasonable Care</Typography>
                                <br></br><br></br>
                                You agree that we cannot be held responsible for any failure or delay to act by any
                                RealX Service Provider, including our Banks, or any other participant that is
                                within the time limits permitted by this User Agreement or prescribed by law, or
                                that is caused by your negligence.
                                <br></br><br></br>
                                You further agree that neither we nor any RealX Service Provider can be held
                                responsible for any “System Failure” (defined as a failure of any computer
                                hardware or software used by RealX, a RealX Service Provider, or any
                                telecommunications lines or devices used by RealX or a RealX Service Provider),
                                or downtime, which prevents us from fulfilling our obligations under this User

                                Agreement, provided that we or the relevant RealX Service Provider (as
                                applicable) used commercially reasonable efforts to prevent or limit such System
                                Failures, or downtime. You also agree that neither we nor any RealX Service
                                Provider can be held responsible for any other circumstances beyond our or such
                                RealX Service Providers’ (as the case may be) reasonable control.
                                <br></br><br></br>
                                In addition, you agree and understand that any act or omission made by us or any
                                RealX Service Provider in reliance upon or in accordance with any provision of
                                Applicable Law,
                                Shall constitute reasonable care and be commercially reasonable.
                                We use commercially reasonable efforts to provide you with a reliable and secure
                                platform. From time to time, interruptions, errors or other deficiencies in service
                                may occur due to a variety of factors, some of which are outside of our control.
                                These factors can contribute to delays, errors in service, or system outages.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Force Majeure</Typography>
                                <br></br><br></br>
                                You agree and understand that in no event shall we or any RealX Service Provider
                                be liable for any delays, failure in performance or interruption of service which
                                result directly or indirectly from any cause or condition, whether or not
                                foreseeable, beyond our or any RealX Service Providers’ reasonable control,
                                including, but not limited to, any act of God, nuclear or natural disaster, epidemic,
                                pandemic, lockdown, action or inaction of civil or military authorities, act of war,
                                terrorism, sabotage, civil disturbance, strike or other labor dispute, accident, state
                                of emergency or interruption, loss, or malfunction of equipment or utility,
                                communications, computer (hardware or software), Internet or network provider
                                services.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Indemnities</Typography>
                                <br></br><br></br>
                                In no event shall we or any RealX Service Provider be liable for any act, omission,
                                error of judgment, or loss suffered by you in connection with this User Agreement
                                or your use or attempted use of RealX Platform, or services of a RealX Service
                                Provider or Frax. You agree to indemnify and hold us and all RealX Service
                                Providers harmless from or against any or all liabilities, obligations, losses,
                                damages, penalties, actions, judgments, suits, costs, expenses (including reasonable
                                attorneys’ fees), claims, disbursements or actions of any kind and injury (including
                                death) arising out of or relating to your use of RealX, or our and any RealX Service
                                Providers’ performance or non-performance of duties to you.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Entire Agreement</Typography>
                                <br></br><br></br>
                                This User Agreement, our Privacy Policy, and our, incorporated by reference
                                herein, comprise the entire understanding and agreement entered into by and
                                between you and RealX as to the subject matter hereof, and supersede any and all
                                prior discussions, agreements, and understandings of any kind (including without
                                limitation any prior versions of this User Agreement), as well as every nature
                                between and among you and us.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Assignment</Typography>
                                <br></br><br></br>
                                This User Agreement, or your rights and obligations hereunder, may not be
                                transferred by you, but may be assigned by us without restriction. Any attempted
                                transfer or assignment by you in violation hereof shall be null and void. This User
                                Agreement shall be binding and inure to the benefit of the parties hereto, our
                                successors, and permitted assigns.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Relationship of the Parties</Typography>
                                <br></br><br></br>
                                You agree and understand that nothing in this User Agreement shall be deemed to
                                constitute, create, imply, give effect to, or otherwise recognize a partnership,
                                employment, joint venture, or formal business entity of any kind; and the rights
                                and obligations of the parties shall be limited to those expressly set forth herein.
                                Except for the indemnity provisions herein, nothing expressed in, mentioned in, or
                                implied from this User Agreement is intended or shall be construed to give any
                                person other than the parties hereto any legal or equitable right, remedy, or claim
                                under or in respect to this User Agreement to enforce any of its terms which might
                                otherwise be interpreted to confer such rights to such persons, and this User
                                Agreement and all representations, warranties, covenants, conditions and
                                provisions hereof are intended to be and are for the exclusive benefit of you and us.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Injunctive Relief</Typography>
                                <br></br><br></br>
                                You agree and understand that your obligations and the obligations of each user set
                                forth in this User Agreement are necessary and reasonable in order to protect us
                                and our business. You expressly agree that due to the unique nature of our
                                business, monetary damages would violation or threatened violation shall cause
                                irreparable injury to the us and that, in addition to any other remedies that may be
                                available, in law, in equity or otherwise, we shall be entitled to obtain injunctive
                                relief against any threatened breach of this User Agreement or your continuation of
                                any such breach, without the necessity of proving actual damages.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Severability</Typography>
                                <br></br><br></br>
                                You agree and understand that if any provision of this User Agreement, or
                                application thereof, shall be determined to be invalid or unenforceable under any
                                rule, law, or regulation or by any governmental agency, local, state, or federal,
                                such provision will be changed and interpreted to accomplish the objectives of the
                                provision to the greatest extent possible under any applicable law. You further
                                agree and understand that the validity of or enforceability of any other provision
                                (or of such provision, to the extent its application is not invalid or unenforceable)
                                of this User Agreement shall not be affected.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Change of Control</Typography>
                                <br></br><br></br>
                                In the event that we are acquired by or merged with a third-party entity, we reserve
                                the right, in any of these circumstances, to transfer or assign the information we
                                have collected from you as part of such merger, acquisition, sale, or other change
                                of control.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Survival</Typography>
                                <br></br><br></br>
                                You agree and understand that all provisions of this User Agreement, which by
                                their nature extend beyond the termination or expiration of this User Agreement,
                                including, but not limited to, sections pertaining to suspension, investigations,
                                remedies for breach, termination, debts owed, right to offset, unclaimed funds,
                                general use of RealX, disputes with us, and general provisions, shall survive the
                                termination or expiration of this User Agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Miscellaneous</Typography>
                                <br></br><br></br>
                                Section headings in this User Agreement are for convenience only, and do not
                                govern the meaning or interpretation of any provision of this User Agreement.
                                Unless the express context otherwise requires: (i) the words “hereof,” “herein,”
                                “hereunder” and words of similar import, when used in this User Agreement, shall
                                refer to this User Agreement as a whole and not to any particular provision of this
                                User Agreement; (ii) the terms defined in the singular have a comparable meaning
                                when used in the plural and vice versa; (iii) wherever the word “include,”
                                “includes” or “including” is used in this User Agreement, it shall be deemed to be
                                followed by the words “without limitation”; (iv) the word “extent” in the phrase
                                “to the extent” shall mean the degree to which a subject or other thing extends and
                                such phrase shall not mean simply “if”; and (v) the word “or” shall not be
                                interpreted to be exclusive.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2"> Written Notice</Typography>
                                <br></br><br></br>
                                If we send an email to the email address on record for your User Account, you
                                agree and understand that this constitutes written notice (“Written Notice”) from us
                                to you. If you visit our Help Center, this constitutes Written Notice from you to us.
                                For all notices made by email, the date of receipt is considered to be the date of
                                transmission.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2"> Non-Waiver of Rights</Typography>
                                <br></br><br></br>
                                This User Agreement shall not be construed to waive rights that cannot be waived
                                under Applicable Laws and Regulations. In addition, our failure to insist upon your
                                strict compliance with any term or provision of this User Agreement shall not be
                                construed as a waiver for you to not comply with such term or provision.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Governing Law</Typography>
                                <br></br><br></br>
                                This User Agreement, your use of RealX, your rights and obligations, and all
                                actions contemplated by, arising out of or related to this User Agreement shall be
                                governed by the laws of the Republic of India and the Courts of Pune will have
                                exclusive jurisdiction in any matter arising under this User Agreement.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Dispute Resolution</Typography>
                                <br></br><br></br>
                                You and RealX agree and understand that any controversy, claim, or dispute
                                arising out of or relating to this User Agreement or your relationship with RealX
                                shall be settled solely and exclusively by binding arbitration to be held in Pune.
                                The arbitration shall be conducted under the provisions of the Indian Arbitration &amp;
                                Conciliation Act, 1999 and shall be conducted by a single arbitrator who shall be
                                mutually appointed by You and RealX. The language of the arbitration shall be
                                English. Each side agrees to bear its own attorney’s fees, costs, and expenses.
                                You and RealX agree to abide by all decisions and awards rendered in such
                                proceedings and you and RealX agree that such decisions and awards rendered by
                                the arbitrator shall be final and conclusive.
                                <br></br><br></br>
                                <Typography variant="aboutHeading2">Grievance Redressal Mechanism</Typography>
                                <br></br><br></br>
                                Customers may contact RealX Customer Support with any account inquiries at our
                                Help Center; by sending an email to RealX.
                                Detailed description of the specific complaint giving the dates involved and any
                                other relevant information.
                                Copies (not originals) of all pertinent documents, such as contracts, letters,
                                receipts, and statements.
                                Description of the resolution you desire or would find acceptable.
                                fYour signature and the date signed.
                                <br></br><br></br>
                                Submissions to RealX
                                <br></br><br></br>
                                We cannot agree to obligations of confidentiality or nondisclosure with regard to
                                any unsolicited information you submit to us, regardless of the method or medium
                                chosen. By submitting unsolicited information or materials to us or any RealX
                                Service Provider, you or anyone acting on your behalf, agree that any such
                                information or materials will not be considered confidential or proprietary.
                                We do not provide any facility for sending or receiving private or confidential
                                electronic communications. You should not use RealX to transmit any
                                communication for which you intend only you and the intended recipient(s) to
                                read. Notice is hereby given that all messages and other content entered using
                                RealX can and may be read by us, regardless of whether we are the intended
                                recipients of such messages. Nevertheless, access to messages and other content
                                will be accessible only by employees and RealX Service Providers that reasonably
                                need such access.
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
}
export default Terms