import ThemeProvider from './theme';
import { Provider } from "react-redux";
import Router from './routes';
import store from './redux/store';
import { SnackbarProvider } from "notistack";
import CustomSnackbar from './components/messageBox/customSnackbar';
import ScrollToTop from './components/scrollToTop';
import { server } from './utils/server';
import { authHeader } from './helpers/authHeader';
import CacheBuster from './CacheBuster';
import VesrionUpdate from './components/modals/versionUpdate';
import { useEffect } from "react";
import './theme/global.css'
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import initializeAnalytics from '../src/analytics';
initializeAnalytics();
  
const interCeptor = () =>{

  server.interceptors.request.use( (config)=> {
    const token = authHeader();
    config.headers.Authorization =  token;
    return config;
  }, null, { synchronous: true });
}

function App() {

  // const location = useLocation();
  const pathname = window.location.pathname

   // Determine if the current path requires the Google Translate Element and SnackbarProvider
  //  const showGoogleTranslateAndSnackbar = location.pathname !=='/ayodhya' && location.pathname !=='/ayodhya/fb'&&location.pathname !=='/ayodhya/sayla'

    const googleTranslateElementInit = () => {
      // (window.innerWidth <= 768)? "hi,ta,te,ur,ml,kn,bn,gu,punjabi,or,as,ne,mr,kok,sd,ks,en,es,fr,ar" : 
      let languages = "hi,te,ur,kn,bn,gu,punjabi,or,as,ne,mr,kok,sd,ks,en,es,fr,ar,ta"; 
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
        includedLanguages: languages,
        defaultLanguage: "en",
      },
      "google_translate_element",
     
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  
    // Add a delay to wait for the widget to load, and then apply the styles
    setTimeout(() => {

      // Apply your custom styles here
    }, 1000); // Adjust the delay time as needed
  }, []);

  
  interCeptor()

  return (
    <ThemeProvider>
      <CacheBuster>
      {({loading, isLatestVersion, refreshCacheAndReload})=>{
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
            return(
              <VesrionUpdate open={true} />
            )
          }
          return(
            <Provider store={store}>
                   
             
                <>
              <div id="google_translate_element"></div>
            <SnackbarProvider
              anchorOrigin={{
                horizontal:"center",
                vertical:"bottom"
              }}
              autoHideDuration={1500}
            Components={{
              success1:CustomSnackbar
            }} >
            
            <ScrollToTop />
              <Router />
            </SnackbarProvider>
            </>
      
          </Provider>
          )
        }}
      </CacheBuster>
    </ThemeProvider>
  );
}

export default App;
