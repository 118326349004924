import store from "../redux/store";
import { server } from "../utils/server";



const getMyWatchlist = () =>{
    const user = store.getState().user.data;
    const data = {
        investorId:user._id
    }
    return server.post("/investor/features/getWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const toggleWatchlist = (id) =>{
    const user = store.getState().user.data;
    const data = {
        listingId:id,
        investorId:user._id
    }
    return server.post("/investor/features/toggleWatchlist",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const addInvite = (data) =>{
    return server.post("/invite/addInvite",data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const addCpDetails = (data) =>{
    return server.post("/cp/add", data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const addDevelopersDetails = (data) =>{
    return server.post("/developerAdmin/auth/register", data)
    .then((res)=>{
        return res.data;
    })
    .catch((err)=>{
        console.log(err)
    });
}

const createSelectUser = (data) => {
    const { amount, membershiptype, emailId, mobileNumber,  username} = data;

    const queryParams = new URLSearchParams({
      emailId,
      membershiptype,
      mobileNumber,
      username,
      amount
    });
  
    const url = `/investor/auth/addnewselectinvestor`;
  
    return server
      .post(url, data)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

const userServices = {
    getMyWatchlist,
    toggleWatchlist,
    addInvite,
    addCpDetails,
    addDevelopersDetails,
    createSelectUser
}

export default userServices;