import { Box, Stack, Typography,Button } from "@mui/material";
import React from "react";
import MiscLayout from "./miscLayout";
import { useNavigate } from "react-router-dom";
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import CustomRoutes from "../../components/customRoutes";
import { useState,useRef, useEffect } from "react";
import DevelopersRegistraions from "../../components/modals/developersRegistrations";
import { Helmet } from 'react-helmet';
import { LearnMore } from "../../components/learnMore";

export default function WhyRealX(){
    
    window.heap.track('Why RealX', {
        buttonName: 'Why RealX',
        category: 'Page view',
      });

    const tabs = [
        {
            name:"Advantage RealX",
            component:<WhyRealXSub />
        },
        {
            name:"For Investors",
            component:<ForInvestors />
        },
        {
            name:"A note on Regulations",
            component:<Regulations />
        },
        {
            name:"For Developers",
            component:<ForDevelopers />
        }
    ]
    return(
        <MiscLayout tabs={tabs} title={"Why RealX"} />
    )
}

const WhyRealXSub = () =>{
    return(
        <Box>
            {/* <AdvantageOfRealX /> */}
            {/* <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} /> */}
            <LandingPage />
        </Box>
    )
}

const ForInvestors = () =>{
    return(
        <Stack spacing={{md:4,xs:2.5}}>
            <Stack spacing={1.5} mb={{md:0,xs:2.5}}>
                <Box 
                    sx={{
                        borderRadius:"10px",
                        bgcolor:"primary.light",
                        px:{md:4,xs:2.5},
                        py:{md:1,xs:2},
                        width:"100%",
                        textAlign:{md:"center"}
                    }}
                >
                    <Typography variant="miscItalic">90% of all millionaires made fortune with real estate investment.<br /> And this did not stop even during the pandemic</Typography>
                </Box>
                <Typography variant="body3">Real estate continues to be the first choice for investment with 57% respondents favoring it over other assets like equities & mutual funds, FDs and gold. Interestingly, the stock market – despite being volatile in nature – is the second preferred choice with 24% votes.</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">RealX was built singularly with investors in mind</Typography>
                <Typography variant="body3">RealX was built singularly with investors in mind
                World's first and only platform to offer direct beneficial rights as property tokens.It follows a next generation legal and commercial model for Fractional Ownership that brings in unique advantages for the new generation property investors. </Typography>
            </Stack>
            <Box sx={{width:"100%",height:1.2,bgcolor:"divider"}} />
            <AdvantageOfRealX />
        </Stack>
    )
}

const AdvantageOfRealX = () =>{

    const data = [
        {
            title:"Direct Co-Ownership",
            desc:"Direct registered co-ownership in the property – What you see is what you get",
            img:"1.png"
        },
        {
            title:"Immutable Records",
            desc:"Co-ownership records maintained on an immutable BlockChain Registry (PropChain)",
            img:"2.png"
        },
        {
            title:"Tax Efficient",
            desc:"RealX is at least 25% more Tax Efficient than its peers",
            img:"3.png"
        },
        {
            title:"Zero Compliance",
            desc:"No Compliance burden as we don't hold the asset in an SPV",
            img:"4.png"
        },
        {
            title:"Full Transparency",
            desc:"We are fully transparent in our deals to the extent we know",
            img:"5.png"
        },
        {
            title:"Completely Digital process",
            desc:"Hassle free entry, benefits management (passive) and exit",
            img:"6.png"
        },
        {
            title:"Multiple asset class",
            desc:"Carefully curated properties across Residential, Commercial, Warehousing, Co-living and others",
            img:"5.png"
        },
        {
            title:"Professional Diligence",
            desc:"Property Due Diligence and Commercial Reports from established empanelled 3rd party organisations",
            img:"8.png"
        },
    ]

    return(
        <Stack spacing={4} sx={{mb:{md:5,xs:2.8}}}>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Advantage of RealX</Typography>
                <Typography variant="body3">Besides being one of the most innovative FinTech company in its space globally, RealX has <span style={{fontWeight:600,fontStyle:"italic"}}>investor first</span> approach at its core</Typography>            
            </Stack>
            {
                data.map((e)=>{
                    return(
                        <Stack direction={"row"} spacing={{md:1.5,xs:1}}>
                            <Box sx={{
                                minWidth:60,
                                minHeight:60,
                                maxWidth:60,
                                maxHeight:60,
                                bgcolor:"primary.light",
                                borderRadius:"100%",
                                padding:{md:1.5,xs:2}
                            }}>
                                <Box component={"img"} src={`/assets/images/whyrealx/${e.img}`} />
                            </Box>
                            <Stack spacing={"6px"}>
                                <Typography variant="aboutHeading2">{e.title}</Typography>
                                <Typography variant="body3">{e.desc}</Typography>
                            </Stack>
                        </Stack>
                    )
                })
            }
        </Stack>
    )
}

const Regulations = () =>{
    const navigate = useNavigate();
    return(
        <Stack spacing={{md:4,xs:2.5}}>
            <Stack spacing={1.5}>
                {/* <Typography variant="aboutHeading2">RealX was built singularly with investors in mind</Typography> */}
                <Typography variant="body3">
                We have taken care in building RealX within the ambit of the extant laws available to us.  Being built on base established laws of today, the RealX structure is therefore compliant with all applicable laws.</Typography>
                <Typography variant="body3">
                    The legal construct of RealX is unique to it and has taken us a couple of years of work to get there. At the moment there isn't any regulatory framework that we can be licensed under. However, we would be keen to get under active regulatory supervision once a framework is created for us.
                </Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Why don't we fall under regulation?</Typography>
                <Typography variant="body3">
                    Simply put, RealX does not fall under any extant regulations (until new ones are made for us) because of three reasons:
                    </Typography>
                <Typography variant="body3">
                    1. You and only you decide where to invest your money. 
                    There is no blind investing or pooling as a fund or trusting any fund manager here. 
                    Your money, your choice, your decision and its your property.
                </Typography>
                <Typography variant="body3">
                2. The RealX structure creates a direct co-ownership on the property. So the ownership (or rights for it), rest solely in your hands. Everything else is a support function - essentially helping you with property administration.
                </Typography>
                <Typography variant="body3">
                3. All key decisions regarding the ownership and tenancy are taken by you. Just like shareholders in a company, the co-owners of the property are free to keep their stake or sell it at their will as well as vote to decide on all important matters.
                </Typography>
                <Typography variant="body3">
                    Even without direct regulations, we think we can do a whole lot to create a better environment for our investors to invest with confidence at RealX.
                    <br/><br />
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                    Some of the best features of how we do things at RealX are
                    </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>1. Separation of Ownership representation and platform functions : </span> 
                    RealX created a separate Registered Trust to carry on the fiduciary functions related to property ownership. The trustee (generally a corporate trustee), will need to ensure they always act as per the Trust clauses and not on what RealX says. This creates a separation of functions, commitments, control  and interest.
                </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>2. Maker-Checker in all key functions : </span> 
                    No major action can be unilaterally taken by any entity, be the Trustee, Asset Manager or even RealX. Most of such functions require one entity to initiate an action and another to approve it even before these are placed before investors. 
                    </Typography>
                <Typography variant="body3">
                    <span style={{fontWeight:600}}>3. Each property is designed as a DAO : </span> 
                    All investors in a property become part of a DAO (Distributed Autonomous Organisation) wherein they together can take all key decisions regarding their collective ownership of the property as a whole. Having said that all investors hold their co-ownerships (or co-ownership rights) as freehold assets and they can individually decide to hold or sell them.
                    </Typography>
                    <Typography variant="body3">
                        RealX and its ecosystem, have simply created a standard  framework (of rights, responsibilities and platform rules), for all of you to come together and invest. This helps you invest with confidence knowing very well that the standard framework ensures you know what to expect from other investors who may co-invest with you as well as sets the expected behaviour from everyone else involved in the process. 
                    </Typography>
                    <Typography variant="body3">
                    And we do our best in terms of curation of such properties, oversight of all functional players and intermediaries as well as create efficient mechanisms for fund transfer etc. </Typography>
            </Stack>
            <Stack  spacing={1.5}>
                <Typography variant="aboutHeading2">Will we be regulated?</Typography>
                <Typography variant="body3">
                Regulation is a good thing. It creates more confidence for investors and protects the interests of investors as well as  every other participant in the market. 
                </Typography>
                <Typography variant="body3">
                We are at an early stage of evolution of this product and we believe prudent regulations will follow once the market gains traction enough. We would only be too happy to work with the regulators and governments to support proper regulations for the industry.
                </Typography>
            </Stack>
           <LearnMore link='/faq' title='see what the government has been saying on Blockchain uses' option={{tab:1}} />
        </Stack>
    )
}

const LandingPage = () =>{
    return(
        <Box
        sx={{
            borderRadius:"10px",
            width:"fit-content",
            
        }}
        >
            <Helmet>
                <meta name="description" content="RealX unlocks premium real estate investments for all. Democratizing access, we offer quality opportunities in the finest properties. Embrace digital investment now" />
                <meta name="keywords" content="RealEstateInvestments, DemocratizeAccess, DigitalInvestment"></meta>
            </Helmet>

                    <Box>    
                    <Stack spacing={{md:4,xs:2.3}}>
                    
                        <Stack spacing={{xl:2,xs:1}}>
                            <Typography variant="aboutHeading2">All major investment assets are available in digital form. Why not Real Estate?</Typography>
                            <Typography  variant="body3">RealX brings Real Estate investing on par with other digital investments like stocks and commodities. Our investment process is completely digital. Just choose where to invest and let the benefits accrue to you with appointed professionals managing every aspect of property and funds administration.</Typography>
                            {/* <IsPossibleNow sx={{pt:{xl:0,xs:1}}} /> */}
                            {/* <LearnMore link={"/why-realx"} sx={{pt:{xs:0.5,md:1}}} title={"Why RealX ?"} /> */}
                        </Stack>

                        <Stack spacing={{xl:2,xs:1}}>
                        <Typography variant="aboutHeading2">Move over baseless Cryptos. Invest in Digital Asset Tokens linked with ownership of real assets.</Typography>
                                <Typography variant="body3">
                                We embraced the new asset class based on BlockChain. However, most of these were flawed or baseless Cryptos and let us down. Move over baseless Cryptos. Buy Real Estate Digital Assets that can give you real ownership of properties and real benefits from them - real assets, real ownership, real gains, bought and sold with real money.
                                </Typography>
                                <Stack direction={{md:"row",xs:"column"}} spacing={{md:5,xs:1}} >
                                <LearnMore link={"/how-it-works#ownership-modes"} option={{tab:1}} title={"Learn about ownership modes"} />
                                <LearnMore link={"/how-it-works#propertyTokens"} option={{tab:1}} title={"Property tokens"} />
                                <LearnMore link={"/how-it-works#coOwnership"} option={{tab:1}} title={" Registered Co-Ownership"} />
                                
                                {/* <LearnMore title={"What are property Tokens?"} /> */}
                            </Stack>
                        </Stack>

                        <Stack spacing={{xl:2,xs:1}}>
                        <Typography sx={{zIndex:2,position:"relative"}} variant="aboutHeading2">Real Estate has been the best performing asset class.</Typography>
                        <Typography variant="body3">High quality properties are more expensive too and beyond reach for most of us. But together we can. Nothing is too expensive when we come together to buy. Opening opportunities to invest digitally in high quality properties beginning as low as INR 5000. Now, everyone can participate to become a co-owner in a high value property asset. And this is just the beginning. We promise to continue to strive hard to do more, much more.</Typography>
                        </Stack>

                        <Stack spacing={{xl:2,xs:1}}>
                        <Typography sx={{zIndex:2,position:"relative"}} variant="aboutHeading2">Sell your property to a large community of investors.</Typography>
                        <Typography variant="body3"> RealX opens doors for everyone to monetize their Real Estate holdings. If you have a property that can attract investment interest from our community investors, send us some details 
                                at <a href="mailto:property@realx.in" style={{textDecoration:"none"}}>property@realx.in</a> and we would be happy to evaluate and get back should it meets our criteria.</Typography>
                        </Stack>

                        <Stack spacing={{xl:2,xs:1}}>
                        <Typography sx={{zIndex:2,position:"relative"}} variant="aboutHeading2">Digital & Transparent Real Estate Market - aligned with the vision of Digital India</Typography>
                        <Typography variant="body3">The Government in India and governments and regulators around the world have been encouraging any innovation using BlockChain that is not speculative and brings real benefits to people. RealX is built with a vision to enhance the Digital India and Financial Inclusion goals of the government.</Typography>
                        <LearnMore link={"/faq"} option={{tab:1}} title={"See what the government has been saying on Blockchain uses"} />
                        
                         </Stack>
                        
                        <Stack spacing={{xl:2,xs:1}}>
                        <Typography sx={{zIndex:2,position:"relative"}} variant="aboutHeading2">Invest with confidence. Invest with the leader.</Typography>          
                        <Typography variant="body3"> RealX is a pioneer in the world to have created a framework, legal and technical to issue tokens that have direct ownership claim on the property asset. RealX was built incorporating the best principals and prudential norms followed by financial institutions.</Typography>
                        <Typography variant="body3">Investor protection is at the core of the processes framework at RealX and it will continue to be in everything we do – from curating the best deals to implementing governance at PropChain to the privacy of your data. All this is invisible work we do and will keep doing on priority, with a long term view, so you can invest with confidence.</Typography>
                        </Stack>

                    </Stack>
                    </Box>
            
         </Box>
    )
}


const ForDevelopers=()=>{
    const [opendevForm,setOpendevForm] = useState(false);
    return(
        <>
        <DevelopersRegistraions open={opendevForm}  handleClose={()=>setOpendevForm(false)} />
        <Stack spacing={{ md: 4, xs: 2.5 }}>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Need to find new sales channels</Typography>
                <Typography variant="body3">“In absolute terms, as of 2020, total unsold inventory across the top 7 cities of the country currently
                stands at Rs 6.7 trillion, in which Mumbai and Thane region accounts for 49 percent of total unsold
                inventory (by value)” . After undergoing a 5% decline, unsold inventory in the top eight markets
                stood at 705,344 as on March 31, 2021. The western markets of MMR and Pune contribute the most
                to this unsold stock, with a combined share of 54%.</Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">The New Investors</Typography>
                <Stack spacing={1.5}>
                    <Typography variant="aboutHeading4">Creating a product to sell to the digital savvy investor</Typography>
                    <Typography variant="body3">Pandemic highlighted the role of technology and digital sales however, real estate now needs to
                        attract a new class of “retail investors”, the kind that has taken over the equities markets with
                        several billion dollars invested via online and app based money managers and services. The average
                        Indian investor has become more digitally savvy over the past few years and is ready to interact
                        online.
                    </Typography>
                    <Typography variant="body3">Millions of young Indians have taken to stock trading during the pandemic, raising hopes that the
                        appetite for equities in the world’s second-most-populated nation is finally growing. Active investor
                        accounts rose by a record 10.4 million in 2020, according to the data from the country’s two main
                        depositories.
                    </Typography>
                    <Typography variant="body3">RealX provides a “mutual fund” type approach to real estate on a per property basis, without the
                        need for a fund manager, albeit with better transparency and more control for the property
                        investor. This allows hassle free, affordable way to hold prime real estate digitally. Out of total
                        assets held by Indians 48% is real estate making it the most invested asset class, much higher than
                        equities, gold, cash or bank deposits.
                    </Typography>
                    <Typography 
                    sx={{
                        borderRadius:"10px",
                        bgcolor:"primary.light",
                        px:{lg:15,md:7,xs:2},
                        py:{md:1,xs:2},
                        marginTop:"20px !important",
                        width:"100%",
                        textAlign:{xs:"center"}
                    }}
                    >
                    <Typography variant="highlightText">Fractional Ownership must be an
                        integral part of every new age
                        Developer’s Sales strategy
                    </Typography>
                    </Typography>
                 
                </Stack>
            </Stack>
            <Stack spacing={1.5}>
                   <Typography variant="aboutHeading2">Advantage RealX</Typography>
                   <Typography variant="aboutHeading4">1. Increase Sales by 2-3X – more conversions, smaller sales cycles</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>Adopting fractional ownership provides options for your customers to invest with
                        smaller amounts</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>This can improve your sales conversion by 2-3X</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>Fractional ownership can also reduce your sales cycle by approximately 30%</Typography>
               
               
                    <Typography variant="aboutHeading4">2. Easy Property Onboarding</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>Post your property with us in minutes with our simple Property Onboarding</Typography>
                    <Box 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor:'pointer',
                            padding: '10px 10px',
                            marginLeft:{md:'30px !important',xs:'12px !important'} ,
                            width: 'fit-content',
                            borderRadius: '10px',
                            bgcolor: '#F9FAFD',
                            color: '#2B59EC',
                            '&:hover': { color: '#0000FF' },
                        }}
                    >
                        <Typography variant="body3" onClick={() => setOpendevForm(true)}>Signup as Developer</Typography>
                        <ArrowCircleUpRoundedIcon onClick={() => setOpendevForm(true)} sx={{ transform: "rotate(90deg)", ml: '10px' }} />
                    </Box>


                    <Typography variant="aboutHeading4">3. Your Property, Your Strategy</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>Complete freedom to design your sales and marketing strategy</Typography>
                    <Typography variant="body3" paddingLeft={{md:5,xs:2}}>RealX offers its platform for you to design your fractional offerings with lot of
                        flexible options</Typography>
              
            </Stack>
            
           
        </Stack>
        </>
    )
}