import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState ,useRef} from "react";
import { useLocation } from 'react-router-dom';

export default function CustomTabs({props={},tabs=[],onClick=()=>{},activeTab,labelProps,tabProps={},disable=false}){

    const headerRef = useRef(null);  // Create a ref for the header
    const location = useLocation(); // To detect tab changes


    const handleClick = (index) =>{
        onClick(index);
    }

    useEffect(() => {
        const adjustScrollPadding = () => {
            const header = headerRef.current;
            if (header) {
                const headerHeight = header.offsetHeight;
                console.log(headerHeight);
                const totalHeight=headerHeight+87;              
                document.documentElement.style.scrollPaddingTop = `${totalHeight}px`;
            }
        };

        // Initial adjustment
        adjustScrollPadding();

        // Adjust on window resize
        window.addEventListener('resize', adjustScrollPadding);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('resize', adjustScrollPadding);
        };
    }, [location.pathname]);


    return(
        <Stack ref={headerRef} direction={"row"} {...props} sx={{position:'sticky',top:{sm:'82px',xs:'62px'},bgcolor:"white",padding:'13px 0px',zIndex:999, boxShadow: '0 4px 4px -4px rgba(0, 0, 0, 0.2)'}} >
            {
                tabs.map((e,i)=>{
                   return(
                    <Box 
                        onClick={()=>handleClick(i)}
                    sx={{
                        position:"relative",
                        cursor:"pointer"                 
                    }}>
                        <Typography color={activeTab !== i && disable ?"#B0B0B0":""} {...labelProps}>
                            {e.name}
                        </Typography>
                        {
                            activeTab === i ? 
                            <Box sx={{
                                position:"absolute",
                                width:"100%",
                                height:5,
                                bgcolor:"primary.main",
                                mt:3,
                                ...tabProps
                            }}>
                            </Box>
                            :""
                        }
                    </Box>
                   ) 
                })
            }
        </Stack>
    )
}