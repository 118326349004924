import { Typography, Box, Stack, Grid,Link } from "@mui/material"
import React from "react";
import MiscLayout from "./miscLayout";

const AboutUsPage  =() =>{
    window.heap.track('About-us', {
        buttonName: 'About Us',
        category: 'Page view',
      });

    const tabs = [
        {
            name:"Introduction",
            component:<Introduction />
        },
        {
            name:"Purpose",
            component:<Purpose />
        },
        {
            name:"RealX DNA",
            component:<RealXDNA />
        },    
        {
            name:"Team",
            component:<Team />
        },
        {
            name:"Friends & Evangelists",
            component:<Friends />
        },
       
    ]
    return(
        <MiscLayout tabs={tabs} title={"About Us"} />
    )
}
export default AboutUsPage


const Introduction = () =>{
    return(
        <Stack spacing={1.5}>
            <Typography variant="aboutHeading2">Introduction</Typography>
            <Typography variant="body3">
                RealX is a Digital Assets platform that enables investment in Real-World Assets as Digital Assets with legal rights to such ownership. 
                <br /><br/>RealX began its journey by introducing fractional ownership of properties as Digital Assets. 
                RealX enables people to invest smaller amounts to become co-owners of high yield and high return properties that were not accessible to many owing to the high price of owning full property. 
                Aside of direct registered co-ownership, RealX also provides an option for smaller investments in these properties being offered as Property Tokens (NFTs). The Property Tokens will mean a proportionate direct ‘Right to Registered Co-Ownership’ (RRO). 
                So, a Token holder can exhaust the Token to become a Direct Co-Owner of the property. 
                <br /><br/>Built after years of work behind it and on base legal framework, RealX is the first such platform in the world that can offer property Tokens with direct, unencumbered, and proportionate rights on property with full legal enforceability. 
                These property tokens, being Digital Assets, can offer better liquidity and exit options when they are listed on various credible Digital Asset exchanges in the world. 
                <br /><br/>Real Estate is the most complex of assets to be digitized and tokenized on Distributed Ledger Technology to create a Digital Asset with Legal Rights. After solving the most difficult use case for Digital Assets, RealX is gearing up for 
                adding more exciting Real-World assets to its line of products in times to come like private equity, bullion and more innovative assets that we will develop in time. 
            </Typography>
        </Stack>
    )
}

const Purpose = () =>{
    return( 
        <Stack spacing={1.5}>
            <Typography variant="aboutHeading2">Purpose</Typography>
            <Typography variant="body3">
                RealX strives to create Digital Asset products and solutions that enable everyone to have equitable access to investment opportunities. 
                To ensure that our customers’ interests are protected, we develop our solutions after a deep study of the legal framework and then bring forth the best in terms of technology to ensure we can provide seamless, legal, and digital products to them. 
                <br /><br/>We wanted to bring in best in class process, governance and technology for you. While, we cannot protect you from inherent risks associated with owning property, but we can surely work to create a trusted environment for you to participate. 
                <br /><br/>Our goal is to ensure that there is a maker-checker in everything we do. As a principle, we ensure that no entity has significant control over everything. Here's how we have implemented this: 
                <br />• Enable only vetted, clean propositions for you - with external Due Diligence done by experienced law firms 
                <br />• Let a Bank handle your money (through an escrow account as per RBI guidelines) - and not RealX touching it 
                <br />• Let an independent entity - <span style={{fontWeight:500}}>Not RealX</span>, hold your Property Title 
                <br />• Maintain the registry for your property holdings on an immutable and cryptographically secure system (PropChain) - bringing in benefit of the best technology the world has for a registry system. 
                <br />• Let a competent Property Management firm manage the day-to-day administration for you - so that you can simply enjoy the benefits without any hassles
            </Typography>
        </Stack>
    )
}

const RealXDNA = () =>{
    return(
        <Stack spacing={4}>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Management Experience</Typography>
                <Typography variant="body3">
                    RealX core team have a combined 100+ years experience in the real estate, capital markets and equities market. The co-founders of RealX had earlier co-founded GREX (a private market platform to invest in high growth companies) - the only platform to have been evaluated and cleared by the regulator to operate.
                </Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Fintech and Security Market Background</Typography>
                <Typography variant="body3">
                Our DNA is that of securities market which is highly regulated and investor protection is at the core. We believe that the time is ripe for real estate to be as easy an asset to purchase and sale as shares and mutual funds with all the legal and financial transparency and disclosures that exist in the equities market today.
                </Typography>
            </Stack>
            <Stack spacing={1.5}>
                <Typography variant="aboutHeading2">Next Generation Model</Typography>
                <Typography variant="body3">
                When it comes to Fractional real estate the model that exists today is based on the SPV (Special Purpose Vehicle) structure. Under the SPV structure, the investor gets to invest in a SPV with a minimum ticket size of 25 lakhs or above. For each property a new SPV is created and under this structure, company law applies which creates restrictions as well as tax inefficiencies. 
                <br />In the RealX proprietary model, there is no intermediate layer. Investors are the DIRECT co-owners in the property and are listed in the sale deed of the property along with the fractions they hold.
                </Typography>
            </Stack>
        </Stack>
    )
}

const Team = () =>{
    const team = [
        {
            name:"Manish Kumar",
            image:"/assets/images/teams/Manish.png",
            position:"Co-founder and CEO",
            desc:"Manish is disruptive serial entrepreneur with 21 years of experience behind him. He is driven to build platforms for participative growth for all stakeholders. Considered a Fintech thought leader, Manish is called upon to share his thoughts on electronic media as well as print and digital media. He is also regularly invited as speaker or moderator at events, both domestic and international. He is also member of few consultative committees on BlockChain. He co-leads the South Asia regional chapter of Global Impact Fintech (GIFT), a global fintech thinktank and is also on VCPE Committee of Bombay Chambers of Commerce and Industry (BCCI)."
        },
        {
            name:"Neera Inamdar",
            image:"/assets/images/teams/NeeraInamdar.png",
            position:"Co-founder and COO",
            desc: (<>
                With a professional career spanning over 20 years, Neera has handled range of roles and domains from critical time sensitive role to BD, Marketing, Company engagement, Corporate & Govt Relations. She joined a Fintech company GREX which was running a Private Market platform for startup investments, reporting and exits. She joined the co-founder of GREX when they together co-founded RealX – a fractional ownership platform for properties.
                <br /><br />Aside of her professional responsibilities, she also co-leads the South Asia chapter of a global fintech think tank organization called GIFT (or Global Impact Fintech). She is also involved with one of the largest State-run incubators called KSUM (Kerala Startup Mission) & MARG StartupIndia as a Mentor.
                <br /><br />She loves customer facing & BD roles. However, her career has exposed her to deeper understanding and reasonable expertise over various & varied inward corporate functions like operations, finance, legal & compliance as well.
            </>)
        },
        {
            name:"Devang Sheth",
            image:"/assets/images/teams/DevangSheth.png",
            position:"Co-founder and CBO",
            desc: (<>
                Devang has over 30 years of experience in various senior management and key advisory capacities in the field of Engineering, Real Estate, Marketing, Business Development, Trade and Media across North America, UAE and India. He is currently focused on Real Estate and Finance.
                <br /><br /> Through his international and domestic career his clients included International companies such as Boeing, Walmart, Black & Decker, Phillips, Southern Pacific Railroad and many more. He also currently serves as Chairman of Indi Enterprise, a multifaceted media group, providing media services to clients such as British Gas, Pfizer, Citibank, Bank of America, Novartis, Rustomjee, Mastek and others.
                <br /><br /> In his prior experience he served as the Founder and Chairman of <a href="https://ecoper.com" target="_blank" rel="noopener noreferrer">ecoper.com</a> which was voted the best copper portal by D & B.
                <br /><br /> He holds a B.E. in Civil Engineering from Mumbai University, an M.S. in Environmental Engineering from South Dakota School of Mines(USA) and a Diploma in Marketing from University of California, Riverside, California.
            </>)
        },
        {
            name: "Srinivas Tangirala",
            image: "/assets/images/teams/shrinivas.jpeg",
            position: "CTO",
            desc: (<>
                He is a proven technology and business leader with 26+ years of experience in the IT industry, across domains and technologies. He held various senior positions in software product companies such as Active Life Sciences, General Motors, Engineering Animation Inc in the USA. As SVP at In10s Technologies Ltd, Hyderabad India, he conceptualized, designed, executed and led and built many successful and award winning enterprise software products across domains and verticals.  As CTO at Virinchi Limited, he successfully led the product development teams and delivered solutions to USA based clients.
                As Chief Architect at Zebi Data India Pvt Ltd he successfully launched India’s first Public Blockchain Mainnet and later as CTO he developed many enterprise class products including a Cloud security solution based on in-house built private blockchain.  He also launched <a href="https://cricketcrazy.io" target="_blank" rel="noopener noreferrer">CricketCrazy.io</a> platform - an NFT based Cricket collectibles marketplace.  As CTO at ALDEFI, he is building an enterprise product for Data protection and Zero-Trust along with Data Lineage for data auditing and trusted reports generation.
                Srinivas holds a B.Tech from the Indian Institute of Technology, Madras, India and M.S. from Clemson University (USA).
            </>)

        },
        // {
        //     name:"Amol Umranikar",
        //     image:"/assets/images/teams/Amol.jpg",
        //     position:"CBO, New Assets",
        //     desc:"An Economics post grad with 15 years in business leadership, has hands-on knowledge in all aspects of business formation, operation, finance, and management. Fuse experience, research, and analytics to forge a strong Services portfolio in the Security as well as in Risk Domain. Relentless optimist who brings a strong mix of innovation and entrepreneurship."
        // },
       
        // {
        //     name:"Saurav Raaj",
        //     image:"/assets/images/teams/sauravRaaj.jpeg",
        //     position:"",
        //     desc:`Founder - Wize - a Tokenization as a Service Solution for business applications. He is an alumnus of IIT Delhi, and has completed  Management Development Program from IIM Lucknow. He is also 1st rank holder in the Financial Technology Professional Program from SP Jain Global Management Institute.

        //     He is active in Web3, DeFi and NFTs with experience on building on Ethereum chains, Polygon, Metis, Celo, Ripple and Stellar and has won several awards at various local and international events. He also conducts regular sessions on blockchain technology related topics, to help knowledge sharing and adoption by business and enterprise users and creators. `
        // },
        // {
        //     name:"Akshay Sarode",
        //     image:"/assets/images/teams/AkshaySarode.jpeg",
        //     position:"",
        //     desc:`Dynamic leader entreprenuer who was involved in a car accident in the Year 2017 that left him paralyzed from the chest down. After several months of rehabilitation, Akshay is now in a wheelchair and celebrating adjusting to his new life. World perceived that he has already adjusted to this new life, this is his achievement but he knows how difficult for him.

        //     Akshay becomes a Voice for Entrepreneurship,  Empowerment, and disability celebrations and uses his experiences to educate others about the challenges faced by people with life disabilities. He proves that, with the right mindset, support, and Family Love, anyone can bounce back from a life-changing accident.`
        // },
        // {
        //     name:"Shivam Sharma",
        //     image:"/assets/images/teams/ShivamSharma.jpg",
        //     position:"",
        //     desc:"Lead Product Designer"
        // },
        {
            name:"Pankaj Doshi",
            image:"/assets/images/teams/Pankaj.png",
            position:"International Biz Dev",
            desc:"Pankaj is a seasoned Finance professional with over 18 years of expertise in propelling business growth and profitability within diverse multi-product, multi-channel settings. He excels in driving revenue acceleration and serving as a strategic leader. Additionally, he has experience in the field of International Business."
        },
        {
            name:"Arpit Gosain",
            image:"/assets/images/teams/Arpit.jpg",
            position:"",
            desc:"With a background in Electrical and Electronics Engineering and as an entrepreneur in the hyperlocal delivery space, he brings five years of startup business development experience. His tenacity is not just professional but also personal, as a state-level football player and an amateur Mixed Martial Artist, he combines technological fluency, entrepreneurial expertise, and athletic discipline thus creating a well-rounded skill set."
        },
       
       
    ]
    return(
        <Stack spacing={5}>
            <Typography variant="aboutHeading2">Team</Typography>
            <Box>
                <Grid container maxWidth={'100%'} rowGap={5} columnSpacing={{md:4}} >
                    {
                        team.map((e,i)=>{
                            return(
                                <Grid item md={12} xs={12} >
                                    <Stack direction={'row'} spacing={3} >
                                    <Box
                                        component="img"
                                        sx={{
                                        height: {md:163,xs:80},
                                        width: {md:163,xs:80},
                                        maxHeight: { md: 163, xs: 80 },
                                        maxWidth: { md: 163, xs: 80 },
                                        minHeight: { md: 163, xs: 80 },
                                        minWidth: { md: 163, xs: 80 },
                                        objectFit: "cover"
                                        }}
                                        // alt="The house from the offer."
                                        src={e.image}
                                    />
                                        <Stack spacing={1.5}>
                                                <Box>
                                                    <Typography variant="teamName" >{e.name}</Typography>
                                                    <Typography variant="subtitle2">{e.position}</Typography>
                                                </Box>
                                                <Typography variant="teamDesc">{e.desc}</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Box>
        </Stack>
    )
}


export const Friends=()=>{
    
const data = [
    {
        img: "/assets/images/friends/PrahladGirish.webp",
        name:"Prahlad Giri",
        link:'https://www.linkedin.com/in/prahladgirinepal/'
     },
    {
       img: "/assets/images/friends/GrReddy.webp",
       name:"G R Reddy",
       link:'https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGbwmEkaUlG4wAAAY7xaZuwBiMDHIqfJoLkfnKmQuDYdPN9RFW_iDvhcwaEjr3d7BBTTEm7hY7K2Ne3y-5zjHVjp9pPt7_CdetphAmXbllmB6IJP13oz-za9YKJGrB03QIWdpc=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fin%2Fgrreddy%3Futm_source%3Dshare%26utm_campaign%3Dshare_via%26utm_content%3Dprofile%26utm_medium%3Dandroid_app'

    },
   {
        img: "/assets/images/friends/anubhavTiwari.webp",
        name:"Anubhav Tiwari",
        link:"https://www.linkedin.com/in/anubhavtiwarivicky"
     },
   
    {
       img: "/assets/images/friends/SudinBarokar.webp",
       name:"Sudin Barokar",
       link:'https://www.linkedin.com/search/results/all/?keywords=Sudin%20Baraokar&origin=GLOBAL_SEARCH_HEADER&sid=2ZZ'
    },
    {
       img: "/assets/images/friends/MalikKhanKotadia.webp",
       name:"MalikKhan Kotadia",
       link:'https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAAAD65gIBBVmE2kbEPVbcyVkbKbjouX4dM_c&keywords=malik%20khan%20kotadia&origin=RICH_QUERY_SUGGESTION&position=0&searchId=faa74186-f495-4ef7-98e1-5dff102a6139&sid=U7L&spellCorrectionEnabled=false'
    },
   ];
   
    return(
        <Grid container spacing={3} justifyContent={'start'}>

            {data.map((item, index) => {
                return (
                    <Grid item xs={12} sm={6} md={4} lg={2.4} xl={2}>
                        <Box style={{ position: 'relative', width: '100%', height: 'auto' }}>

                        <img src={item.img} alt="Image 1" style={{ width: '100%', height: 'auto' ,borderTopLeftRadius:'12px',borderTopRightRadius:'12px'}} />
                            <Link href={item.link} target="_blank" rel="noopener noreferrer">
                                <img src={'/assets/images/friends/Lnicon.png'} alt="LinkedIn" style={{ position: 'absolute', top: -3, right: -3, width: '25px', height: '25px',borderRadius:'20%'}} />
                            </Link>
                        </Box>
                        <Typography sx={{
                            borderTop: "3px solid #ff8c16",
                            borderBottomLeftRadius:"12px",
                            borderBottomRightRadius:"12px",
                            bgcolor: '#0c657c',
                            color: '#fff',
                            textAlign: 'center',
                            padding: '12px 3px',
                            fontWeight: 600,
                            fontSize: { xl: '15px', lg: '14px', md: '14px', sm: '14px', xs: '14px' }
                        }}>
                            {item.name}
                        </Typography>
                    </Grid>
              )
            })
           } 
        </Grid>
    )
}