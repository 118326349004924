import { Box, Button, Card, Dialog, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import * as Yup from 'yup';
import { useFormik,Form, FormikProvider } from "formik";
import CloseIcon from '@mui/icons-material/Close';
import FormField from "../form/formField";
import userServices from "../../services/userServices";
import { useSnackbar } from "notistack";
import { EXTERNAL_URLS } from "../../utils/constants";
import store from "../../redux/store";
import './modals.css';

const inviteSchema = Yup.object().shape({
    username: Yup.string().required("Name is required"),
    emailId: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    mobileNumber: Yup.number()
      .required("Mobile Number is required")
      .min(1000000000, "Please enter valid mobile number"),
  });


export default function JoinSelect({ open,
    handleClose = () => {},
    setShowLogin,
  }) {
    // const user = store.getState().user.data;
    const { enqueueSnackbar } = useSnackbar();
  
    const formik = useFormik({
      initialValues: {
        username: '',
        emailId: '',
        mobileNumber: '',
      },
      validationSchema: inviteSchema,
      onSubmit: (v) => {
        console.log(v);
        saveDetails(v);
        //   saveDetails(v);
      },
    });
  
    const saveDetails = async (data) => {
  
      data.membershiptype = "SELECT";
      data.amount = 599;
      console.log("User Details", data);
          
  
  
      const res = await userServices.createSelectUser(data);
      if (res && res.success) {
        const order = res.order;
        const options = {
          key: order.notes.key,
          amount: order.amount,
          currency: "INR",
          name: data.username,
          description: "Purchase RealX Select Subscription",
          order_id: order.id,
          // callback_url: `https://pupperazy-backend.odinflux.com/transaction/verifyOrder/${order.notes.transactionId}`,
          // callback_url: `http://192.168.0.114:5000/transaction/verifyOrder/${order.notes.transactionId}`,
          // callback_url: `http://192.168.0.109:80/transaction/verifyOrder/${order.notes.transactionId}`,
          // callback_url: `${process.env.REACT_APP_API_URL}transaction/verifyOrder/${order.notes.transactionId}`,
          // callback_url: `https://api.pupperazy.com/transaction/verifyOrder/${order.notes.transactionId}`,
          handler: (res) => {
            enqueueSnackbar("Payment successfull.", {
              variant: "success",
              anchorOrigin: { horizontal: "center", vertical: "bottom" },
              autoHideDuration: 2000, 
            });
            // navigate("/portfolio");
            // window.open('https://staging.realx.in', '_blank');
            window.location.href = '/';

          },
          prefill: {
            name: '',
            email: '',
            contact: '',
          },
          notes: {
            address: "REALX",
          },
          theme: {
            color: "#2F6BF2",
          },
          modal: {
            escape: false,
            confirm_close: true,
            ondismiss: () => {
              console.log("fkdhf");
            },
          },
        };
        const razor = new window.Razorpay(options);
        razor.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
        });
        razor.open();
      } else {
        enqueueSnackbar(res.data.message, {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          autoHideDuration: 2000,
        });
      }
    };
  
    const {
      errors,
      touched,
      handleSubmit,
      getFieldProps,
      setFieldValue,
      resetForm,
    } = formik;
  
    const onClose = () => {
      resetForm();
      handleClose();
    };
  
    const openSignUpForm = () => {
      setShowLogin(true);
      onClose();
    };
  
    return (
      <Dialog
        maxWidth={"md"}
        scroll={"body"}
        PaperProps={{
          sx: { overflow: "auto", borderRadius: "15px", width: { md: "100%" } },
        }}
        open={open}
        onClose={onClose}
      >
        <Card>
          <Box
            sx={{
              position: "absolute",
              right: 0,
              padding: 2.5,
              color: { md: "#FFF", xs: "text.disabled" },
            }}
          >
            <CloseIcon
              onClick={onClose}
              sx={{ width: 24, height: 24, cursor: "pointer" }}
            />
          </Box>
          <Grid container width={"100%"}>
            <Grid item md={6}>
              <Box
                sx={{
                  borderRadius: "0px 15px 15px 0px",
                  background:
                    "url('https://media.istockphoto.com/id/1303392990/vector/cityscape-on-white-background-modern-city-skyline-city-silhouette-vector-illustration-in.jpg?s=612x612&w=0&k=20&c=n2m1ZocC5sCpKEj-GU46l2gov9iNiOStXeW0wk2TA1U=') #ffffff no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "bottom",
                  width: "100%",
                  height: "100%",         
                }}
              >
                <Stack spacing={3}>
                  <Stack padding={5}>
                    <img
                      className="relax-select-logo-main"
                      src="/assets/images/realx_select.png"
                    />
  
                    <Typography className="select-title" variant="h4">
                      Become the first few who will change the way people
                      invest in Real Estate
                    </Typography>
                    <ul className="select-benifit-list">
                      <li>
                        Access to higher quality exclusive Select deals
                      
                      </li>
                      <li>First right to invest in many properties</li>
                      
                      <li>Flat 10% off on RealX Fees</li>
                    </ul>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
  
            <Grid item md={6} xs={12} className="select-user-wrapper">
              <Box padding={5}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                    <Stack spacing={3}>
                      <Stack sx={{position:"relative"}}>
                        <Typography variant="h4" > 
                        <Box sx={{position:"relative",img:{position:"absolute",width:"20%",ml:{md:"113px",xs:"84px"},mt:{md:"-10%",xs:"-10%",sm:"-9%"}},span:{ml:"19%"}}}>Become a  <img src="/assets/images/landing/only-select.png" />  <span>member</span></Box></Typography>
                        
                          
                        <Typography variant="h4" sx={{color: 'rgb(124 124 124)', fontSize: {md:'2.5rem',xs:"1.5rem"}, fontWeight: 'bold'}}>NOW</Typography>
                         
                        {/* <img
                          className="relax-select-logo"
                          src="/assets/images/logo.png"
                        /> */}
                        <Typography className="sub-title" variant="body3">
                          You save Rs. 1900
                        </Typography>
                        <Typography className="pay-titles" variant="body3">
                          <span className="stikethrough">Rs 2499 </span>
                          <span className="price-label"> Rs 599 </span>
                        </Typography>
  
                        <Typography className="member-valid" variant="body3">
                        (Membership Valid for 1 Year)
                        </Typography>
                      </Stack>
                      <FormField
                        label={"Enter Name*"}
                        inputProps={{
                          ...getFieldProps("username"),
                          error: Boolean(touched.username && errors.username),
                          helperText: touched.username && errors.username,
                        }}
                      />
                      <FormField
                        label={"Enter Mobile No*"}
                        inputProps={{
                          ...getFieldProps("mobileNumber"),
                          onChange: (e) => {
                            // console.log(RegExp('^[0-9]*$').test(e.target.value));
                            if (
                              RegExp("^[0-9]*$").test(e.target.value) &&
                              e.target.value?.length < 11
                            ) {
                              setFieldValue("mobileNumber", e.target.value);
                            }
                          },
                          error: Boolean(
                            touched.mobileNumber && errors.mobileNumber
                          ),
                          helperText: touched.mobileNumber && errors.mobileNumber,
                        }}
                      />
                      <FormField
                        label={"Enter Email ID*"}
                        inputProps={{
                          ...getFieldProps("emailId"),
                          error: Boolean(touched.emailId && errors.emailId),
                          helperText: touched.email && errors.emailId,
                        }}
                      />
                      <Stack>
                        {/* <Typography variant="h4">₹ 599</Typography> */}
                      </Stack>
                      <Stack pt={1}>
                        <Button type="submit" variant="contained">
                          Proceed to Pay
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Box>
            </Grid>
          </Grid>
        </Card>
      </Dialog>
    );
  }
  

  