import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import MainFooter from "../components/mainComponents/mainFooter";
import MainHeader from "../components/mainComponents/mainHeader";

export default function MainLayout(){
    return(
        <Box
        sx={{
            maxWidth:"1920px",
            position:"relative",
            margin:"auto",         
        }}
        >
            <MainHeader/>
            <Outlet />
            <MainFooter/>
        </Box>
    )
}