import { Box, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import CustomTabs from "../../components/tabs/customTabs";
import { checkLogin } from "../../utils/functions";

export default function MiscLayout({tabs=[],title=""}){
    const [activeTab,setActiveTab] = useState(0);

    const { state } = useLocation();
    const [params] = useSearchParams();

    useEffect(()=>{
        
        if(params.get("tab") !== null){
            setActiveTab(Number(params.get("tab")));
        }
        if(state && state.tab !== null){
            setActiveTab(state.tab);
        }
    },[state])

      // Scroll to the top of the page whenever the active tab changes
      useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [activeTab]);

    return(
        <Card sx={{mt:checkLogin()?5:{md:15,xs:12},mx:{xl:30,lg:15,md:10,xs:2},mb:5}}>
            <CardContent>
                <Box sx={{px:{md:"60px",xs:0}}}>
                    <Typography variant="miscTitle">{title}</Typography>
                    <CustomTabs 
                        onClick={(index)=>setActiveTab(index)} 
                        tabs={tabs} props={{spacing:{md:5,xs:2},mt:{md:1,xs:3}}} 
                        labelProps={{variant:"miscTab"}} 
                        activeTab={activeTab} 
                        tabProps={{height:3,mt:{md:"8px",xs:"4px"}}} 
                        disable={true} />
                    {/* <Box sx={{width:"100%",height:1.2,bgcolor:"divider",position:'sticky',top:'135px',zIndex:'999'}} /> */}
                    <Box
                        sx={{
                            mt:{md:5,xs:3},
                        }}
                    >
                        {
                            tabs[activeTab]?.component
                        }
                    </Box>
                </Box>
            </CardContent>
        </Card>
    )
}