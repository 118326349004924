import React from 'react'
// import CircleIcon from '@mui/icons-material/Circle';
import { Box, Stack, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom';
import CustomIcon from '../customIcon';
import moment from 'moment';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const MainFooter = () =>{
    const navigate = useNavigate();


    const handleClick = (link) =>{
        navigate(link);
    }
    return(
        <Box
            sx={{
                bgcolor:"#111013",
                width:"100vw",
                left:"50%",
                right:"50%",
                marginX:"-50vw",
                position:"relative",
                zIndex:1,
            }}
        >
            <Box sx={{
                        width:"100%",
                        maxWidth:{xl:1360,lg:1080},
                        margin:"auto",
                        py:{md:"100px",xs:5},
                        px:{lg:0,md:8,xs:3},
                        pb:{md:"100px",xs:15}
                    }}>
                <Stack
                direction={{md:"row",xs:"column"}}
                justifyContent={"space-between"}
                // alignItems={"center"}
                mb={{md:"50px",xs:2}}   
                >
                
                    <Stack direction={"column"} spacing={{md:5,xs:2}}  alignItems={{md:'center',xs:'none'}}  sx={{width: { xs: '100%', sm: '100%', md: '40%' }}}>
                        <Box onClick={()=>window.scroll({behavior:"smooth",top:0})} component={'img'} src={"/assets/images/darklogo.png"} width={{md:159,xs:110}} sx={{cursor:"pointer"}} />
                        <Typography sx={{fontFamily:"Proxima Nova Rg",color:"#858585",textAlign:{md:'center',xs:'none'}}}>
                            RealX allows investors to buy, sell, hold and gain from their property holdings as Digital Assets.
                        </Typography>     
                    </Stack>
                    <Stack mt={{md:0,xs:3}} direction={"row"} justifyContent={{md:'center',xs:'start'}} flexWrap={"wrap"}  rowGap={{lg:0,xs:3}} columnGap={{lg:9,xs:6}}  sx={{ width: { xs: '100%', md: '60%' }}}>
                        <Stack spacing={{md:4,xs:"14px"}}>
                            <Typography variant='footerTitle'>Company</Typography>
                            <Stack direction={"column"} spacing={{md:2,xs:"12px"}}>
                                <Typography onClick={()=>handleClick('/how-it-works')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>How it works</Typography>
                                <Typography onClick={()=>handleClick('/why-realx')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>Why RealX</Typography>
                                <Typography onClick={()=>handleClick('/faq')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>FAQ's</Typography>
                                <Typography onClick={()=>handleClick('/about-us')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>About Us</Typography>
                                <Typography onClick={()=>handleClick('/policy')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>Privacy Policy</Typography>
                                <Typography onClick={()=>handleClick('/terms-and-conditions')} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>Terms of use</Typography>
                                {/* <Typography sx={{fontFamily:"Proxima Nova Rg",color:"#FFF",cursor:"pointer"}}>Terms & Conditions</Typography> */}
                            </Stack>
                        </Stack>
                        <Stack spacing={{md:4,xs:"14px"}}>
                            <Typography variant='footerTitle'>Follow Us</Typography>
                            <Stack direction={"column"} spacing={{md:2,xs:"12px"}}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                    onClick={()=>window.open('https://www.facebook.com/hirealx/',"_blank")}
                                >
                                    <CustomIcon name='facebook-2' sx={{width:20,height:20,bgcolor:"#FFF"}} />
                                    <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg"}}>Facebook</Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                    onClick={()=>window.open('https://twitter.com/hellorealx',"_blank")}
                                >
                                    <CustomIcon name='X' sx={{width:16,height:16,bgcolor:"#FFF"}} />
                                    <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg"}}>X (Twitter)</Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                    onClick={()=>window.open('https://www.linkedin.com/company/hellorealx/',"_blank")}
                                >
                                    <CustomIcon name='linkedin' sx={{width:16,height:16,bgcolor:"#FFF"}} />
                                    <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg"}}>Linkedin</Typography>
                                </Stack>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                    onClick={()=>window.open('https://instagram.com/hellorealx?igshid=MDM4ZDc5MmU=',"_blank")}
                                >
                                    <CustomIcon name='instagram1' sx={{width:16,height:16,bgcolor:"#FFF"}} />
                                    <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg"}}>Instagram</Typography>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Stack spacing={{md:4,xs:"14px"}}>
                            <Typography variant='footerTitle'>Connect Us</Typography>
                            <Stack direction={"column"} spacing={{md:2,xs:"12px"}}>
                                {/* <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                >
                                    <CustomIcon name='phone' sx={{width:16,height:16,bgcolor:"#FFF"}} />
                                    <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg"}}>+91000000000</Typography>
                                </Stack> */}
                                <a href="mailto:hi@realx.in" style={{textDecoration:"none"}}>
                                <Stack direction={"row"} spacing={1} alignItems={"center"}
                                    sx={{
                                        color:"#FFF",
                                        cursor:"pointer",
                                    }}
                                >
                                    <CustomIcon name="mail" sx={{bgcolor:"#FFF",width:16,height:16}} />
                                        <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg",color:"#FFF",}}>hi@realx.in</Typography>
                                </Stack>
                                    </a>
                                {/* <Stack direction={'row'} spacing={1} alignItems={"center"}>
                                <CallIcon sx={{color:"#FFF",width:16,height:20}}  />
                                <Typography sx={{fontSize:18,fontFamily:"Proxima Nova Rg",color:"#FFF",}}>9834970119</Typography>
                                </Stack>     */}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction={'row'} justifyContent={{md:'center'}} spacing={1}>
                <LocationOnIcon sx={{color:"#FFF",width:{md:22,xs:15},height:{md:22,xs:15},mt:'3px'}} />
                <Typography mb={{md:"50px",xs:5}} sx={{fontFamily:"Proxima Nova Rg",color:"#FFF"}}>
                    303, Olympia Business House, above Citroen Showroom, Mumbai - Bangalore Highway Service Road, Baner, Pune MH 411021    
                    {/* Office Address - #303, Olympia Business House, Sr No 36/1, Mumbai - Pune Highway, Baner, Pune 411045, Maharashtra, India */}
                </Typography>
                </Stack>
                <br/>
                <Box sx={{width:"100%",height:2,bgcolor:"#FFF",opacity:0.5}} />
                <Box mt={{md:"100px",xs:5}} mb={{md:5,xs:2.5}}>
                    <Typography variant='footerbody'>
                        Safe Harbor and Disclaimer 
                        <br /><br/>
                        RealX merely provides a platform for legal co-ownership of property assets. Such co-ownership is offered solely for the benefit for financial investment and returns from property investing. Investment opportunities on the platform may carry substantial risk and investors are requested to solicit advice before investing. Information contained in any property deal on the platform, including information regarding targeted returns and investment performance, maybe based on a reasonable growth scenario and is provided by the respective seller. These investments do not carry any government or regulatory protection and Investors must be willing to sustain the risk of loss of capital, including the risk of total loss of capital, despite all the precautions and process put up by us to ensure that does not happen ever.
                    </Typography>
                </Box>
            
                <Typography  variant='footerbody'>
                    Copyright © RealX {moment().year()}
                    {/* | <span onClick={()=>handleClick("policy")} style={{textDecoration:"underline",cursor:"pointer"}}>Privacy Policy</span> */}
                </Typography>
            </Box>
        </Box>
    )
}
export default MainFooter