import React from 'react';
import { Box, Link, Stack, Typography} from '@mui/material';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';

export default function CustomLink({ href, text, iconRotation = '90deg',num }){
  return (
    <Stack direction={'row'} alignItems={'center'}>
    <Typography>{num}</Typography>
     <Link
       sx={{ 
         display:'flex',
         alignItems:'center',
         textDecoration: 'none' ,
         padding: '5px 15px',
         width: 'fit-content',
         borderRadius: '15px',
         bgcolor: '#F9FAFD' ,
         color: '#2B59EC',
        '&:hover': { color: '#0000FF' },  
      }}

      rel="noreferrer" href={href} target={"_blank"}>
        <Typography variant="body3">{text}</Typography>
        <ArrowCircleUpRoundedIcon sx={{ transform: `rotate(${iconRotation})`, ml: '10px' }} />
      </Link>
   
  </Stack>
  );
};


