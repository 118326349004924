import React from 'react'
import { useEffect, useState, useRef } from 'react';

const UseIntersectionObserver = (options) => {
    
        const [isIntersecting, setIsIntersecting] = useState(false);
        const ref = useRef(null);
      
        useEffect(() => {
          const observer = new IntersectionObserver(([entry]) => {
            setIsIntersecting(entry.isIntersecting);
          }, options);
      
          if (ref.current) {
            observer.observe(ref.current);
          }
      
          return () => {
            if (ref.current) {
              observer.unobserve(ref.current);
            }
          };
        }, [options]);
      
        return [ref, isIntersecting];
      };
      


export default UseIntersectionObserver